import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import Loading from "./component/Loading";

function ScrollToTop({ history }) {

  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    });
    return () => {
      unlisten();
    };
  }, [history]);

  const location = useLocation();
  useEffect(()=> {
    setLoading(true);
    document.body.style.overflow = 'hidden';
    setTimeout(()=>{
      setLoading(false);
      document.body.style.overflow = 'auto';
    }, 500);


    const body = document.querySelector(`body`);
    body.style.top = '0px !important';

    const firstDiv = document.querySelectorAll(`#goog-gt-tt`);
    if (firstDiv.length > 0) {
      firstDiv.forEach(element => {
      let nextSibling = element.nextElementSibling;      
      // eslint-disable-next-line
      if(nextSibling.tagName == "SCRIPT") {
        nextSibling = nextSibling.nextElementSibling; 
      }
      nextSibling.style.setProperty('display', 'none', 'important');
      });
    } else {
      // console.log('No elements found with ID "goog-gt-tt"');
    }



    // let cookieVal = `/en/${localStorage.getItem("selectedLang")}`;
    // // document.cookie = "googtrans=" + cookieVal;

    // // document.cookie = `googtrans=${cookieVal}; domain=.esgri.tech`
    // // document.cookie = `googtrans=${cookieVal}; domain=staging.esgri.tech`

    // document.cookie = `googtrans=${cookieVal};`
    // // document.cookie = `googtrans=${cookieVal};`



  }, [location])

  return (
    <div>
      {loading === true ? (
          <Loading />
        ):(
          <div>
          </div>
        )

      }
    </div>
  );
}

export default withRouter(ScrollToTop);

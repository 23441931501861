import React, { Component } from "react";

export default class enviornmental_consideration extends Component {
  render() {
    return (
      <div>
        <div id="panel1" className="panel">
          <div className="table_well">
            <p className="actual">
              <strong>Greenhouse gas emissions (GHG): </strong> Includes actual or potential regulations such as carbon taxes, emissions trading schemes and other direct or indirect costs. The GHG emissions under the Kyoto climate change agreement are carbon dioxide (CO2), methane (CH4), nitrous oxide (N2O), hydrofluorocarbons (HFCs), perfluorocarbons (PFCs) and sulfur hexafluoride (SF6).
            </p>
            <p className="actual">
              <strong>Sensitivity to extreme weather events: </strong> Incremental costs or the potential physical impact on assets associated with recurring (for example, hurricanes) or infrequent (droughts) severe weather events.
            </p>
            <p className="actual">
              <strong>Sensitivity to water scarcity: </strong> Potential costs are related to the need for extracting or sourcing large quantities of water or requiring on-site water treatment compared to other water users of the same water basins or utilities.
            </p>
            <p className="actual">
              <strong>Waste, pollution and toxicity: </strong> Potential fines or rising costs associated with prevention and treatment of waste and pollution, including hazardous waste and air pollution.
            </p>
            <p className="actual">
              <strong>Land use and biodiversity: </strong> Asset retirement obligations, developing natural land or potential operating constraints, or increased costs associated with protecting plant and animal life.
            </p>
            <p className="actual">
              <strong>Human capital management: </strong> It includes a sector's capacity to develop a long-lasting productive workforce while reducing potential operational disruptions from workforce mismanagement, diversity and inclusion attributes, exposure to strikes, and the sector's widespread exposure to dealing with emerging skills scarcity or surplus labour.
            </p>
            <p className="actual">
              <strong>Changing consumer or user preferences:  </strong> We recognise that changes in consumer behaviour are often the result of complex dynamics, such as changes in technology or fashion or other disruptive business trends. Therefore, we treat a change in consumer preferences as a social factor related to sustainability, health, safety, the environment, privacy, financial mis-selling, community and human rights, primarily when an entity has triggered the change.
            </p>
            <p className="actual">
              <strong>Demographic changes:  </strong> Includes potential costs or opportunities related to population growth and composition, such as an ageing population, urbanisation, changing living standards or a growing middle class.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

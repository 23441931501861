/* eslint-disable jsx-a11y/anchor-is-valid */
import { authenticationService } from "../../../../_services/authentication";
import { sweetAlert, readCokkie } from "../../../../utils/UniversalFunction";
import React, { Component } from "react";
import config from "../../../../config/config.json";
import { NavLink } from "react-router-dom";
import Sidebar from "../../../sidebar/sidebar";
import Header from "../../../header/header";
import axios from "axios";
import Loader from "../../../loader/Loader";
import DyanamicTabs from "./dyanamicTabs";
const currentUser = authenticationService.currentUserValue;

export default class Social_Capital extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      answers: [],
      questionId: [],
      submitted: false,
      questions: [],
      isQuestionAvailable: false,
      isEditableOrNot: false,
      tabs: [],
      isNextTab: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.goToPreviousPath = this.goToPreviousPath.bind(this);
    this.handleCallback = this.handleCallback.bind(this);
  }

  handleCallback = (childData) => {
    this.setState({ tabs: childData });
    let uri = window.location.href.split("/").pop();
    let nextTab = "";
    let ii = 0;
    childData.forEach((element) => {
      if (element.uri === "/" + uri) {
        nextTab = childData[ii + 1].uri;
        this.setState({
          isNextTab: nextTab,
        });
      }
      ii++;
    });
  };

  handleChange(id, event, questionId) {
    let answerss = this.state.answers;
    let questionIds = this.state.questionId;
    const target = event.target;
    const value = target.value;
    const questionIdd = questionId;
    answerss[id] = value;
    questionIds[id] = questionIdd;
    this.setState({
      answers: answerss,
      questionId: questionIds,
    });
  }

  goToPreviousPath() {
    this.props.history.goBack();
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    let socialCapitalIds = {};
    let finalArr = [];
    let questionIdddd;
    const { answers, questionId } = this.state;

    let hasEmpty = false;

    for (let i = 0; i < answers.length; i++) {
      if (answers[i] === "") {
        hasEmpty = true;
      }
    }

    if (hasEmpty === false) {
      for (let index = 0; index < answers.length; index++) {
        const element = answers[index];
        questionIdddd = questionId[index];
        let obj = {
          id: questionIdddd,
         // eslint-disable-next-line
         answer: element.replace(/"/g, '\"'),
        };
        finalArr.push(obj);
      }
      socialCapitalIds.data = finalArr;
      const headers = {
        Authorization: `Bearer ${currentUser.data.token}`,
        Accept: "application/json",
      };

      axios
        .post(
          config.OLD_API_URL + "socialCapital",
          { socialCapitalIds: socialCapitalIds },
          { headers }
        )
        .then((response) => {
          sweetAlert("success", response.data[0].message);
          setTimeout(() => {
            const newLocal = this.state.isNextTab;
            this.props.history.push(newLocal);
          }, 1000);
        })
        .catch(function (error) {
          if (error.response) {
            sweetAlert("error", error.response.data.message);
          }
        });
    } else {
      sweetAlert("error", "All fields are required!");
    }
  }

  componentDidMount() {
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    this.setState({
      isLoaded: true,
    });
    fetch(config.API_URL + "getSocialCapitalAnswers", { headers })
      .then((res) => res.json())
      .then(
        async (result) => {
          let mappedQues;
          // Language Translation Fix
          const cokkie = readCokkie();
          if(cokkie === '/en/da') {
            const replacementMap = { 
              "percentage of all": "ud af alle", 
              "the framework application":  "rammer og standarder",
              "assurance": "kontrol"
              // "What is the percentage of female senior management": "Hvad er procentdelen af kvindelige topledelser"
            };
            mappedQues = await result.questions.map((item) => {
              var regexPattern = new RegExp(Object.keys(replacementMap).join("|"), "gi");
              
              var newString = item.title.replace(regexPattern, function(match) {
                return replacementMap[match];
              });

              if(item.title === "What is the percentage of female senior management as a percentage of senior management?" || item.title === "What is the percentage of female senior management as a percentage of senior management?\n") {
                newString = "Hvad er andelen af kvindelige ledelsesmedlemmer i procent af topledelsen?"
              }
              if(item.title === "What disclosures get made based on anti-corruption standards?" || item.title === "What disclosures get made based on anti-corruption standards?\n") {
                newString = "Havad medfører jeres anti-korruptionsstandarder?"
              }

              return {
                ...item,
                title: newString
              }
            });
            }
          let questionIdddd = [];
          let answerIdddd = [];
          result.questions.forEach((element) => {
            questionIdddd.push(element.id);
            answerIdddd.push(element.answer);
          });

          this.setState({
            isQuestionAvailable: result.questions.length > 0 ? true : false,
            questions: mappedQues ? mappedQues : result.questions,
            answers: answerIdddd,
            questionId: questionIdddd,
            isEditableOrNot: result.insertOrUpdate,
            isLoaded: false,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  render() {
    const { questions, isEditableOrNot, isQuestionAvailable, isNextTab } = this.state;
    let role = JSON.parse(localStorage.getItem("currentUser")).data.role;
    return (
      <div>
        <Header />
        <Sidebar dataFromParent={this.props.location.pathname} />
        {this.state.isLoaded === true && <Loader />}

        {this.state.isLoaded === false && (
          <div className="main_wrapper">
            <DyanamicTabs parentCallback = {this.handleCallback}/>
            <div className="inner_wraapper">
              <div className="container-fluid">
                <section className="d_text">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="Introduction framwork_2">
                          {/* <div className="row setup-content" id="step-10"> */}
                          <div className="col-md-12">
                            {isQuestionAvailable && (
                              <form onSubmit={this.handleSubmit}>
                                <h4 className="E_capital font-heading">
                                  Social Capital
                                </h4>

                                {questions.map((item, key) => (
                                  <div key={key}>
                                    <div className="heading_h3 mt-3">
                                      {item.heading && (
                                        <span className="gement">
                                          {item.heading}
                                        </span>
                                      )}
                                    </div>

                                    <div className="form-group">
                                      <label
                                        htmlFor="floatingTextarea"
                                        className="energy mb-3 font-increase"
                                      >
                                        {item.title}
                                      </label>
                                      <textarea
                                        className="form-control"
                                        name={"answers" + key}
                                        placeholder="Leave a Answer here"
                                        onChange={(event) =>
                                          this.handleChange(key, event, item.id)
                                        }
                                        in="true"
                                        defaultValue={item.answer}
                                      ></textarea>
                                    </div>
                                  </div>
                                ))}

                                <div className="global_link mt-4" style={{gap:'15px'}}>
                                  <a
                                    className="link_bal_next"
                                    onClick={this.goToPreviousPath}
                                  >
                                    Back
                                  </a>
                                  <span className="global_link">
                                    {isEditableOrNot && (
                                      <button
                                        className="page_width page_save"
                                        type="submit"
                                      >
                                        Next
                                      </button>
                                    )}
                                    {!isEditableOrNot &&
                                      role === "company_sub_admin" && (
                                        <NavLink
                                          className="page_width page_save"
                                          to={isNextTab}
                                        >
                                          Next
                                        </NavLink>
                                      )}
                                  </span>
                                  {/* )} */}
                                </div>
                              </form>
                            )}
                            {!isQuestionAvailable && (
                              <>
                                <h2>No Questions here</h2>
                                <div
                                  className="global_link mx-0 my-5"
                                  style={{ gap: "15px" }}
                                >
                                  <span className="">
                                    <a
                                      className="link_bal_next"
                                      onClick={this.goToPreviousPath}
                                    >
                                      Back
                                    </a>
                                  </span>
                                  <span className="global_link">
                                    <NavLink
                                      className="page_width page_save"
                                      to={isNextTab}
                                    >
                                      Next
                                    </NavLink>
                                  </span>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

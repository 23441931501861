import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import Table from "react-bootstrap/Table";
/* eslint-disable jsx-a11y/iframe-has-title */
import Swal from "sweetalert2";

import { authenticationService } from "../../../../_services/authentication";
import config from "../../../../config/config.json";
import Header from "../../../header/header";
import Sidebar from "../../../sidebar/sidebar";

import "./requested_reports.css";

const currentUser = authenticationService.currentUserValue;
export default class requested_reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reports: [],
    };
  }

  componentDidMount() {
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };

    axios
      .get(config.BASE_URL + "api/v1/supplierManagement/getUserReports", {
        headers,
      })
      .then((response) => {
        this.setState({
          reports: response.data.reports,
        });
      })
      .catch(function (response) {
        Swal.fire({
          icon: "error",
          title: response.data.message,
          showConfirmButton: false,
          timer: 1000,
        });
      });
  }

  async downloadFile(item) {
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
    };
    try {
      // const res = await axios.get(
      //   "http://localhost:7070/api/v1/supplierManagement/getFileFromS3",
      //   {
      //     params: {
      //       filename: item.filename,
      //     },
      //     headers,
      //   }
      // );
      // console.log(res, "res");
      // if (!res.data.url) {
      console.log(item.filename);
      const file = await axios.get(
        config.BASE_URL + "api/v1/supplierManagement/downloadReport",
        {
          params: {
            filename: item.filename,
          },
          headers,
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(file.data);
      const a = document.createElement("a");
      a.href = url;
      a.download = item.filename;
      a.click();
      window.URL.revokeObjectURL(url);
      // } else {
      //   const a = document.createElement("a");
      //   a.href = res.data.url;
      //   a.download = "coriolis-report";
      //   a.click();
      //   window.URL.revokeObjectURL(res.data.url);
      // }

      Swal.fire({
        icon: "success",
        title: "Check your downloads folder",
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { reports } = this.state;
    return (
      <div>
        <Sidebar dataFromParent={this.props.location.pathname} />
        <Header />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="governance">
                    <div className="text_governance">
                      <h5 className="governance_head font-heading">
                        Requested reports
                      </h5>

                      <div className="row pt-4">
                        <div className="table_f manage-detail admin-risk-table table-responsive">
                          <Table
                            striped
                            bordered
                            hover
                            size="sm"
                            className="report-table"
                          >
                            <thead>
                              <tr className="heading_color">
                                <th>№</th>
                                <th scope="col" style={{ textAlign: "left" }}>
                                  Company name
                                </th>
                                <th scope="col" style={{ textAlign: "left" }}>
                                  Address
                                </th>
                                <th scope="col">Country code</th>
                                <th scope="col">Status</th>
                                <th scope="col">Created at</th>
                                <th scope="col">Download</th>
                              </tr>
                            </thead>
                            <tbody>
                              {reports.map((item, key) => (
                                <tr key={key}>
                                  <td>{key + 1}</td>
                                  <td
                                    style={{
                                      width: "300px",
                                      textAlign: "left",
                                    }}
                                  >
                                    {item.companyName}
                                  </td>
                                  <td
                                    style={{
                                      width: "300px",
                                      textAlign: "left",
                                    }}
                                  >
                                    {item.address}
                                  </td>
                                  <td>{item.countryCode}</td>
                                  <td>{item.status}</td>
                                  <td>
                                    {moment(item.createdAt).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </td>
                                  <td>
                                    <button
                                      className="download_button"
                                      onClick={() => this.downloadFile(item)}
                                    >
                                      <span>
                                        <i className="fa fa-download"></i>
                                      </span>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

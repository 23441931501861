import React, { useState } from "react";
import classes from "./scopeTableComponent.module.css";
import moment from "moment";
import { Input } from "semantic-ui-react";
import Swal from "sweetalert2";
import { saveScopeEmissions } from "./services/CarbonService";
import { sweetAlert } from "../../../../utils/UniversalFunction";
import { useHistory } from "react-router-dom";
import "./carbon.css";
import { Spinner } from "react-bootstrap";


const ScopeTableComponent = ({
  activeTab,
  setActiveTab,
  entities,
  setEntities,
  loading,
  totalEmission
}) => {
  const [factorId, setFactorId] = useState(null);
  const [tempFactor, setTempFactor] = useState(null);
  const [ saveLoading, setSaveLoading] = useState(false);

  const history = useHistory();

  const handleTab = () => {
    if (activeTab === 3) {
      // navigate to result route
      history.push("/result");
    } else {
      if (activeTab < 3) setActiveTab((cur) => cur + 1);
    }
  };

  const handleValueChange = (e, parentIndex, childindex) => {
    const { value } = e.target;

    let temp = [...entities];

    // do calculations based on object
    temp[parentIndex][childindex].activity_cy = parseFloat(value);
    temp[parentIndex][childindex].emission_kgco2e = temp[parentIndex][childindex].activity_cy * temp[parentIndex][childindex].emission_factor;
    temp[parentIndex][childindex].emission_tco2e = temp[parentIndex][childindex].emission_kgco2e / 1000;
    setEntities(temp);
    calculateTotalEmission(temp);
  };

  const calculateTotalEmission = async (dataset) => {
    
    // calculate total Emission of particular scope
    let totalSumScope = 0;
    dataset.forEach((innerArray) => {
      innerArray.forEach((element) => {
        totalSumScope += isNaN(element.emission_kgco2e)
          ? 0
          : element.emission_kgco2e;
      });
    });

    let totalSum = 0;
    if(activeTab === 1) {
      totalSum += totalEmission.scope2 + totalEmission.scope3 + totalSumScope;
    }else if(activeTab === 2) {
      totalSum += totalEmission.scope1 + totalEmission.scope3 + totalSumScope;
    }else{
      totalSum += totalEmission.scope1 + totalEmission.scope2 + totalSumScope;
    }

    const temp = entities.map((child) => {
      const childArray = child.map((item) => {
        return {
          ...item,
          total_emission: (item.emission_kgco2e / totalSum) * 100,
        };
      });
      return childArray;
    });

    setEntities(temp);
  };

  const handleFactorChange = (e) => {
    const { value } = e.target;
    setTempFactor(value);
  };

  const handleFactorSave = (parentIndex, childIndex) => {
    Swal.fire({
      title: "Do you want to save the changes?",
      showCancelButton: true,
      confirmButtonText: "Save",
      reverseButtons: true,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let value = parseFloat(tempFactor ? tempFactor : "0");
        let temp = [...entities];
        temp[parentIndex][childIndex].emission_factor = value;

        temp[parentIndex][childIndex].emission_kgco2e =
          temp[parentIndex][childIndex].activity_cy.toFixed(2) *
          temp[parentIndex][childIndex].emission_factor.toFixed(2);
        temp[parentIndex][childIndex].emission_tco2e =
          temp[parentIndex][childIndex].emission_kgco2e.toFixed(2) / 1000;
        setEntities(temp);
        calculateTotalEmission(temp);
        setFactorId(null);
        setTempFactor(null);
        Swal.fire({
          icon: "success",
          title: "Saved!",
          showConfirmButton: false,
          timer: 1500,
        });
      }else if(result.dismiss === "cancel"){
        setFactorId(null);
        setTempFactor(null);
      }else{
        console.log("No Action Seleted")
      }
    });
  };

  const handleEmissionSave = async () => {
    setSaveLoading(true);
    const payload = entities.map((childs) => {
      let childPayload = [];
      childs.forEach((item) => {
        childPayload.push({
          activity_cy: item?.activity_cy ? `${item.activity_cy}` : "0",
          emission_factor: `${item.emission_factor}`,
          config_id: `${item.id}`,
          emission_kgco2e: item?.emission_kgco2e
            ? `${item.emission_kgco2e}`
            : "0",
          emission_tco2e: item?.emission_kgco2e
            ? `${item.emission_tco2e}`
            : "0",
          total_emission: item?.emission_kgco2e
            ? `${item.total_emission}`
            : "0",
        });
      });
      return childPayload;
    });
    const flattenedPayload = payload.reduce(
      (acc, curr) => acc.concat(curr),
      []
    );

    saveScopeEmissions(flattenedPayload, activeTab)
      .then((res) => {
        sweetAlert("success", "Saved Successfully!");
        setSaveLoading(false);
        setTimeout(() => {
          handleTab();
        }, 1000);

        // move to next tab
      })
      .catch((error) => {
        setSaveLoading(false);
        sweetAlert("error", error.message || "Oops some error occured!");
      });
  };
 
  return (
    <div>
    <div className={classes.tableContainer} style={{ paddingBottom: "50px"}}>
      <table className={classes.table}>
        <thead>
          <tr className={classes.tableHeadRow}>
            <th className={classes.tableHeadCell} style={{ width: "7%" }}>
              Group
            </th>
            <th className={classes.tableHeadCell} style={{ width: "12%" }}>
              Emissions Group
            </th>
            <th className={classes.tableHeadCell} style={{ width: "16%" }}>
              Emission Source
            </th>
            <th className={classes.tableHeadCell} style={{ width: "6%" }}>
              Unit
            </th>
            <th className={classes.tableHeadCell} style={{ width: "10.5%" }}>
              Activity data CY {moment().year()}
            </th>
            <th className={classes.tableHeadCell} style={{ width: "9.5%" }}>
              Emission factor
            </th>
            <th className={classes.tableHeadCell} style={{ width: "9.5%" }}>
              <div>{moment().year()} Emissions </div>
              <div>(kg CO2e)</div>
            </th>
            <th className={classes.tableHeadCell} style={{ width: "9.5%" }}>
              <div>{moment().year()} Emissions</div> <div>(tCO2e)</div>
            </th>
            <th className={classes.tableHeadCell} style={{ width: "9.5%" }}>
              <div>{moment().year()} Emissions</div> <div>% of total</div>
            </th>
          </tr>
        </thead>
        <tbody className={classes.tableBody} style={{ width: "100%" }}>
          {loading ? (
                // <Spinner />
                <tr>
                  <td colSpan="12">
                    <div className="loader_container">
                      <Spinner animation="border"  style={{ width: '3rem', height: '3rem' }}/>
                    </div>
                  </td>
                </tr>
          ): (
            entities.map((group, index) =>
              group.map((item, childIndex) => (
                <tr
                  className={classes.tableBodyRow}
                  key={childIndex}
                  style={{ width: "100%" }}
                >
                  <td
                    rowSpan={item.col1rowSpan}
                    style={{
                      position: "relative",
                    }}
                  >
                    <div className={classes.group_cell} style={{ width: "100%" }}>
                      {childIndex === 0 ? item?.group : null}
                    </div>
                  </td>
                  <td className={classes.tableBodyCell} style={{ width: "15%" }}>
                    <div
                      className={classes.disabledCellBox}
                      style={{ width: "100%" }}
                    >
                      {item?.emission_group}
                    </div>
                  </td>
                  <td className={classes.tableBodyCell} style={{ width: "19%" }}>
                    <div
                      className={classes.disabledCellBox}
                      style={{ width: "100%" }}
                    >
                      {item?.source}
                    </div>
                  </td>
                  <td className={classes.tableBodyCell} style={{ width: "8%" }}>
                    <div
                      className={classes.disabledCellBox}
                      style={{ width: "100%" }}
                    >
                      {item?.unit}
                    </div>
                  </td>
                  <td className={classes.tableBodyCell} style={{ width: "12%" }}>
                    <input
                      type="number"
                      className={classes.cellBox}
                      placeholder="Enter Value here..."
                      name="activity_cy"
                      value={ parseFloat(item?.activity_cy)}
                      onChange={(e) => {
                        handleValueChange(e, index, childIndex);
                      }}
                      style={{ width: "100%" }}
                    />
                    {/* <div className={classes.cellBox}>{item.activitydata}</div> */}
                  </td>
                  <td className={classes.tableBodyCell} style={{ width: "9%" }}>
                    <Input
                      type="number"
                      className={
                        // eslint-disable-next-line
                        item.id == factorId
                          ? classes.activeFactor
                          : classes.inactiveFactor
                      }
                      icon={{
                        // eslint-disable-next-line
                        name: item.id == factorId ? `save` : `pencil alternate`,
                        link: true,
                        onClick: () => {
                          // eslint-disable-next-line
                          if(item.id == factorId) {
                            handleFactorSave(index, childIndex)
                          }else{
                            setFactorId(item.id);
                            setTempFactor(item?.emission_factor)
                          }
                        },
                      }}
                      placeholder="Enter Value here..."
                      defaultValue={parseFloat(item?.emission_factor).toFixed(2)}
                      onChange={(e) => handleFactorChange(e)}
                      value={
                        // eslint-disable-next-line
                        item.id == factorId
                          ? parseFloat(tempFactor)
                          : parseFloat(item?.emission_factor)
                      }
                      style={{ width: "100%" }}
                      readOnly={item.id !== factorId}
                    />
                  </td>
                  <td className={classes.tableBodyCell} style={{ width: "10%" }}>
                    {/* <input
                      type="number"
                      value={
                        item?.emission_kgco2e
                          ? item?.emission_kgco2e.toFixed(2)
                          : 0.0
                      }
                      className={classes.activeCellBox}
                      disabled
                      style={{ width: "100%" }}
                    /> */}
  
                    <div
                      className={classes.activeCellBox}
                      style={{ width: "100%" }}
                    >
                      {item?.emission_kgco2e
                        ? item?.emission_kgco2e.toFixed(2)
                        : 0.0}
                    </div>
                  </td>
                  <td className={classes.tableBodyCell} style={{ width: "10%" }}>
                    <div
                      className={classes.activeCellBox}
                      style={{ width: "100%" }}
                    >
                      {item?.emission_tco2e
                          ? item?.emission_tco2e.toFixed(2)
                          : 0.0}
                    </div>
                  </td>
                  <td className={classes.tableBodyCell} style={{ width: "10%" }}>
  
                  <div
                      className={classes.activeCellBox}
                      style={{ width: "100%" }}
                    >
                      {item?.total_emission
                          ? item?.total_emission.toFixed(2)
                          : 0.0}                        
                    </div>
                  </td>
                </tr>
              ))
            )
          )}
   
        </tbody>
      </table>
      <div className={classes.btnContainer}>
        {saveLoading ? (
          <button
            className="page_save loader_container"
          >
            Saving&nbsp; <Spinner  animation="border"/>
          </button>
        ): (
          <button
            className="page_save"
            onClick={() => handleEmissionSave()}
          >
            
            Save Updates
          </button>
        )}

      </div>
      
    </div>
    <div
        style={{
          marginTop: "30px",
        }}
      >
        .
      </div>
    </div>
  );
};

export default ScopeTableComponent;

/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { sweetAlert, readCokkie } from '../../../../utils/UniversalFunction';
import React, { Component } from "react";
import Sidebar from "../../../sidebar/sidebar";
import Header from "../../../header/header";
import { Button,  Modal } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import config from "../../../../config/config.json";
import axios from "axios";
import "./esg_reporting.css";
import { authenticationService } from "../../../../_services/authentication";
import AssignSubAdminComponent from "../SmallComponents/assignSubAdmin";
const currentUser = authenticationService.currentUserValue;

export default class home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false,
      error: null,
      isLoaded: false,
      isLoaded2: false,
      isLoaded3: false,
      answersss: [],
      itemsForSubAdmin: [],
      isEditableOrNot: false,
      // selectedUser: [],
      questions: [],
      esgReportingQuestionsEnvironment: [],
      esgReportingQuestionsBusiness_model_innovation: [],
      esgReportingQuestionsCyber_digital: [],
      esgReportingQuestionsHuman_capital: [],
      esgReportingQuestionsLeadership_governance: [],
      esgReportingQuestionsSocial_capital: [],
      answers: [],
      submitted: false,
      assignedId: null,
      submissions: {
        frameworksUsed: [],
        environment: "",
        socialCapital: "",
        leadershipAndGovernance: "",
        humanCapital: "",
        cyberAndDigital: "",
        businessModelAndInnovation: "",
      },
      selected: [],
      selected2: [],
      selected3: [],
      selected4: [],
      selected5: [],
      selected6: [],
      selected7: [],
      selectedUser: [],
      selectedUser2: [],
      selectedUser3: [],
      selectedUser4: [],
      selectedUser5: [],
      selectedUser6: [],
      selectedUser7: [],
      isCompanySubAdminSubmit: false,
      isCompanyAdminSubmit: false,
      title: [],
      video_link: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.goToPreviousPath = this.goToPreviousPath.bind(this);

  }

  handleOpenModal(val) {
    this.setState({ activeModal: val });
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
    this.setState({ showModal: "" });
  }
  showHide(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === "password" ? "input" : "password"
    });
  }

  onClose() {
    this.setState({
      setOpen: false
    });
  }
  setOpen(event) {
    this.setState({
      setOpen: true
    });
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { submissions } = this.state;
    this.setState({
      submissions: {
        ...submissions,
        [name]: value,
      },
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const {
      selectedUser,
      selectedUser2,
      selectedUser3,
      selectedUser4,
      selectedUser5,
      selectedUser6,
      selectedUser7,
    } = this.state;
    let checkFrameworksUsed = selectedUser.length === 0;
    let checkEnvironment = selectedUser4.length === 0;
    let checkSocialCapital = selectedUser7.length === 0;
    let checkLeaderShipAndGovernance = selectedUser6.length === 0;
    let checkHumanCapital = selectedUser5.length === 0;
    let checkBusinessModelInnovation = selectedUser2.length === 0;
    let checkCyberDigital = selectedUser3.length === 0;
    if (
      checkFrameworksUsed &&
      checkEnvironment &&
      checkSocialCapital &&
      checkLeaderShipAndGovernance &&
      checkHumanCapital &&
      checkBusinessModelInnovation &&
      checkCyberDigital
    ) {
      return sweetAlert('error', "Select at least one checkbox in each category");
    }
    let isFrameworksUsed = this.state.selectedUser.filter(Boolean);
    let isEnvironment = this.state.selectedUser4.filter(Boolean);
    let isSocialCapital = this.state.selectedUser7.filter(Boolean);
    let isLeadershipAndGovernance = this.state.selectedUser6.filter(Boolean);
    let isHumanCapital = this.state.selectedUser5.filter(Boolean);
    let isBusinessModelInnovation = this.state.selectedUser2.filter(Boolean);
    let isCyberDigital = this.state.selectedUser3.filter(Boolean);

    let postData = {};
    let frameworksUsed = isFrameworksUsed;
    let environment = isEnvironment;
    let socialCapital = isSocialCapital;
    let leadershipAndGovernance = isLeadershipAndGovernance;
    let humanCapital = isHumanCapital;
    let cyberAndDigital = isCyberDigital;
    let businessModelAndInnovation = isBusinessModelInnovation;

    postData.frameworksUsed = frameworksUsed;
    postData.environment = environment;
    postData.socialCapital = socialCapital;
    postData.leadershipAndGovernance = leadershipAndGovernance;
    postData.humanCapital = humanCapital;
    postData.cyberAndDigital = cyberAndDigital;
    postData.businessModelAndInnovation = businessModelAndInnovation;
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    axios
      .post(config.OLD_API_URL + "esgReporting", { postData }, { headers })
      .then((response) => {
        sweetAlert('success', response.data.message);
        this.setState({ submitted: true });
        setTimeout(() => {
          const newLocal = "/esg_reporting_pie";
          this.props.history.push(newLocal);
        }, 1000);
      })
      .catch(function (error) {
        if (error.response) {
          sweetAlert('error', error.response.data.message);
        }
      });
  }
  // }

  goToPreviousPath() {
    this.props.history.goBack();
  }

  componentDidMount() {


    // Language fix
    const cokkie = readCokkie();
    if(cokkie === '/en/da') {
      const langElem1 = document.querySelector('.lang_fix1');
      const langElem2 = document.querySelector('.lang_fix2');

      if(langElem1) {
        langElem1.innerText=" er dEr direkte KorRelatiOon";
      }
      if(langElem2) {
        langElem2.innerText="omfatter";
      }
    }
    let token = JSON.parse(localStorage.getItem("currentUser")).data.token;
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };

    //   Get Answers Api
    fetch(config.API_URL + "getEsgReportingData", { headers })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded3: true,
            answersss: result.esgReportingAnswers,
            assignedId: result.esgReportingAnswers[0]?.assigned_user_id,
            selectedUser:
              result.esgReportingAnswers[0]?.frameworksUsed.split(",") ===
                undefined
                ? []
                : result.esgReportingAnswers[0]?.frameworksUsed.split(","),
            selectedUser2:
              result.esgReportingAnswers[0]?.businessModelAndInnovation.split(
                ","
              ) === undefined
                ? []
                : result.esgReportingAnswers[0]?.businessModelAndInnovation.split(
                  ","
                ),
            selectedUser3:
              result.esgReportingAnswers[0]?.cyberAndDigital.split(",") ===
                undefined
                ? []
                : result.esgReportingAnswers[0]?.cyberAndDigital.split(","),
            selectedUser4:
              result.esgReportingAnswers[0]?.environment.split(",") ===
                undefined
                ? []
                : result.esgReportingAnswers[0]?.environment.split(","),
            selectedUser5:
              result.esgReportingAnswers[0]?.humanCapital.split(",") ===
                undefined
                ? []
                : result.esgReportingAnswers[0]?.humanCapital.split(","),
            selectedUser6:
              result.esgReportingAnswers[0]?.leadershipAndGovernance.split(
                ","
              ) === undefined
                ? []
                : result.esgReportingAnswers[0]?.leadershipAndGovernance.split(
                  ","
                ),
            selectedUser7:
              result.esgReportingAnswers[0]?.socialCapital.split(",") ===
                undefined
                ? []
                : result.esgReportingAnswers[0]?.socialCapital.split(","),
            isEditableOrNot: result.insertOrUpdate,
          });
          //answersss  answersss[0]?.frameworksUsed.split(",")

        },
        (error) => {
          this.setState({
            isLoaded3: true,
            error,
          });
        }
      );

    fetch(config.API_URL + "getEsgReportingQuestions", { headers })
      .then((res) => res.json())
      .then(async (result) => {
          let mappedEnvironmentQuestion;
          let mappedHumanQuestion;
          let mappedBussinessQuestion;
          let mappedCyberQuestions;
          if(cokkie === '/en/da') {
            mappedEnvironmentQuestion = await result.esgReportingQuestionsEnvironment.map((item) => {
              let temp = item;
              if(temp.description === "Energy Management") {
                temp.description = "Energistyring"
              }
              if(temp.description === "Heritage") {
                temp.description = "Eftermæle"
              }
              return temp;
            });
            
            mappedHumanQuestion = await result.esgReportingQuestionsHuman_capital.map((item) => {
              let temp = item;
              if(temp.description === "Diversity, Equity & Inclusion") {
                temp.description = "Diversitet, Lighed & Inklusion"
              }
              
              if(temp.description === "Employee Recruitment, Engagement & Discovery") {
                temp.description = "Medarbejderrekruttering, engagement og oplæring"
              }
              return temp;
            });

            mappedBussinessQuestion = await result.esgReportingQuestionsBusiness_model_innovation.map((item) => {
              let temp = item;
              if(temp.description === "Investment, Credit & Underwriting ESG Risks") {
                temp.description = "ESG-risici for investeringer, kredit og forsikring"
              }

              if(temp.description === "Legacy of Products") {
                temp.description = "Eftermæle af produkter"
              }
              return temp;
            });
            
            mappedCyberQuestions = await result.esgReportingQuestionsCyber_digital.map((item) => {
              let temp = item;
              if(temp.description === "Disaster Recovery") {
                temp.description = "Katastrofeplan"
              }

              if(temp.description === "Legacy of Products") {
                temp.description = "Eftermæle af produkter"
              }
              return temp;
            });
          }
      
          this.setState({
            isLoaded2: true,
            questions: result.esgReportingQuestionsFrameWorkUsed,
            esgReportingQuestionsEnvironment: mappedEnvironmentQuestion ? mappedEnvironmentQuestion : result.esgReportingQuestionsEnvironment,
            esgReportingQuestionsBusiness_model_innovation: mappedBussinessQuestion ? mappedBussinessQuestion : result.esgReportingQuestionsBusiness_model_innovation,
            esgReportingQuestionsCyber_digital:mappedCyberQuestions ? mappedCyberQuestions : result.esgReportingQuestionsCyber_digital,
            esgReportingQuestionsHuman_capital: mappedHumanQuestion ? mappedHumanQuestion : result.esgReportingQuestionsHuman_capital,
            esgReportingQuestionsLeadership_governance:
              result.esgReportingQuestionsLeadership_governance,
            esgReportingQuestionsSocial_capital:
              result.esgReportingQuestionsSocial_capital,
          });
        },
        (error) => {
          this.setState({
            isLoaded2: true,
            error,
          });
        }
      );

    let uri = window.location.pathname.split("/");
    let category = uri[1];
    fetch(config.API_URL + `getIntroductionVideosbyLink/${category}`)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded2: true,
            title: (result.introductionVideo.length > 0) ? result.introductionVideo[0].title : '',
            video_link: (result.introductionVideo.length > 0) ? result.introductionVideo[0].video_link : '',
          });
        },
        
        (error2) => {
          this.setState({
            isLoaded2: true,
            error2,
          });
        }
      );
  }

  handleMultiSelect = (e, data) => {
    // debugger
    const { checked } = e.target;
    if (checked) {
      this.setState({
        selectedUser: [...this.state.selectedUser, data],
      });
    } else {
      let tempuser = this.state.selectedUser?.filter(
        (item) => Number(item) !== Number(data)
      );
      this.setState({
        selectedUser: tempuser,
      });
    }
  };
  handleMultiSelect2 = (e, data) => {

    // debugger
    const { checked } = e.target;
    if (checked) {
      this.setState({
        selectedUser2: [...this.state.selectedUser2, data],
      });
    } else {
      let tempuser2 = this.state.selectedUser2?.filter(
        (item2) => Number(item2) !== Number(data)
      );
      this.setState({
        selectedUser2: tempuser2,
      });
    }
  };
  handleMultiSelect3 = (e, data) => {
    // debugger
    const { checked } = e.target;
    if (checked) {
      this.setState({
        selectedUser3: [...this.state.selectedUser3, data],
      });
    } else {
      let tempuser3 = this.state.selectedUser3?.filter(
        (item3) => Number(item3) !== Number(data)
      );
      this.setState({
        selectedUser3: tempuser3,
      });
    }
  };
  handleMultiSelect4 = (e, data) => {
    // debugger
    const { checked } = e.target;
    if (checked) {
      this.setState({
        selectedUser4: [...this.state.selectedUser4, data],
      });
    } else {
      let tempuser4 = this.state.selectedUser4?.filter(
        (item4) => Number(item4) !== Number(data)
      );
      this.setState({
        selectedUser4: tempuser4,
      });
    }
  };
  handleMultiSelect5 = (e, data) => {
    // debugger
    const { checked } = e.target;
    if (checked) {
      this.setState({
        selectedUser5: [...this.state.selectedUser5, data],
      });
    } else {
      let tempuser5 = this.state.selectedUser5?.filter(
        (item5) => Number(item5) !== Number(data)
      );
      this.setState({
        selectedUser5: tempuser5,
      });
    }
  };
  handleMultiSelect6 = (e, data) => {
    // debugger
    const { checked } = e.target;
    if (checked) {
      this.setState({
        selectedUser6: [...this.state.selectedUser6, data],
      });
    } else {
      let tempuser6 = this.state.selectedUser6?.filter(
        (item6) => Number(item6) !== Number(data)
      );
      this.setState({
        selectedUser6: tempuser6,
      });
    }
  };
  handleMultiSelect7 = (e, data) => {
    // debugger
    const { checked } = e.target;
    if (checked) {
      this.setState({
        selectedUser7: [...this.state.selectedUser7, data],
      });
    } else {
      let tempuser7 = this.state.selectedUser7?.filter(
        (item7) => Number(item7) !== Number(data)
      );
      this.setState({
        selectedUser7: tempuser7,
      });
    }
  };

  render() {
    const {
      title,
      video_link,
      questions,
      esgReportingQuestionsEnvironment,
      esgReportingQuestionsBusiness_model_innovation,
      esgReportingQuestionsCyber_digital,
      esgReportingQuestionsHuman_capital,
      esgReportingQuestionsLeadership_governance,
      esgReportingQuestionsSocial_capital,
      selectedUser,
      selectedUser2,
      selectedUser3,
      selectedUser4,
      selectedUser5,
      selectedUser6,
      selectedUser7,
      isEditableOrNot
    } = this.state;
    let role = JSON.parse(localStorage.getItem("currentUser")).data.role;
    return (
      <>
        <Sidebar dataFromParent={this.props.location.pathname} />
        <Header />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="Introduction">
                        <div className="text_Parts">
                          <div className="esg-reporting">
                            <h5 className="motor font-heading">Introduction
                              <span className='icon_hitn' onClick={(e) => this.setOpen(e)}> <i className="fas fa-video"></i></span>
                            </h5>
                            <div className="form_x mb-3">
                              {currentUser.data.role === "company_admin" && (
                                <AssignSubAdminComponent tableName="esgreportings" />
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xxl-12 col-lg-12 col-md-12 col-12">
                              <p className="specialize">
                                Environmental, Social and Governance (ESG) is a framework and a methodology to improve financial and non-financial performance. This criterion <span className="lang_fix2">captures and critiques</span> all aspects of a business beyond business plans and financial reporting, aiming to improve financial performance while reducing operating risks. ESG is becoming a critical business management tool impacting debt funding rates, credit ratings and fund manager interest from the equity investment market, with better ESG strategy <span className="lang_fix1 changeLower">directly correlated</span> to improved share price performance.
                              </p>
                            </div>
                            <Modal open={this.state.setOpen} className="feedback2 feedback3 iframe_modal">
                              <Modal.Header>{title}</Modal.Header>
                                    <div className="form-group">
                                      <div className="video_esg">
                                        <iframe src={video_link} frameborder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen="true"></iframe>
                                      </div>
                                    </div>
                                  <Modal.Actions>
                                  <Button className="mx-3" onClick={() => this.onClose(false)}>
                                    Close
                                  </Button>
                                </Modal.Actions>
                            </Modal>
                          </div>
                        </div>
                      </div>
                      <form name="form" onSubmit={this.handleSubmit}>
                        <div className="Below_demo mt-4">
                          <div className="text_Parts">
                            <div className="text_ntroion">
                              <h5 className="frame">
                                Please select which ESG reporting frameworks you
                                have used in the past:
                              </h5>
                              <div className="Global">
                                <div className="row">
                                  <div className="col-sm-12 col-xs-12">
                                    <div className="border_box p-3">
                                      <div className="wel_fel">
                                        <div className="row">
                                          {questions.map((item, key) => (
                                            <div
                                              key={key}
                                              className="col-xxl-6 col-lg-6 col-md-12 col-12"
                                            >
                                              <div
                                                className="Global_text"
                                                key={key}
                                              >
                                                <div className="form-check form-check-inline clobal_check input-padding">
                                                  <input
                                                    className="form-check-input input_one "
                                                    name="frameworksUsed[]"
                                                    onChange={(e) =>
                                                      this.handleMultiSelect(
                                                        e,
                                                        item.id
                                                      )
                                                    }
                                                    checked={selectedUser?.some(
                                                      (frameworkiuse) =>
                                                        Number(frameworkiuse) ===
                                                        item.id
                                                    )}
                                                    type="checkbox"
                                                    id={"frameworks" + (key + 1)}
                                                  />
                                                  <label
                                                    className="form-check-label label_one"
                                                    htmlFor={"frameworks" + (key + 1)}
                                                  >
                                                    {item.description}
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="mt-4">
                          <div className="col-sm-12">
                            <div className="Below_demo">
                              <div className="text_Parts">
                                <div className="text_Below_one">
                                  {/* <h6 className="back_quninti_below">
                                    <a className="back_text_icon" href="#">
                                      <span className="step_icon_text">
                                        <i className="far fa-long-arrow-alt-left"></i>
                                      </span>
                                      Back
                                    </a>
                                  </h6> */}
                                  <p className="Below">
                                    Below we have listed a range of potential risks that may impact your business. Select the ones about your business so we can understand how to improve its performance.
                                  </p>

                                  <div className="border_box p-3">
                                    <div className="Below_text">
                                      <div className="row">
                                        <div className="col-xxl-4 col-lg-6 col-md-12 col-12">
                                          <div className="better_dic">
                                            <div className="golp"></div>
                                            <div className="Emissions">
                                              <h4 className="ronment">
                                                Environment
                                              </h4>
                                            </div>
                                            {esgReportingQuestionsEnvironment.map(
                                              (item2, key2) => (
                                                <div
                                                  className="Global_text"
                                                  key={key2}
                                                >
                                                  <div className="form-check form-check-inline clobal_check input-padding">
                                                    <input
                                                      className="form-check-input input_one"
                                                      type="checkbox"
                                                      name="environment[]"
                                                      onChange={(e) =>
                                                        this.handleMultiSelect4(
                                                          e,
                                                          item2.id
                                                        )
                                                      }
                                                      checked={selectedUser4?.some(
                                                        (eenvironment) =>
                                                          Number(eenvironment) ===
                                                          item2.id
                                                      )}
                                                      id={"environment" + (key2 + 1)}
                                                    />
                                                    <label
                                                      className="form-check-label label_one"
                                                      htmlFor={"environment" + (key2 + 1)}
                                                    >
                                                      {item2.description}
                                                    </label>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                        <div className="col-xxl-4 col-lg-6 col-md-12 col-12">
                                          <div className="better_dic">
                                            <div className="golp"></div>
                                            <div className="Emissions">
                                              <h4 className="ronment">
                                                Social Capital
                                              </h4>
                                            </div>

                                            {esgReportingQuestionsSocial_capital.map(
                                              (item3, key3) => (
                                                <div
                                                  className="Global_text"
                                                  key={key3}
                                                >
                                                  <div className="form-check form-check-inline clobal_check input-padding">
                                                    <input
                                                      className="form-check-input input_one"
                                                      name="social_capital[]"
                                                      onChange={(e) =>
                                                        this.handleMultiSelect7(
                                                          e,
                                                          item3.id
                                                        )
                                                      }
                                                      checked={selectedUser7?.some(
                                                        (eenvironment) =>
                                                          Number(eenvironment) ===
                                                          item3.id
                                                      )}
                                                      type="checkbox"
                                                      id={"socialCapital" + (key3 + 1)}
                                                    />
                                                    <label
                                                      className="form-check-label label_one"
                                                      htmlFor={"socialCapital" + (key3 + 1)}
                                                    >
                                                      {item3.description}
                                                    </label>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                        <div className="col-xxl-4 col-lg-6 col-md-12 col-12">
                                          <div className="Emissions">
                                            <h4 className="ronment">
                                              Leadership & Governance
                                            </h4>
                                          </div>

                                          {esgReportingQuestionsLeadership_governance.map(
                                            (item4, key4) => (
                                              <div
                                                className="Global_text"
                                                key={key4}
                                              >
                                                <div className="form-check form-check-inline clobal_check input-padding">
                                                  <input
                                                    className="form-check-input input_one"
                                                    type="checkbox"
                                                    name="leadership_governance[]"
                                                    onChange={(e) =>
                                                      this.handleMultiSelect6(
                                                        e,
                                                        item4.id
                                                      )
                                                    }
                                                    checked={selectedUser6?.some(
                                                      (eenvironment) =>
                                                        Number(eenvironment) ===
                                                        item4.id
                                                    )}
                                                    id={"leadershipGovernance" + (key4 + 1)}
                                                  />
                                                  <label
                                                    className="form-check-label label_one"
                                                    htmlFor={"leadershipGovernance" + (key4 + 1)}
                                                  >
                                                    {item4.description}
                                                  </label>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                      <hr style={{"background": "#ebefff"}}/>
                                      {/* <!-- Human Capital --> */}
                                      <div className="row mt-4">
                                        <div className="col-xxl-4 col-lg-6 col-md-12 col-12">
                                          <div className="Emissions">
                                            <h4 className="ronment">
                                              Human Capital
                                            </h4>
                                          </div>

                                          {esgReportingQuestionsHuman_capital.map(
                                            (item5, key5) => (
                                              <div
                                                className="Global_text"
                                                key={key5}
                                              >
                                                <div className="form-check form-check-inline clobal_check input-padding">
                                                  <input
                                                    className="form-check-input input_one"
                                                    name="human_capital[]"
                                                    onChange={(e) =>
                                                      this.handleMultiSelect5(
                                                        e,
                                                        item5.id
                                                      )
                                                    }
                                                    checked={selectedUser5?.some(
                                                      (eenvironment) =>
                                                        Number(eenvironment) ===
                                                        item5.id
                                                    )}
                                                    type="checkbox"
                                                    id={"humanCapital" + (key5 + 1)}
                                                  />
                                                  <label
                                                    className="form-check-label label_one"
                                                    htmlFor={"humanCapital" + (key5 + 1)}
                                                  >
                                                    {item5.description}
                                                  </label>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                        <div className="col-xxl-4 col-lg-6 col-md-12 col-12">
                                          <div className="Emissions">
                                            <h4 className="ronment">
                                              Business Model & Innovation
                                            </h4>
                                          </div>

                                          {esgReportingQuestionsBusiness_model_innovation.map(
                                            (item6, key6) => (
                                              <div
                                                className="Global_text"
                                                key={key6}
                                              >
                                                <div className="form-check form-check-inline clobal_check input-padding">
                                                  <input
                                                    className="form-check-input input_one"
                                                    name="business_model_innovation[]"
                                                    onChange={(e) =>
                                                      this.handleMultiSelect2(
                                                        e,
                                                        item6.id
                                                      )
                                                    }
                                                    checked={selectedUser2?.some(
                                                      (eenvironment) =>
                                                        Number(eenvironment) ===
                                                        item6.id
                                                    )}
                                                    type="checkbox"
                                                    id={"businessModelInnovation" + (key6 + 1)}
                                                  />
                                                  <label
                                                    className="form-check-label label_one"
                                                    htmlFor={"businessModelInnovation" + (key6 + 1)}
                                                  >
                                                    {item6.description}
                                                  </label>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                        <div className="col-xxl-4 col-lg-6 col-md-12 col-12">
                                          <div className="Emissions">
                                            <h4 className="ronment">
                                              Cyber & Digital
                                            </h4>
                                          </div>
                                          {esgReportingQuestionsCyber_digital.map(
                                            (item7, key7) => (
                                              <div
                                                className="Global_text"
                                                key={key7}
                                              >
                                                <div className="form-check form-check-inline clobal_check input-padding">
                                                  <input
                                                    className="form-check-input input_one"
                                                    name="cyber_digital[]"
                                                    onChange={(e) =>
                                                      this.handleMultiSelect3(
                                                        e,
                                                        item7.id
                                                      )
                                                    }
                                                    checked={selectedUser3?.some(
                                                      (eenvironment) =>
                                                        Number(eenvironment) ===
                                                        item7.id
                                                    )}
                                                    type="checkbox"
                                                    id={"cyberDigital" + (key7 + 1)}
                                                  />
                                                  <label
                                                    className="form-check-label label_one"
                                                    htmlFor={"cyberDigital" + (key7 + 1)}
                                                  >
                                                    {item7.description}
                                                  </label>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="my-5"></div>
                                  <div className="global_link" style={{gap:'15px'}}>
                                    <span className="global_link">
                                      <a
                                        className="link_bal_next"
                                        onClick={this.goToPreviousPath}
                                      >
                                        Back
                                      </a>
                                    </span>
                                    <span className="global_link">

                                      {isEditableOrNot && (
                                        <button
                                          className="page_width page_save"
                                          type="submit"
                                        >
                                          Next
                                        </button>
                                      )}
                                      {!isEditableOrNot &&
                                        role === "company_sub_admin" && (
                                          <NavLink
                                            className="page_width page_save"
                                            to={"/esg_reporting_pie"}
                                          >
                                            Next
                                          </NavLink>
                                        )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </>
    );
  }
}

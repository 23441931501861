/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  sweetAlert,
  isValidEmail,
  isValidName,
} from "../../../../utils/UniversalFunction";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../../sidebar/sidebar";
import Header from "../../../header/header";
import config from "../../../../config/config.json";
import "./supplier_management.css";
import axios from "axios";
import { authenticationService } from "../../../../_services/authentication";
const currentUser = authenticationService.currentUserValue;

export default class supplier_management_form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      criteria: [props.location.aboutProps] ?? [],
      finalIds: [],
      submitted: false,
      csvsubmitted: false,
      firstName: "",
      lastName: "",
      email: "",
      companyName1: "",
      firstName1: "",
      lastName1: "",
      email1: "",
      companyName: "",
      companyIndustory: [],
      industry: "",
      industry1: "",
      industryList: [],
      addNewSupplier: false,
      addNewSupplierButton: true,
      emailvalidation: false,
      emailvalidationMessage: "",
      firstNameValidation: false,
      firstNameMessage: "",
      lastNameValidation: false,
      lastNameMessage: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeForSelect = this.handleChangeForSelect.bind(this);
    this.handleChangeForSelect1 = this.handleChangeForSelect1.bind(this);
    this.applySecondForm = this.applySecondForm.bind(this);
    this.updateMessage = this.updateMessage.bind(this);
  }

  handleChangeForSelect(event) {
    const { value, industry } = event;
    this.setState({
      [industry]: value,
    });
  }

  handleChangeForSelect1(event) {
    const { value, industry1 } = event;
    this.setState({
      [industry1]: value,
    });
  }

  applySecondForm() {
    this.setState({
      addNewSupplier: true,
      addNewSupplierButton: false,
    });
  }
  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (name === "email") {
      let condition = isValidEmail(value);
      if (condition === true) {
        this.setState({
          emailValidation: true,
          emailValidationMessage: "",
        });
      } else {
        this.setState({
          emailValidation: false,
          emailValidationMessage: "Please check email format",
        });
      }
    }
    if (name === "firstName") {
      let condition = isValidName(value);
      if (condition === true) {
        this.setState({
          firstNameValidation: true,
          firstNameMessage: "",
        });
      } else {
        this.setState({
          firstNameValidation: false,
          firstNameMessage: "Please check firstname format",
        });
      }
    }
    // if (name === "companyName") {
    //   let condition = isValidName(value);
    //   if (condition === true) {
    //     this.setState({
    //       companyNameValidation: true,
    //       companyNameMessage: "",
    //     });
    //   } else {
    //     this.setState({
    //     companyNameValidation: false,
    //     companyNameMessage: "Please check companyname format",
    //     });
    //   }
    // }


    if (name === "lastName") {
      let condition = isValidName(value);
      if (condition === true) {
        this.setState({
          lastNameValidation: true,
          lastNameMessage: "",
        });
      } else {
        this.setState({
          lastNameValidation: false,
          lastNameMessage: "Please check lastname format",
        });
      }
    }

    this.setState({
      [name]: value,
    });
  }
  // handleNameChange = (event) => {
  //   const companyName = event.target.value;
  //   const isInvalid = companyName !== "" && !/^[A-Za-z]+$/.test(companyName);
  //   this.setState({ companyName, isInvalid });
  // };

  updateMessage() {
    sweetAlert("info", "The Document will be uploaded within next 48 hours");
  }
  handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const {
      firstName,
      lastName,
      email,
      companyName,
      finalIds,
      industry,
      addNewSupplier,
      firstName1,
      lastName1,
      email1,
      companyName1,
      industry1,
      emailValidation,
      firstNameValidation,
      lastNameValidation
    } = this.state;
    if ( firstName && lastName && email && companyName && industry && emailValidation && firstNameValidation && lastNameValidation ) {
      if (addNewSupplier) {
        if (
          !firstName1 &&
          !lastName1 &&
          !email1 &&
          !companyName1 &&
          !industry1
        ) {
          return;
          //  sweetAlert('error',"Please fill all input");
        }
      }
      // let textCriteria = "";
      // finalIds.forEach((element) => {
      //   textCriteria += element.title + ">";
      // });

      let textCriteria = "";
      finalIds.forEach((element, index) => {
        textCriteria += element.title;
        if (index < finalIds.length - 1) {
          textCriteria += ">";
        }
      });

      let secondObject = {};
      secondObject.firstName = firstName1;
      secondObject.lastName = lastName1;
      secondObject.email = email1;
      secondObject.companyName = companyName1;
      secondObject.industry = industry1;
      secondObject.addNewSupplier = addNewSupplier;

      let followingCriteria = textCriteria;
      const headers = {
        Authorization: `Bearer ${currentUser.data.token}`,
        Accept: "application/json",
      };

      axios
        .post(
          `${config.OLD_API_URL}supplierManagement`,
          {
            followingCriteria,
            firstName,
            lastName,
            email,
            companyName,
            industry,
            secondObj: secondObject,
          },
          { headers }
        )
        .then((response) => {
          sweetAlert("success", response.data[0].message);
          setTimeout(() => {
            const newLocal = "/supplier_management_detail";
            this.props.history.push(newLocal);
          }, 1000);
        })
        .catch(function (error) {
          if (error.response) {
            sweetAlert("error", error.message);
          }
        });
    } else {
      sweetAlert("error", "Please fill all input");
    }
  }

  // emailValidator = (text) => {
  //   let reg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  //   if (reg.test(text) === false) {
  //     this.setState({ emailError: "Please enter a valid email address" });
  //     return false;
  //   } else {
  //     this.setState({ email: text, emailError: "" });
  //   }
  // };

  // validateForm = () => {
  //   const { formData } = this.state;
  //   let isValid = true;
  //   const errors = {};

  //   if (formData.companyName.trim() === "") {
  //     errors.companyName = "Company name is required";
  //     isValid = false;
  //   }

  //   if (formData.firstName.trim() === "") {
  //     errors.firstName = "Contact name is required";
  //     isValid = false;
  //   }

  //   if (formData.lastName.trim() === "") {
  //     errors.lastName = "Last name is required";
  //     isValid = false;
  //   }

  //   if (formData.email.trim() === "") {
  //     errors.email = "Email address is required";
  //     isValid = false;
  //   } else if (!this.validateEmail(formData.email)) {
  //     errors.email = "Invalid email address";
  //     isValid = false;
  //   }

  //   this.setState({ errors });
  //   return isValid;
  // };

  validateEmail = (email) => {
    // Email validation regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  handleChange1 = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };

  onFileChange = (event) => {
    this.setState({ csvsubmitted: true });
    let topicId = event.target.getAttribute("data-id");
    const formData = new FormData();

    // Update the formData object
    formData.append("csv", event.target.files[0], event.target.files[0].name);
    formData.append("supplier_management_criteria_ids", topicId);

    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    axios
      .post(config.OLD_API_URL + "supplierManagementWithCSV", formData, {
        headers,
      })
      .then((response) => {
        // eslint-disable-next-line
        if(response.data.error == false) {
          sweetAlert("success", response.data.message);
          setTimeout(() => {
            const newLocal = "/supplier_management_detail";
            this.props.history.push(newLocal);
          }, 1000);
        }else{
          sweetAlert("error", response.data.message);
          setTimeout(() => {
            window.location.reload()
          }, 1000);
        }
      })
      .catch(function (response) {
        sweetAlert("error", response.data.message);
        setTimeout(() => {
          window.location.reload()
        }, 1000);
      });
  };

  componentDidMount(id) {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ type: "supplier_management" }),
    };

    if (currentUser.data.role === "company_sub_admin") {
      fetch(
        config.API_URL + "getSupplierManagementAssignedUser",
        requestOptions
      )
        .then((res) => res.json())
        .then(
          (result) => {
            if (currentUser.data.role === "company_sub_admin") {
              let getStatus = result.data.length > 0 ? true : false;
              if (getStatus === false) {
                this.props.history.push("/supplier_management_detail");
              }
            }
            this.setState({
              isLoaded: true,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
    }

    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    axios
      .post(
        config.API_URL + "getSupplierManagementCriteriaWithCondition",
        { ids: this.state.criteria },
        { headers }
      )
      .then((response) => {
        this.setState({ finalIds: response.data.data });
      })
      .catch(function (response) {
        sweetAlert("error", response.data.message);
      });

    axios
      .get(config.API_URL + "getSupplierManagementIndustry", { headers })
      .then((response) => {
        this.setState({ industryList: response.data.data });
      })
      .catch(function (response) {
        sweetAlert("error", response.data.message);
      });

    fetch(config.API_URL + "getCompanyIndustoryName")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded2: true,
            companyIndustory: result.companyIndustory,
          });
        },

        (error2) => {
          this.setState({
            isLoaded2: true,
            error2,
          });
        }
      );
  }

  render() {
    const {
      criteria,
      firstName,
      lastName,
      email,
      companyName,
      firstName1,
      lastName1,
      email1,
      companyName1,
      submitted,
      industry,
      companyIndustory,
      // companyname,
      // isInvalid,
      // formData,
      // errors,
    } = this.state;
    return (
      <div>
        <Sidebar dataFromParent={this.props.location.pathname} />
        <Header />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="requirem">
                        <div className="text_Parts">
                          <div className="text_suppliers mb-0 pb-3">
                            <h4 className="suppliers_text pb-0">
                              Add your suppliers' details below and we will
                              reach out to them on your behalf.
                            </h4>
                          </div>

                          <div className="text_Parts">
                            <div className="Supplier_Company">
                              <div className="forms">
                                <form name="supplier_form" autoComplete="off" onSubmit={this.handleSubmit}>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="row boxborder border_box mb-3">
                                        <div className="col-xxl-6 col-lg-12 col-md-12 col-12">
                                          <div className="Supplier_any">
                                            <div className="form-group mb-3">
                                              <label htmlFor="exampleInputEmail1">
                                                Supplier Company Name*
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                onChange={this.handleChange}
                                                value={companyName}
                                                name="companyName"
                                                id="floatingInput"
                                                autoComplete="new-companyName"
                                                list="companyNameList"
                                                // onChange={this.handleChange1}
                                                // className={`form-control mellp ${
                                                //   isInvalid ? "is-invalid" : ""
                                                // }`}
                                                placeholder="Enter Company Name"
                                              />
                                              <datalist id="companyNameList"></datalist>
                                              {/* {isInvalid && (
                                                <div className="invalid-feedback">
                                                  Please enter alphabetic
                                                  characters only
                                                </div>
                                              )}
                                              */}
                                              
                                              {this.state.companyNameValidation ===
                                                false && (
                                                <div className="help-block">
                                                  {this.state.companyNameMessage}
                                                </div>
                                              )}
                                              {submitted && !companyName && (
                                                <div className="help-block">
                                                  Company Name is required
                                                </div>
                                              )} 
                                              {/* {errors.companyName && (
                                                <span>
                                                  {errors.companyName}
                                                </span>
                                              )} */}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-xxl-6 col-lg-12 col-md-12 col-12">
                                          <div className="Supplier_any contact_first">
                                            <div className="form-group">
                                              <label htmlFor="exampleInputEmail1">
                                                Contact First Name*
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                onChange={this.handleChange}
                                                value={firstName}
                                                name="firstName"
                                                id="floatingInput"
                                                placeholder="Enter First Name"
                                                // autoComplete="new-firstName"
                                                // onChange={this.handleFirstNameChange}
                                                // className={`form-control mellp ${
                                                //   isInvalid ? "is-invalid" : ""
                                                // }`}
                                              />

                                              {this.state.firstNameValidation ===
                                                false && (
                                                <div className="help-block">
                                                  {this.state.firstNameMessage}
                                                </div>
                                              )}
                                              {submitted && !firstName && (

                                                <div className="help-block">
                                                  First Name is required
                                                </div>
                                              )} 
                                              {/* {errors.firstName && (
                                                <span>{errors.firstName}</span>
                                              )} */}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-xxl-6 col-lg-12 col-md-12 col-12">
                                          <div className="Supplier_any mb-3">
                                            <div className="form-group">
                                              <label htmlFor="exampleInputEmail1">
                                                Contact Last Name*
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                onChange={this.handleChange}
                                                value={lastName}
                                                name="lastName"
                                                id="floatingInput"
                                                // autoComplete="off"
                                                autoComplete="new-lastName"
                                                placeholder="Enter Last Name"
                                              />
                                              {this.state.lastNameValidation ===
                                                false && (
                                                <div className="help-block">
                                                  {this.state.lastNameMessage}
                                                </div>
                                              )}
                                              {submitted && !lastName && (

                                                <div className="help-block">
                                                  Last Name is required
                                                </div>
                                              )} 
                                              {/* {errors.lastName && (
                                                <span>{errors.lastName}</span>
                                              )} */}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-xxl-6 col-lg-12 col-md-12 col-12">
                                          <div className="Supplier_any">
                                            <div className="form-group">
                                              <label htmlFor="exampleInputEmail1">
                                                Contact Email Address*
                                              </label>
                                              <input
                                                type="email"
                                                // onBlur={(text) =>
                                                //   this.emailValidator(text)
                                                // }
                                                className="form-control"
                                                onChange={this.handleChange}
                                                value={email}
                                                name="email"
                                                id="floatingInput"
                                                autoComplete="new-email"
                                                placeholder="Enter Email Address"
                                              />

                                              {this.state.emailValidation ===
                                                false && (
                                                <div className="help-block">
                                                  {
                                                    this.state
                                                      .emailValidationMessage
                                                  }
                                                </div>
                                              )}
                                              {submitted && !email && (

                                                <div className="help-block">
                                                  Email Address is required
                                                </div>
                                              )} 
                                              {/* <span style={{ color: "red"}}>
                                                {this.state.emailError}
                                              </span> */}
                                              {/* {errors.email && (
                                                <span>{errors.email}</span>
                                              )} */}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-xxl-6 col-lg-12 col-md-12 col-12">
                                          <div className="Supplier_any contact_first">
                                            <div className="form-group">
                                              <label htmlFor="exampleInputEmail1">
                                                Industry*
                                              </label>
                                              <div className="pb4">
                                                <div className="input-group mb-3">
                                                  <span>
                                                    <i className=""></i>
                                                  </span>
                                                  <select
                                                    name="industry"
                                                    id="industry"
                                                    placeholder="Select Industry Name"
                                                    onChange={this.handleChange}
                                                    className="form-control name_nf select-div2 my-3"
                                                  >
                                                    <option
                                                      value=""
                                                      disabled
                                                      selected
                                                    >
                                                      Please Select Industry
                                                      Name
                                                    </option>
                                                    {companyIndustory.map(
                                                      (item) => (
                                                        <option
                                                          value={item.title}
                                                        >
                                                          {item.title}
                                                        </option>
                                                      )
                                                    )}
                                                    {submitted && !industry && (
                                                      <div className="help-block">
                                                        Industry is required
                                                      </div>
                                                    )}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {this.state.addNewSupplier && (
                                          <div className="row mt-5">
                                            <div className="hr_supplier">
                                              <span className="or_supplier">
                                                AND
                                              </span>
                                            </div>
                                            <div className="col-md-6 mt-5">
                                              <div className="Supplier_any">
                                                <div className="form-group mb-3">
                                                  <label htmlFor="exampleInputEmail1">
                                                    Supplier Company Name*
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                    value={companyName1}
                                                    name="companyName1"
                                                    id="floatingInput"
                                                    placeholder="Enter Company Name"
                                                    autoComplete="new-companyName1"
                                                  />
                                                  {submitted &&
                                                    !companyName1 && (
                                                      <div className="help-block">
                                                        Company Name is required
                                                      </div>
                                                    )}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-md-6 mt-5">
                                              <div className="Supplier_any">
                                                <div className="form-group">
                                                  <label htmlFor="exampleInputEmail1">
                                                    Contact First Name*
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                    value={firstName1}
                                                    name="firstName1"
                                                    id="floatingInput"
                                                    placeholder="Enter First Name"
                                                    autoComplete="new-firstName1"

                                                  />
                                                  {/* 
                                                  {this.state
                                                    .firstNameValidation ===
                                                    false && (
                                                    <div className="help-block">
                                                      {
                                                        this.state
                                                          .firstNameMessage
                                                      }
                                                    </div>
                                                  )} */}
                                                  {submitted && !firstName1 && (
                                                    <div className="help-block">
                                                      First Name is required
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="Supplier_any mb-3">
                                                <div className="form-group">
                                                  <label htmlFor="exampleInputEmail1">
                                                    Contact Last Name*
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                    value={lastName1}
                                                    name="lastName1"
                                                    id="floatingInput"
                                                    placeholder="Enter Last Name"
                                                    autoComplete="new-lastName"
                                                  />
                                                  {submitted && !lastName1 && (
                                                    <div className="help-block">
                                                      Last Name is required
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="Supplier_any">
                                                <div className="form-group">
                                                  <label htmlFor="exampleInputEmail1">
                                                    Contact Email Address*
                                                  </label>
                                                  <input
                                                    type="email"
                                                    // onBlur={(text) =>
                                                    //   this.emailValidator(text)
                                                    // }
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                    value={email1}
                                                    name="email1"
                                                    id="floatingInput"
                                                    placeholder="Enter Email Adress"
                                                    autoComplete="new-email1"
                                                  />
                                                  {submitted && !email1 && (
                                                    <div className="help-block">
                                                      Email Address is required
                                                    </div>
                                                  )}
                                                  {/* <span style={{ color: "red" }}>
                                                    {this.state.emailError}
                                                  </span> */}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="Supplier_any">
                                                <div className="form-group">
                                                  <label htmlFor="exampleInputEmail1">
                                                    Industry*
                                                  </label>

                                                  <div className="pb4">
                                                    <div className="input-group mb-3">
                                                      {/* <i classname="fa fa-down-arrow"></i> */}
                                                      <span>
                                                        <i className=""></i>
                                                      </span>
                                                      <select
                                                        name="industry1"
                                                        id="industry"
                                                        placeholder="Select Industry Name"
                                                        onChange={
                                                          this.handleChange
                                                        }
                                                        className="form-control name_nf select-div2 my-3"
                                                      >
                                                        <option
                                                          value=""
                                                          disabled
                                                          selected
                                                        >
                                                          Please Select Industry
                                                          Name
                                                        </option>
                                                        {companyIndustory.map(
                                                          (item) => (
                                                            <option
                                                              value={item.title}
                                                            >
                                                              {item.title}
                                                            </option>
                                                          )
                                                        )}
                                                      </select>
                                                      {submitted &&
                                                        !industry && (
                                                          <div className="help-block">
                                                            Industry is required
                                                          </div>
                                                        )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                      {this.state.addNewSupplierButton && (
                                        <div className="add_text ">
                                          <a
                                            className="add_supplier_t"
                                            onClick={this.applySecondForm}
                                            href="#"
                                          >
                                            + add new Supplier
                                          </a>
                                        </div>
                                      )}
                                      <div className="row">
                                        <div className="col-md-12 col-xs-12">
                                          <div className="add_new plu_wrap">
                                            <div className="hr_supplier">
                                              <span className="or_supplier">
                                                OR
                                              </span>
                                            </div>
                                            <div className="cct pt-0 pb-2 mt-5">
                                              <div className="image-upload-wrap1">
                                                <input
                                                  type="file"
                                                  className="file-upload-input"
                                                  name="supplierData"
                                                  accept=".csv"
                                                  data-id={criteria}
                                                  onChange={this.onFileChange}
                                                />
                                                <div className="drag-text">
                                                  <i className="fas fa-file-csv"></i>
                                                  <h3 className="upload-text">
                                                    Click here to upload
                                                    suppliers list via CSV file.
                                                  </h3>
                                                </div>
                                                <div className="file-upload-content">
                                                  <img
                                                    className="file-upload-image"
                                                    src="#"
                                                    alt=""
                                                  />
                                                  <div className="image-title-wrap">
                                                    <button
                                                      type="button"
                                                      className="remove-image"
                                                    >
                                                      Remove
                                                      <span className="image-title">
                                                        Uploaded Image
                                                      </span>
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-12">
                                            <div className="mb-3 text-center">
                                              <Link
                                                to="../../../../../../template.csv"
                                                target="_blank"
                                                className="m-0 need_full"
                                                type="button"
                                              >
                                                <span className="need_blue">
                                                  Need Help?
                                                </span>
                                                <span className="need_green">
                                                  Download Sample CSV
                                                </span>
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <hr className="heling" />
                                    </div>
                                  </div>

                                  <div className="global_link mx-0">
                                    <button className="page_save" type="submit">
                                      submit
                                    </button>

                                    {/* {submitted && (
                                      <NavLink
                                        to="/supplier_management_detail"
                                        title="The Report will be uploaded within next 48 hours"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        className="sector_questions_one page_save page_width"
                                        href="#"
                                      >
                                        Generate Report
                                      </NavLink>
                                    )} */}
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
/* eslint-disable jsx-a11y/role-supports-aria-props */
import Swal from "sweetalert2";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import config from "../../../../config/config.json";
import axios from "axios";

import Sidebar from "../../../sidebar/sidebar";
import Header from "../../../header/header";
import { isValidEmail } from "../../../../utils/UniversalFunction";
import { authenticationService } from "../../../../_services/authentication";
import './billing.css';
const currentUser = authenticationService.currentUserValue;

export default class SettingSubAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeModal: "",
      showModal: false,
      email: "",
      submitted: false,
      limit: 100,
      skip: 0,
      startDate: "",
      endDate: "",
      totalCount: 0,

      list: [],
      perPage: 10,
      page: 0,
      pages: 0,
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleOpenModal(val) {
    this.setState({ activeModal: val });
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
    this.setState({ showModal: "" });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });

    const { email } = this.state;

    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    if (email) {
      let isValid = isValidEmail(email);
      if (isValid === true) {
        axios
          .post(
            config.API_URL + "sendInviteForSubAdmin",
            {
              email: email,
            },
            { headers }
          )
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: response.data.message,
              showConfirmButton: false,
              timer: 1000,
            });

            setTimeout(() => {
              this.handleCloseModal();
              this.setState({ email: "" });
              this.setState({ submitted: false });
            }, 1000);
          })
          .catch(function (error) {
            if (error.response) {
              Swal.fire({
                icon: "error",
                title: error.response.data.message,
                showConfirmButton: false,
                timer: 1000,
              });
            }
          });
      } else {
        Swal.fire({
          icon: "error",
          title: "Invalid Email Format!",
          showConfirmButton: false,
          timer: 1000,
        });
      }
    }
  }

  componentDidMount() {
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    fetch(
      config.OLD_API_URL +
        `getCompanySubAdmin?limit=${this.state.limit}&skip=${this.state.skip}`,
      { headers }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            totalCount: result.totalCount,
            items: result.data,
            list: result.data,
            pages: Math.floor(result.data.length / this.state.perPage),
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  handlePageClick = (event) => {
    let page = event.selected;
    this.setState({ page });
  };

  render() {
    const { page, perPage, list } = this.state;
    let items = list.slice(page * perPage, (page + 1) * perPage);
    let ii = 0;
    let weathers =
      items.map((item) => {
        return (
          <tr key={ii++}>
            <td>{item.id}</td>
            <td>{item.firstName + " " + item.lastName}</td>
            <td>{item.email}</td>
            <td>{item.position}</td>
            <td>
              <NavLink
                to={"sub_accounts/" + item.uuid}
                className={item.isActive === 1 ? "green" : "red"}
              >
                {item.isActive === 1 ? "Active" : "Inactive"}
              </NavLink>
            </td>
          </tr>
        );
      }) || "";
    return (
      <div>
        <Header />
        <Sidebar dataFromParent={this.props.location.pathname} />

        <div className="main_wrapper">
          <div
            className={
              localStorage.getItem("role") === "company_admin"
                ? "tabs-top my_profile_menus"
                : "tabs-top my_profile_menus setting_tab_d"
            }
          >
            <ul>
              <li>
                <NavLink to="/Profile_Settings">My Profile</NavLink>
              </li>
              <li>
                <NavLink to="/Google_2FA"> Two Factor Authentication</NavLink>
              </li>
              <li>
                <NavLink to="/Setting_Billing">Billing</NavLink>
              </li>
              <li>
                <NavLink to="/sub_accounts" className="activee">
                  Sub Accounts
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="Introduction framwork_2">
                        <section className="forms">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="business_detail">
                                <div className="heading">
                                  <h4>Business Details</h4>
                                  <div className="directly p-0">
                                    <button
                                      className="link_bal_next page_width white w-100"
                                      variant="none"
                                      onClick={() =>
                                        this.handleOpenModal("login")
                                      }
                                    >
                                      Add new Sub Account
                                    </button>
                                  </div>
                                </div>
                                <hr className="line"></hr>
                              </div>

                              <div className="table_f">
                                <Table striped bordered hover size="sm">
                                  <thead>
                                    <tr className="heading_color">
                                      <th className="t_head">ID</th>
                                      <th className="t_head">SUB ACCOUNTS NAME</th>
                                      <th className="t_head">EMAIL</th>
                                      <th className="t_head">TITLE</th>
                                      <th className="t_head">STATUS</th>
                                    </tr>
                                  </thead>
                                  <tbody>{weathers}</tbody>
                                </Table>
                                <div className="Page navigation example"></div>
                              </div>
                            </div>
                          </div>
                        </section>

                        <Modal
                          animation={true}
                          size="md"
                          className="modal_box"
                          shadow-lg="border"
                          show={
                            this.state.showModal &&
                            this.state.activeModal === "login"
                          }
                        >
                          <div className="modal-lg">
                            <Modal.Header>
                              <Modal.Title className="pb3">
                                <h4>Invite New Sub Accounts</h4>
                              </Modal.Title>
                              <Button
                                variant="outline-dark"
                                onClick={this.handleCloseModal}
                              >
                                <i className="fa fa-times"></i>
                              </Button>
                            </Modal.Header>
                            <div className="modal-body vekp pt-0">
                              <div className="row">
                                <div className="col-md-12">
                                  <form
                                    name="form"
                                    onSubmit={this.handleSubmit}
                                  >
                                    <div className="forms">
                                      <div className="form-group">
                                        <label
                                          htmlFor="exampleFormControlInput1"
                                          className="form-label fw-bold mt-3"
                                        >
                                          Enter users email address below to
                                          send sign up invite
                                        </label>
                                        <input
                                          className="form-control my-3"
                                          aria-expanded="false"
                                          placeholder="Email"
                                          value={this.state.email}
                                          name="email"
                                          type="email"
                                          onChange={this.handleChange}
                                        />
                                        {this.state.submitted &&
                                          !this.state.email && (
                                            <div className="help-block">
                                              Email is required
                                            </div>
                                          )}
                                      </div>
                                      <div className="cenlr">
                                        <button
                                          className="page_save page_width"
                                          to="#"
                                        >
                                          SEND
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Sidebar from "../../../sidebar/sidebar";
import Header from "../../../header/header";
import { settingService } from "../../../../_services/company/settingServices";
import { sweetAlert } from "../../../../utils/UniversalFunction";

const CoachingForm = (props) => {
  const [module, setModule] = useState("ESG Reporting");
  const [message, setMessage] = useState("");

  const requestCoaching = async(e) => {
    e.preventDefault();
    let userData ={};
    userData.module = module;

    if(module !== "") { 
    userData.message = message;
    let data = await settingService.requestCoaching(userData)
    if(data.statusCode){
      setModule("")
      setMessage("");

    }

    window.location.reload();

  }else{
    sweetAlert("error", "All Fields are required!");
  }
  }
  
  const goToPreviousPath = () => {
    window.history.back();
  }
    return (
      <div>
        <Sidebar dataFromParent={props.location.pathname} />
        <Header />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="Introductionweq">
                        <div className="text_Parts">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="d-flex justify-content-between">
                                <h6 className="back_quninti back_quninti_2">
                                  <a className="back_text" href="#" onClick={goToPreviousPath}>
                                    <span className="step_icon">
                                      <i className="far fa-long-arrow-alt-left"></i>
                                    </span>
                                    Back
                                  </a>
                                </h6>
                                {/* <h6 className="back_quninti back_quninti_2">
                                  <a className="back_text" href="#">
                                    <span className="step_icon"></span>Next
                                    <i className="far fa-long-arrow-alt-right"></i>
                                  </a>
                                </h6> */}
                              </div>
                              <div className="d-flex justify-content-between mt-5">
                                <h5 className="motor">Coaching</h5>
                              </div>
                              <div className="col-md-12 col-xs-12">
                                <h6 className="mt-5">
                                  Fill out the following form for us to find the
                                  best consultant help:
                                </h6>
                                <div className="form_start">
                                  <form onSubmit={requestCoaching}>
                                    <div className="form-group my-3">
                                      <label for="exampleFormControlSelect1">
                                        I need help with
                                      </label>
                                      <select
                                        className="form-control select_t"
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setModule(e.target.value)}
                                        required={true}
                                      >
                                        <option value={"ESG Reporting"}>ESG Reporting</option>
                                        <option value={"Sector Question"}>Sector Question</option>
                                        <option value={"Governance"}>Governance</option>
                                        <option value={"Suppliers"}>Suppliers</option>
                                        <option value={"Supplier Management"}>Supplier Management</option>
                                        <option value={"SDG's"}>SDG's</option>
                                        <option value={"Carbon Footprint"}>Carbon Footprint</option>
                                        <option value={"Board Skills"}>Board Skills</option>
                                        <option value={"ESG Products"}>ESG Products</option>
                                      </select>
                                    </div>

                                    <div className="form-group">
                                      <label for="exampleFormControlTextarea1">
                                        Please provide a detailed explanation of
                                        the help you are looking for
                                      </label>
                                      <textarea
                                        className="form-control mt-3"
                                        onChange={(e) => setMessage(e.target.value)}
                                        value={message}
                                        id="exampleFormControlTextarea1"
                                        rows="3"
                                        required={true}
                                      ></textarea>
                                    </div>
                                    <div className="sve_next">
                                      <button
                                        className="page_save page_width"
                                        style={{width: 'auto'}}
                                        type="sumbit"
                                        
                                      >
                                        Submit Request
                                      </button>
                                      {/* <button
                                        className="page_save_green page_width"
                                        type="button"
                                      >
                                        <span>
                                          <i className="fa fa-check"></i>
                                        </span>
                                        DRAFT SAVED
                                      </button> */}
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }

  export default CoachingForm;
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Moment from "react-moment";
import { NavLink } from "react-router-dom";
import { Pagination, Icon } from "semantic-ui-react";

import { authenticationService } from "../../../../_services/authentication";
import { settingService } from "../../../../_services/company/settingServices";
import config from "../../../../config/config.json";
import Header from "../../../header/header";
import Sidebar from "../../../sidebar/sidebar";
import GlobalPlanFeatureComponent from "../setting/globalPlanFeature";

import { sweetAlert } from "../../../../utils/UniversalFunction";
import { isValidName, isValidEmail } from "../../../../utils/UniversalFunction";
import moment from "moment";
import "./billing.css";
import "react-datepicker/dist/react-datepicker.css";

const currentUser = authenticationService.currentUserValue;

export default class SettingBilling extends Component {
  constructor(props) {
    super(props);
    this.startDate = new Date();
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      items1: [],
      message: [],
      message1: [],
      expire: [],
      users: "",
      email: "",
      uuid: "",
      items3: [],
      totalRows: 0,
      currentPage: 1,
      skip: 0,
      limit: 10,
      pageCount: 0,
      perPage: 10,
      uuid1: "",
      firstName: "",
      lastName: "",
      phone: "",
      totalData: 0,
      setStartDate: null,
      setEndDate: null,
      show: false,
      firstNameValidation: false,
      firstNameMessage: "",
      lastNameValidation: false,
      lastNameMessage: "",
      emailvalidation: false,
      emailvalidationMessage: "",
      currentStatus: null,
      trialExpireAt: null
    };
    this.getProfileData = this.getProfileData.bind(this);
    this.serverRequest = this.serverRequest.bind(this);
    this.pageChange = this.pageChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.getGlobalSubscriptionPlan = this.getGlobalSubscriptionPlan.bind(this);
    this.calluuid = this.calluuid.bind(this);
    this.cancelSubscription = this.cancelSubscription.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.referBusiness = this.referBusiness.bind(this);
  }

  setStartDate = (date) => {
    this.startDate = { date: new Date() };
  };

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (name === "email") {
      let condition = isValidEmail(value);
      if (condition === true) {
        this.setState({
          emailValidation: true,
          emailValidationMessage: "",
        });
      } else {
        this.setState({
          emailValidation: false,
          emailValidationMessage: "Please check email format",
        });
      }
    }

    if (name === "firstName") {
      let condition = isValidName(value);
      if (condition === true) {
        this.setState({
          firstNameValidation: true,
          firstNameMessage: "",
        });
      } else {
        this.setState({
          firstNameValidation: false,
          firstNameMessage: "Please check firstname format",
        });
      }
    }

    if (name === "lastName") {
      let condition = isValidName(value);
      if (condition === true) {
        this.setState({
          lastNameValidation: true,
          lastNameMessage: "",
        });
      } else {
        this.setState({
          lastNameValidation: false,
          lastNameMessage: "Please check lastname format",
        });
      }
    }
    let isPhoneValid = true;
    if (name === "phone") {
      if (/^\d{0,20}$/.test(value)) {
        isPhoneValid = true;
      } else {
        isPhoneValid = false;
      }
    }

    if (isPhoneValid) {
      this.setState({
        [name]: value,
      });
    }
  }

  getProfileData() {
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    fetch(config.API_URL + "getProfileData", { headers })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded2: true,
            uuid: result.user[0]?.uuid,
            users: result.user[0]?.no_of_users,
            currentStatus: result.user[0]?.isDisable,
            trialExpireAt: result.user[0]?.trialExpiredAt
          });
          this.calluuid(this.state.uuid);
          this.serverRequest(this.state.uuid);
          this.getGlobalSubscriptionPlan(this.state.users);
        },
        (error) => {
          this.setState({
            isLoaded2: true,
            error,
          });
        }
      );
  }

  pageChange(e, data) {
    let page = data.activePage;
    let pageNo = page === 1 ? 0 : (page - 1) * this.state.limit;
    this.setState({
      skip: pageNo,
    });
    this.serverRequest();
  }

  onDateChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
    let setStartDate = null;
    let setEndDate = null;
    if (this.state.setStartDate === null) {
      if (name === "setStartDate") {
        setStartDate = value;
      }
    } else {
      if (value !== this.state.setStartDate && name === "setStartDate") {
        setStartDate = value;
      } else {
        setStartDate = this.state.setStartDate;
      }
    }
    if (this.state.setEndDate === null) {
      if (name === "setEndDate") {
        setEndDate = value;
      }
    } else {
      if (value !== this.state.setEndDate && name === "setEndDate") {
        setEndDate = value;
      } else {
        setEndDate = this.state.setEndDate;
      }
    }
    if (setStartDate !== null && setEndDate !== null) {
      setTimeout(() => {
        this.serverRequest();
      }, 500);
    }
  }

  serverRequest(uuids) {
    const { skip, limit, setStartDate, setEndDate } = this.state;
    let uuid = this.state.uuid;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };
    let parms = `company/${uuid}/modules/billingDetails/getAllUserSubscriptions?skip=${skip}&limit=${limit}&startDate=${setStartDate}&endDate=${setEndDate}`;
    fetch(config.ADMIN_API_URL + parms, requestOptions)
      .then((res) => res.json())
      .then(
        (data) => {
          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            totalData: data.data.totalCount,
            items3: data.data.items,
            uuid1: data.data.items.length > 0 ? data.data.items[0].uuid : "",
            expire: data.data.expire,
          });
        },
        (error) => {}
      );
  }

  cancelSubscription(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    let subscriptionId = event.target.getAttribute("data-subscriptionid");
    let userSubscriptionId = event.target.getAttribute(
      "data-userSubscriptionId"
    );
    console.log("subscriptionId", subscriptionId);

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        subscriptionId: subscriptionId,
        userSubscriptionId: userSubscriptionId,
      }),
    };

    fetch(config.API_URL + `cancelSubscription`, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          sweetAlert("success", result.message);
          setTimeout(() => {
            this.serverRequest();
          }, 1000);
        },
        (error) => {
          sweetAlert("error", "Something Went wrong");
          setTimeout(() => {
            this.serverRequest();
          }, 1000);
        }
      );
  }
  calluuid(uuid) {
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    fetch(config.API_URL + `getSpecificSubscriptionPlans/${uuid}`, { headers })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items1: result.plans,
            message: result.message,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  getGlobalSubscriptionPlan(users) {
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };

    fetch(config.API_URL + `getGlobalPlanForCompanyAdmin/${users}`, { headers })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.plans,
            message1: result.message,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  async referBusiness(e) {
    e.preventDefault();
    const {
      firstName,
      lastName,
      email,
      phone,
      firstNameValidation,
      lastNameValidation,
    } = this.state;
    if (
      firstName &&
      lastName &&
      email &&
      phone &&
      firstNameValidation &&
      lastNameValidation
    ) {
      let obj = {};
      obj.firstName = firstName;
      obj.lastName = lastName;
      obj.email = email;
      obj.phone = phone;
      let response = await settingService.referBusiness(obj);
      let checkStatusCode = response.statusCode === 200;
      if (checkStatusCode) {
        this.handleClose();
        this.setState({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
        });
      }
    }
  }
  componentDidMount() {
    this.getProfileData();
    setTimeout(() => {
      this.serverRequest();
    }, 500);
    this.getGlobalSubscriptionPlan();
  }

  render() {
    const { show } = this.state;
    const { items, items1, items3, message, message1, uuid1, expire } =
      this.state;

    return (
      <div>
        <Header />
        <Sidebar dataFromParent={this.props.location.pathname} />
        <div className="main_wrapper">
          <div
            className={
              localStorage.getItem("role") === "company_admin"
                ? "tabs-top my_profile_menus"
                : "tabs-top my_profile_menus setting_tab_d"
            }
          >
            <ul>
              <li>
                <NavLink to="/Profile_Settings">My Profile</NavLink>
              </li>
              <li>
                <NavLink to="/Google_2FA"> Two Factor Authentication</NavLink>
              </li>
              {currentUser.data.role === "company_admin" && (
                <li>
                  <NavLink to="#" className="activee">
                    Billing
                  </NavLink>
                </li>
              )}
              {currentUser.data.role === "company_admin" && (
                <li>
                  <NavLink to="/sub_accounts">Sub Accounts</NavLink>
                </li>
              )}
            </ul>
          </div>
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="Introduction framwork_2">
                        <section className="forms">
                          <div className="row">
                            <div className="col-md-12 col-xs-12">

                             
                              <div className="business_detail">
                                  {this?.state?.currentStatus === 2 && (
                                    <>
                                      <div className="heading">
                                      <h4 className="font-heading">
                                        Current Trial Plan
                                      </h4>
                                    </div>
                                    <div className="sub-heading mt-3">
                                      <p>
                                        <strong>
                                          You are currenlty using our free trial
                                          tier
                                        </strong>
                                      </p>
                                    </div>
                                    <div className="cards">
                                      <div className="row justify-content-center">
                                        <div className="col-lg-3 col-xs-3 my-3 subscription_box">
                                          <div className="card-1">
                                            <span className="tag">
                                              {"Trial Plan"}
                                            </span>
                                            <h4>
                                              FREE /<span>14 days</span>
                                            </h4>
                                            <h6>
                                              <b>Features Included:</b>
                                            </h6>
                                            <div className="data_card-2 pb-4">
                                              <ul className="planFeatures">
                                                <GlobalPlanFeatureComponent
                                                  items={["Feature 1", "Feature 2"]}
                                                />
                                              </ul>
                                            </div>
                                            <h6>
                                              <b>Time left: {moment(this?.state?.trialExpireAt).diff(moment(), 'days')} days</b>
                                            </h6>
                                          </div>
                                          
                                        </div>
                                      </div>
                                    </div>
                                    </>
                                  )}
                                <>
                                  <div className="heading">
                                    <h4 className="font-heading">
                                      Subscription Plan
                                    </h4>
                                  </div>
                                  <div className="sub-heading mt-3">
                                    <p>
                                      <strong>{message}</strong>
                                    </p>
                                  </div>
                                  <div className="cards">
                                    <div className="row justify-content-center">
                                      <div className="col-lg-12 col-xs-12 my-3 subscription_box">
                                        {items1.map((item, key) => (
                                          <div
                                            key={key}
                                            className="mx-4"
                                            // className="col-lg-3 col-xs-12 my-3"
                                          >
                                            <div className="card-1">
                                              <span className="tag">
                                                {item.title}
                                              </span>
                                              <h4>
                                                {item?.currency === "dkk"
                                                  ? "kr"
                                                  : "$"}
                                                {item.price
                                                  .toString()
                                                  .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )}
                                                /<span>{item.tier}</span>
                                              </h4>
                                              <h6>
                                                <b>Features Included:</b>
                                              </h6>
                                              <div className="data_card-2 pb-4">
                                                <ul className="planFeatures">
                                                  <GlobalPlanFeatureComponent
                                                    items={JSON.parse(
                                                      item.metaData.split(",")
                                                    )}
                                                  />
                                                </ul>
                                              </div>
                                            </div>
                                            {item.isSubscribe === true &&
                                              item.userPlanActive === true && (
                                                <div className="current-plan text-center">
                                                  <div className="btn-b">
                                                    <button
                                                      id="checkout-and-portal-button"
                                                      type="submit"
                                                      className="page_width page_save m-3"
                                                      data-subscriptionId={
                                                        JSON.parse(
                                                          item?.rawData
                                                        )?.subscription
                                                      }
                                                      data-userSubscriptionId={
                                                        item.userSubscriptionId
                                                      }
                                                      onClick={(e) =>
                                                        this.cancelSubscription(
                                                          e
                                                        )
                                                      }
                                                    >
                                                      Cancel Plan
                                                    </button>
                                                    <p>
                                                      Your Current Plan
                                                      <br />
                                                      Ends On: &nbsp;
                                                      <Moment
                                                        format="DD MMM YYYY"
                                                        withTitle
                                                      >
                                                        {item.updated_at}
                                                      </Moment>{" "}
                                                    </p>
                                                  </div>
                                                </div>
                                              )}
                                            {item.isSubscribe === false && (
                                              <div className="global_link w-100 btn-d subbutton">
                                                <form
                                                  action={`${config.BASE_URL}api/v1/create-checkout-session`}
                                                  method="POST"
                                                >
                                                  {/* Add a hidden field with the lookup_key of your Price */}
                                                  <input
                                                    type="hidden"
                                                    name="lookup_key"
                                                    value={item.price_key}
                                                  />
                                                  <input
                                                    type="hidden"
                                                    name="email"
                                                    value={localStorage.getItem(
                                                      "user_temp_email"
                                                    )}
                                                  />
                                                  <button
                                                    id="checkout-and-portal-button"
                                                    type="submit"
                                                    className="page_width page_save"
                                                  >
                                                    Select Plan
                                                  </button>
                                                </form>
                                              </div>
                                            )}
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="sub-heading mt-3">
                                    <p>
                                      <strong>{message1}</strong>
                                    </p>
                                  </div>
                                  <div className="cards">
                                    <div className="row justify-content-center">
                                      <div className="col-lg-12 col-xs-12 my-3 subscription_box">
                                        {items?.map((item, key) => (
                                          <div
                                            key={key}
                                            className="mx-4"
                                            // className="col-lg-3 col-xs-12 my-3"
                                          >
                                            <div className="card-1">
                                              <span className="tag">
                                                {item.title}
                                              </span>
                                              <h4>
                                                {item?.price_prefix || "$"}
                                                {item.price
                                                  .toString()
                                                  .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )}
                                                /<span>{item.tier}</span>
                                              </h4>
                                              <h6>
                                                <b>Features Included:</b>
                                              </h6>
                                              <div className="data_card-2 pb-4">
                                                <ul className="planFeatures">
                                                  <GlobalPlanFeatureComponent
                                                    items={JSON.parse(
                                                      item.metaData.split(",")
                                                    )}
                                                  />
                                                </ul>
                                              </div>
                                            </div>
                                            {item.isSubscribe === true &&
                                              item.userPlanActive === true && (
                                                <div className="current-plan text-center">
                                                  <div className="btn-b">
                                                    <button
                                                      id="checkout-and-portal-button"
                                                      type="submit"
                                                      className="page_width page_save m-3"
                                                      data-subscriptionId={
                                                        JSON.parse(
                                                          item?.rawData
                                                        )?.subscription
                                                      }
                                                      data-userSubscriptionId={
                                                        item.userSubscriptionId
                                                      }
                                                      onClick={(e) =>
                                                        this.cancelSubscription(
                                                          e
                                                        )
                                                      }
                                                    >
                                                      Cancel Plan
                                                    </button>
                                                    <p>Your Current Plan</p>

                                                    <p>
                                                      Ends On: &nbsp;
                                                      {expire.map(
                                                        (data, key) => (
                                                          <span key={key}>
                                                            {data}
                                                          </span>
                                                        )
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>
                                              )}
                                            {item.isSubscribe === true &&
                                              item.userPlanActive === false && (
                                                <div className="global_link w-100 btn-d subbutton">
                                                  <form
                                                    action={`${config.BASE_URL}api/v1/create-checkout-session`}
                                                    method="POST"
                                                  >
                                                    <input
                                                      type="hidden"
                                                      name="lookup_key"
                                                      value={item.price_key}
                                                    />
                                                    <input
                                                      type="hidden"
                                                      name="email"
                                                      value={localStorage.getItem(
                                                        "user_temp_email"
                                                      )}
                                                    />
                                                    <button
                                                      id="checkout-and-portal-button"
                                                      type="submit"
                                                      className="page_width page_save"
                                                    >
                                                      Select Plan
                                                    </button>
                                                  </form>
                                                </div>
                                              )}
                                            {item.isSubscribe === false && (
                                              <div className="global_link w-100 btn-d subbutton">
                                                <form
                                                  action={`${config.BASE_URL}api/v1/create-checkout-session`}
                                                  method="POST"
                                                >
                                                  <input
                                                    type="hidden"
                                                    name="lookup_key"
                                                    value={item.price_key}
                                                  />
                                                  <input
                                                    type="hidden"
                                                    name="email"
                                                    value={localStorage.getItem(
                                                      "user_temp_email"
                                                    )}
                                                  />
                                                  <button
                                                    id="checkout-and-portal-button"
                                                    type="submit"
                                                    className="page_width page_save"
                                                  >
                                                    Select Plan
                                                  </button>
                                                </form>
                                              </div>
                                            )}
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="hello" />
                                  <hr className="line mt-5"></hr>
                                </>
                                {/* } */}
                                <div className="refer">
                                  <div className="business_detail">
                                    <div className="heading">
                                      <h4>Refer a Business</h4>
                                    </div>
                                  </div>
                                  <div className="sub-heading mt-3">
                                    {/* <h5> Add the below text and functionality.</h5><br/> */}
                                    <p>
                                      Refer a business to ESGRI and get the
                                      following credit against your next
                                      invoice.
                                    </p>
                                  </div>
                                  <ul className="refer-list">
                                    <li>
                                      1 - 5 (per successful lead):{" "}
                                      <span className="price">$50</span>
                                    </li>
                                    <li>
                                      6 - 20 (per successful lead):{" "}
                                      <span className="price">$75</span>
                                    </li>
                                    <li>
                                      21+ (per successful lead):{" "}
                                      <span className="price">$100</span>
                                    </li>
                                  </ul>
                                  <button
                                    className="page_width page_save"
                                    type="submit"
                                    onClick={this.handleShow}
                                  >
                                    Refer Now
                                  </button>
                                </div>
                                <hr className="line mt-5"></hr>
                                <div className="col-sm-12">
                                  <div className="business_detail">
                                    <div className="saved_cards">
                                      <div className="business_detail">
                                        <div className="heading">
                                          <div className="heading_wth_text"></div>
                                          <form>
                                            <div className="row">
                                              <div className="col-md-6 col-xs-12 mb-3">
                                                <div className="form-group">
                                                  <label htmlFor="exampleInputEmail1">
                                                    Date From
                                                  </label>
                                                  <input
                                                    type="date"
                                                    onChange={
                                                      (this.handleChange,
                                                      this.onDateChange)
                                                    }
                                                    className="form-control date-picker"
                                                    name="setStartDate"
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-md-6 col-xs-12 mb-3">
                                                <div className="form-group">
                                                  <label htmlFor="exampleInputEmail1">
                                                    Date To
                                                  </label>
                                                  <input
                                                    type="date"
                                                    onChange={
                                                      (this.handleChange,
                                                      this.onDateChange)
                                                    }
                                                    className="form-control date-picker"
                                                    name="setEndDate"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                      <div className="table_f">
                                        <Table striped bordered hover size="sm">
                                          <thead>
                                            <tr className="heading_color">
                                              <th className="t_head">
                                                INVOICE ID
                                              </th>
                                              <th className="t_head">
                                                SUBSCRIPTION PLAN
                                              </th>
                                              <th className="t_head">MONTH</th>
                                              <th className="t_head">
                                                AMOUNT PAID
                                              </th>
                                              <th className="t_head">
                                                INVOICE
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {items3.map((item, key) => (
                                              <tr key={key}>
                                                <td>{item.id}</td>
                                                <td>{item.title}</td>
                                                <td>
                                                  {" "}
                                                  <Moment
                                                    format="MMM YYYY"
                                                    withTitle
                                                  >
                                                    {items[0]?.created_at}
                                                  </Moment>
                                                </td>
                                                <td>
                                                  $
                                                  {item.amount
                                                    .toString()
                                                    .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )}
                                                </td>
                                                <td>
                                                  <NavLink
                                                    to={`/details/${uuid1}/invoice`}
                                                    className="table-tag"
                                                  >
                                                    <span>
                                                      <i className="fa fa-eye"></i>
                                                    </span>
                                                    View
                                                  </NavLink>
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </Table>
                                        <Pagination
                                          className="mx-auto pagination"
                                          defaultActivePage={1}
                                          onPageChange={this.pageChange}
                                          ellipsisItem={{
                                            content: (
                                              <Icon name="ellipsis horizontal" />
                                            ),
                                            icon: true,
                                          }}
                                          firstItem={{
                                            content: (
                                              <Icon name="angle double left" />
                                            ),
                                            icon: true,
                                          }}
                                          lastItem={{
                                            content: (
                                              <Icon name="angle double right" />
                                            ),
                                            icon: true,
                                          }}
                                          prevItem={{
                                            content: <Icon name="angle left" />,
                                            icon: true,
                                          }}
                                          nextItem={{
                                            content: (
                                              <Icon name="angle right" />
                                            ),
                                            icon: true,
                                          }}
                                          totalPages={Math.ceil(
                                            this.state.totalData /
                                              this.state.limit
                                          )}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <Modal show={show} onHide={this.handleClose}>
            <Form onSubmit={this.referBusiness}>
              <Modal.Header closeButton>
                {/* <Modal.Title>Refer a Business</Modal.Title> */}
                <div className="heading">
                  <h4>Refer a Business</h4>
                </div>
              </Modal.Header>
              <Modal.Body>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Row>
                    <Col>
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        value={this.state.firstName}
                        onChange={(e) => this.handleChange(e)}
                        placeholder="First Name"
                        autoFocus
                        required
                      />
                      {this.state.firstNameValidation === false && (
                        <div className="help-block">
                          {this.state.firstNameMessage}
                        </div>
                      )}
                    </Col>
                    <Col>
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        name="lastName"
                        type="text"
                        value={this.state.lastName}
                        onChange={(e) => this.handleChange(e)}
                        placeholder="Last Name"
                        autoFocus
                        required
                      />
                      {this.state.lastNameValidation === false && (
                        <div className="help-block">
                          {this.state.lastNameMessage}
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col>
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        name="email"
                        type="email"
                        value={this.state.email}
                        onChange={(e) => this.handleChange(e)}
                        placeholder="Email Address"
                        autoFocus
                        required
                      />

                      {this.state.emailValidation === false && (
                        <div className="help-block">
                          {this.state.emailValidationMessage}
                        </div>
                      )}
                    </Col>
                    <Col>
                      <Form.Label>Contact Number</Form.Label>
                      <Form.Control
                        name="phone"
                        type="number"
                        value={this.state.phone}
                        onChange={(e) => this.handleChange(e)}
                        placeholder="Contact Number"
                        autoFocus
                        required
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="refer-button"
                  type="submit"
                  onClick={this.referBusiness}
                >
                  Refer Now
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </div>
      </div>
    );
  }
}

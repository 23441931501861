import {sweetAlert} from '../../../../utils/UniversalFunction';
import React, { Component } from "react";
import Sidebar from "../../../sidebar/sidebar";
import Header from "../../../header/header";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { saveAs } from "file-saver";
import config from "../../../../config/config.json";
import { authenticationService } from "../../../../_services/authentication";
import Spinner from "react-bootstrap/Spinner";
// import Loader from "../../../loader/Loader";
const currentUser = authenticationService.currentUserValue;

export default class sector_question_detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      business_model: [],
      cyber_digital: [],
      environmental_capital: [],
      human_capital: [],
      leadership_goverance: [],
      social_capital: [],
      description: "",
      loading: false,

    };
    this.exportCSV = this.exportCSV.bind(this);
    
  }

  
  async handelSectorReportDownload(e) {
    try {
      await this.setState({ loading: true });
      e.preventDefault();

      const headers = {
        Authorization: `Bearer ${currentUser.data.token}`,
        Accept: "application/json",
      };

      axios
        .get(config.API_URL + "repotings/downloadCompanyReport/sector", {
          headers,
        })
        .then((response) => {
          if (response?.data?.file) {
            sweetAlert("success", "Your file will donwload shortly");
            let url = config.BASE_URL + "docs/" + response?.data?.file;
            setTimeout(() => {
              this.setState({ loading: false });
              saveAs(url);
            }, 100);
          } else {
            sweetAlert("error", "Unable to find file!");
            this.setState({ loading: false });
          }
        })
        .catch(async (error) => {
           await this.setState({ loading: false });
           // eslint-disable-next-line
          if(error?.response?.data?.message == "NO_REPORT_FOUND") {
            sweetAlert('info',"The Report will be uploaded within next 48 hours");	
            

          }else{
            sweetAlert(
              "error",
              "Oops some error created while dowloading file"
            );
            
          }
        });
    } catch (err) {

      // sweetAlert("error", err.message);
      // this.setState({ loading: false });
    }
  }

  showAlert = () => {
    sweetAlert('info',"The Report will be uploaded within next 48 hours");	
  };

  exportCSV(event) {
    event.preventDefault();
    this.setState({ submitted: true });
      const headers = {
        Authorization: `Bearer ${currentUser.data.token}`,
        Accept: "application/json",
      };
      axios
        .post(
          config.API_URL + "downloadReport",
          {
            section_name: 'sector_question',
            sub_section: 'sector_question',
          },
          { headers }
        )
        .then((response) => {
          let url = config.BASE_URL + response?.data?.data?.file;
          if(response?.data?.data?.file){
            saveAs(
              url,
              url 
            );
          }else{
            this.showAlert()
          }
        })
       
        .catch(function (error) {
          if (error.response) {
            sweetAlert('error',error.response.data.message);
          }
        });
   
  }

  componentDidMount() {
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    this.setState({
      isLoaded: true,
    });
    fetch(config.API_URL + "getAllQuestionSectorQuestions", { headers })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            business_model: result?.business_model,
            cyber_digital: result.cyber_digital,
            environmental_capital: result?.environmental_capital,
            human_capital: result?.human_capital,
            leadership_goverance: result?.leadership_goverance,
            social_capital: result?.social_capital,
            isLoaded: false,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );

      fetch(config.API_URL + "getIndustryDescription", { headers })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            description:
            result?.description?.description === null
                ? "Description not found"
                : result?.description?.description,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }
  render() {
    const {
      business_model,
      cyber_digital,
      environmental_capital,
      human_capital,
      leadership_goverance,
      social_capital,
      loading
    } = this.state;

    return (
      <div>
        <Sidebar dataFromParent={this.props.location.pathname} />
        <Header />
        {/* {this.state.isLoaded === true && <Loader />} */}
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="Introduction framwork_2">
                        <div className="text_Parts">
                          <div className="text_ntroion">
                            <h5 className="Intro">Introduction</h5>
                            <p className="critical">
                            {this.state.description}
                            </p>
                            { loading ? (
                                <div className="request_file_btns_group">
                                  <button
                                    className="next_page_one request_file_btn"
                                    title="The Report will be uploaded within next 48 hours"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    type="button"
                                  >
                                    {"Preparing Files"}
                                    <Spinner animation="border" />
                                  </button> 
                                 <NavLink
                                    style={{display: 'flex', alignItems: 'center'}}
                                    className="re-submit mx-3"
                                    to={"/sector_questions"}
                                    type="button"
                                  >
                                    Re-Submit Responses
                                  </NavLink>
                                </div>
                                ): (
                                  <div>
                                    <button
                                    // onClick={(e) =>
                                    //   this.handelSectorReportDownload(e)
                                    // }
                                    onClick={(e) =>
                                      this.exportCSV(e)
                                    }
                                    title="The Report will be uploaded within next 48 hours"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    className="next_page_one mb-3"
                                    type="button"
                                  >
                                    <span className="Download_r">
                                      <i className="fa fa-download"></i>
                                    </span>
                                    Download Report
                                  </button>
                                  <NavLink
                                    className="re-submit mx-3"
                                    to={"/sector_questions"}
                                    type="button"
                                  >
                                    Re-Submit Responses
                                  </NavLink>

                                </div>
                            )
                                
                            }
                            
                                  
                          </div>
                        </div>
                      </div>
                      <div className="frameworks framwork_2">
                        <div className="text_Parts">
                          <div className="col-md-12">
                            <div className="Capital_op">
                              <h4 className="E_capital">
                                Environmental Capital
                              </h4>

                              {environmental_capital.map((item, key) => (
                                <>
                                  <div className="manag">
                                    <div className="heading_h3 mt-3">
                                      {item.heading && (
                                        <span className="gement">
                                          {item.heading}
                                        </span>
                                      )}
                                    </div>
                                    <h3 className="energy">{item.title}</h3>
                                    {/* <p className="sumption">{item.answer}</p> */}
                                    <textarea type="text" className="form-control" placeholder={item.answer} disabled></textarea>
                                    {/* <hr className="related" /> */}
                                  </div>
                                </>
                              ))}
                              {environmental_capital.length === 0 && (
                                <h6>No Questions here</h6>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frameworks framwork_2">
                        <div className="text_Parts">
                          <div className="col-md-12">
                            <div className="Capital_op">
                              <h4 className="E_capital">
                                Business Model & Innovation
                              </h4>

                              {business_model.map((item, key) => (
                                <>
                                  <div className="manag">
                                    <div className="heading_h3 mt-3">
                                      {item.heading && (
                                        <span className="gement">
                                          {item.heading}
                                        </span>
                                      )}
                                    </div>
                                    <h3 className="energy">{item.title}</h3>
                                    {/* <p className="sumption">{item.answer}</p> */}
                                    <textarea type="text" className="form-control form_height" placeholder={item.answer} disabled></textarea>
                                    {/* <hr className="related" /> */}
                                  </div>
                                </>
                              ))}
                              {business_model.length === 0 && (
                                <h6>No Questions here</h6>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frameworks framwork_2">
                        <div className="text_Parts">
                          <div className="col-md-12">
                            <div className="Capital_op">
                              <h4 className="E_capital">Cyber & Digital</h4>

                              {cyber_digital.map((item, key) => (
                                <>
                                  <div className="manag">
                                    <div className="heading_h3 mt-3">
                                      {item.heading && (
                                        <span className="gement">
                                          {item.heading}
                                        </span>
                                      )}
                                    </div>
                                    <h3 className="energy">{item.title}</h3>
                                    {/* <p className="sumption">{item.answer}</p> */}
                                    <textarea type="text" className="form-control form_height" placeholder={item.answer} disabled></textarea>
                                    {/* <hr className="related" /> */}
                                  </div>
                                </>
                              ))}
                              {cyber_digital.length === 0 && (
                                <h6>No Questions here</h6>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frameworks framwork_2">
                        <div className="text_Parts">
                          <div className="col-md-12">
                            <div className="Capital_op">
                              <h4 className="E_capital">Human Capital</h4>

                              {human_capital.map((item, key) => (
                                <>
                                  <div className="manag">
                                    <div className="heading_h3 mt-3">
                                      {item.heading && (
                                        <span className="gement">
                                          {item.heading}
                                        </span>
                                      )}
                                    </div>
                                    <h3 className="energy">{item.title}</h3>
                                    {/* <p className="sumption">{item.answer}</p> */}
                                    <textarea type="text" className="form-control form_height" placeholder={item.answer} disabled></textarea>
                                    {/* <hr className="related" /> */}
                                  </div>
                                </>
                              ))}
                              {human_capital.length === 0 && (
                                <h6>No Questions here</h6>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frameworks framwork_2">
                        <div className="text_Parts">
                          <div className="col-md-12">
                            <div className="Capital_op">
                              <h4 className="E_capital">
                                Leadership Governance
                              </h4>

                              {leadership_goverance.map((item, key) => (
                                <>
                                  <div className="manag">
                                    <div className="heading_h3 mt-3">
                                      {item.heading && (
                                        <span className="gement">
                                          {item.heading}
                                        </span>
                                      )}
                                    </div>
                                    <h3 className="energy">{item.title}</h3>
                                    {/* <p className="sumption">{item.answer}</p> */}
                                    <textarea type="text" className="form-control form_height" placeholder={item.answer} disabled></textarea>
                                    {/* <hr className="related" /> */}
                                  </div>
                                </>
                              ))}
                              {leadership_goverance.length === 0 && (
                                <h6>No Questions here</h6>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frameworks framwork_2">
                        <div className="text_Parts">
                          <div className="col-md-12">
                            <div className="Capital_op">
                              <h4 className="E_capital">Social Capital</h4>

                              {social_capital.map((item, key) => (
                                <>
                                  <div className="manag">
                                    <div className="heading_h3 mt-3">
                                      {item.heading && (
                                        <span className="gement">
                                          {item.heading}
                                        </span>
                                      )}
                                    </div>
                                    <h3 className="energy">{item.title}</h3>
                                    {/* <p className="sumption">{item.answer}</p> */}
                                    {/* <hr className="related" /> */}
                                    <textarea type="text" className="form-control form_height" placeholder={item.answer} disabled></textarea>
                                  </div>
                                </>
                              ))}
                              {social_capital.length === 0 && (
                                <h6>No Questions here</h6>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

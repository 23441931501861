import { authenticationService } from "../../../../../_services/authentication";
import config from "../../../../../config/config.json";
import axios from "axios";
const currentUser = authenticationService.currentUserValue;

const PREFIX = "company/carbon/";
const headers = {
    Authorization: `Bearer ${currentUser?.data?.token}`,
    Accept: "application/json",
};

export const requestScopeEmissions = async (tab) => {
  try {
    const apiResponse = await axios.get(
      `${config.API_URL + PREFIX}getScopeEmissions?scope=${tab}`,
      {
        headers: headers,
      }
    );
    return apiResponse.data;
  } catch (err) {
    throw new Error(err.response.data.message || "Oops some error occured!");
  }
};

export const saveScopeEmissions = async (payload, activeTab) => {
  try {
    const apiResponse = await axios.post(
      `${config.API_URL + PREFIX}createScopeEmission?saveType=${activeTab}`,
      payload,
      {
        headers: headers,
      }
    );
    return apiResponse.data;
  } catch (err) {
    throw new Error(err.response.data.message || "Oops some error occured!");
  }
};

export const requestScopeSummary = async (tab) => {
  try {
    const apiResponse = await axios.get(
      `${config.API_URL + PREFIX}getScopeSummary`,
      {
        headers: headers,
      }
    );
    return apiResponse.data;
  } catch (err) {
    throw new Error(err.response.data.message || "Oops some error occured!");
  }
};

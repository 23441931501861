import React, { Component } from "react";

export default class GovernanceConsideration extends Component {
  render() {
    return (
      <div>
        {/* <!-- end panel 1 --> */}
        <div id="panel3" className="panel">
          <div className="table_well">
            <p className="actual">
              <strong>Management Strategy: </strong>
              It contemplates the effective implementation of management strategy. Financial performance over a period offers a valuable measure of the capability of the management to perform its operational and financial procedures. Examples include poor execution of internal controls and underinvestment, leading to problems while implementing an issuer’s plan.
            </p>
            <p className="actual">
              <strong>Governance Structure: </strong>
              This category considers whether the genuine power spread within an issuer affects the protection of creditors or the rights of other stakeholders. For example, it reflects whether an issuer is extracting value from the shareholders or creditors for its advantage or whether a majority shareholder is extracting value from minority shareholders or creditors. Elements evaluated include- the existence of lawful controls for guaranteeing sound policies, oversight of related-party transactions, an active and independent board of directors, management compensation, ownership concentration and key-person risk. 
            </p>
            <p className="actual">
              <strong>Group Structure: </strong>
              This category studies governance risks emerging from the intricacy and transparency of the organisational structure of an issuer and whether it is apt for its business model. The complex organisation structure can cause limited visibility into main legal entities.
            </p>
            <p className="actual">
              <strong>Financial Transparency: </strong>
              It contemplates whether the quality, regularity and timeliness of an issuer’s financial and non-financial reporting make it easy for market players and investors to be in a position to evaluate its financial state and fundamental risks. High-quality and timely financial reporting is indicative of vigorous governance. Reproducing purposely incorrect or deceptive accounting reports suggests deep flaws in an issuer’s governance framework.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

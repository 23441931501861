import React, { Component } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

import { authenticationService } from "../../../../_services/authentication";
import config from "../../../../config/config.json";

import "./completion.css";

const currentUser = authenticationService.currentUserValue;

export default class completion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "Thank you",
      message: "Payment done Successfully",
    };
  }
  async componentDidMount() {
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    try {
      const getPaymentId = await axios.get(config.API_URL + "getLastPayment", {
        headers,
      });

      if (getPaymentId.data.message !== this.state.message) {
        this.setState({ message: getPaymentId.data.message });
      }
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    return (
      <div className="completion-section">
        <div className="inner_completion-section">
          <div>
            <img
              src="https://funtura.in/tvm/wp-content/themes/funtura/assets/images/success.svg"
              alt="success"
            />
          </div>
          <div className="completion-text">
            <h2>{this.state.text}</h2>
            <h4>{this.state.message}</h4>
          </div>

          <NavLink
            to="/supplier_management_detail"
            className="page_width page_save button_color w-100"
            style={{ marginLeft: "30px" }}
          >
            Go Back
          </NavLink>

          <p className="note">
            Please note the report may take 5 minutes to generate
          </p>
        </div>
      </div>
    );
  }
}

/* eslint-disable jsx-a11y/iframe-has-title */
import Swal from "sweetalert2";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Sidebar from "../../../sidebar/sidebar";
import Header from "../../../header/header";
import AssignSubAdminComponent from "../SmallComponents/assignSubAdmin";
import { authenticationService } from "../../../../_services/authentication";
import config from "../../../../config/config.json";
import axios from "axios";
import { Button,  Modal } from "semantic-ui-react";

const currentUser = authenticationService.currentUserValue;
export default class supplier_management extends Component {

  constructor(props) {
    super(props);
    this.state = {
      item: [],
      condition:false,
      title:[],
      video_link:[],
      selectedVal: "Simply"
    };
  }

  handleOpenModal(val) {
    this.setState({ activeModal: val });
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
    this.setState({ showModal: "" });
  }
  showHide(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === "password" ? "input" : "password"
    });
  }

  onClose() {
    this.setState({
      setOpen: false
    });
  }
  setOpen(event) {
    this.setState({
      setOpen: true
    });
  }

  componentDidMount() {
    
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };

    axios
      .get(config.API_URL + "criteria_assess", { headers })
      .then((response) => {
        this.setState({ 
          item: response.data.data,
          condition:(response?.data?.secondValue > 0) ? true : false
         });
      })
      .catch(function (response) {
        Swal.fire({
          icon: "error",
          title: response.data.message,
          showConfirmButton: false,
          timer: 1000,
        });
      });

      let uri = window.location.pathname.split("/");
      let category = uri[1];
      fetch(config.API_URL + `getIntroductionVideosbyLink/${category}`)
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded2: true,
              title: (result.introductionVideo.length > 0) ? result.introductionVideo[0].title : '',
              video_link: (result.introductionVideo.length > 0) ? result.introductionVideo[0].video_link : '',
            });
          },
          
          (error2) => {
            this.setState({
              isLoaded2: true,
              error2,
            });
          }
        );
        this.readCokkie();
  }

  readCokkie() {
    
      function readCookie(name) {
        var c = document.cookie.split('; '),
        cookies = {}, i, C;
    
        for (i = c.length - 1; i >= 0; i--) {
            C = c[i].split('=');
            cookies[C[0]] = C[1];
        }
    
        return cookies[name];
      }

    let cookieVal = readCookie('googtrans')
    if(cookieVal === '/en/da'){
      this.setState({selectedVal: 'Ganske enkelt'});
    }
  }

  render() {
    const { condition, title, video_link } = this.state;
    return (
      <div>
        <Sidebar dataFromParent={this.props.location.pathname} />
        <Header />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="governance">
                        <div className="text_Parts">
                          <div className="text_governance">
                            <div className="d-flex justify-content-between mb-3">
                              <h5 className="governance_head font-heading">
                                Introduction <span className='icon_hitn' onClick={(e) => this.setOpen(e)}> <i className="fas fa-video"></i></span>
                              </h5>
                              {currentUser.data.role === "company_admin" && (
                                <div className="form_x mb-3">
                                  <AssignSubAdminComponent tableName="supplier_management" />
                                </div>
                              )}
                            </div>

                          <div className="row">
                              <div className="col-xxl-12 col-lg-12 col-md-12 col-12">
                                <p className="suppliers_m">
                                  The Supplier ESG Assessment helps you start your journey towards understanding social, environmental and business ethics practices throughout your supply chain.
                                </p>
                                <p className="suppliers_m">
                                Based on responsible supply chain engagement strategies at leading global businesses, the Supplier ESG Assessment is a proven indicator set that helps you quickly measure supplier performance and identify vast opportunities for supplier improvement.
                                </p>
                                <p className="suppliers_m">
                                  We help you quickly create visibility, access supplier intelligence and accelerate progress toward your goals.
                                </p>
                                <div className="Assessment">
                                  <div className="Assessment_ones">
                                    <h4 className="ssess_h">
                                      With the Supplier ESG Assessment, you will be able to:
                                    </h4>
                                    <p className="policy">
                                      <span className="policy_icon">
                                        <i className="fal fa-chevron-right"></i>
                                      </span>
                                        Discover new insights into your suppliers’ social, environmental and ethical business practices. 
                                    </p>
                                    <p className="policy">
                                      <span className="policy_icon">
                                        <i className="fal fa-chevron-right"></i>
                                      </span>
                                        Identify your strengths and understand where to focus your efforts to encourage change.
                                    </p>
                                    <p className="policy">
                                      <span className="policy_icon">
                                        <i className="fal fa-chevron-right"></i>
                                      </span>
                                        Share valuable supplier information with internal and external stakeholders.
                                    </p>
                                    <p className="policy">
                                      <span className="policy_icon">
                                        <i className="fal fa-chevron-right"></i>
                                      </span>
                                        Expand your programs to layer additional information needs alongside the Supplier ESG Assessment without difficulty.
                                    </p>
                                  </div>
                                </div>
                                <div className="Assessment_two">
                                  <div className="Assessment_ones">
                                    <h4 className="ssess_h">
                                        Our Supplier Code of Conduct assessment will ensure suppliers are committed to:
                                    </h4>
                                    <p className="policy">
                                      <span className="policy_icon">
                                        <i className="fal fa-chevron-right"></i>
                                      </span>
                                        Integrity and ethics.
                                    </p>
                                    <p className="policy">
                                      <span className="policy_icon">
                                        <i className="fal fa-chevron-right"></i>
                                      </span>
                                        Meet social and labour conditions and legal requirements.
                                    </p>
                                    <p className="policy">
                                      <span className="policy_icon">
                                        <i className="fal fa-chevron-right"></i>
                                      </span>
                                        Operate organisations with health and safety commitments.
                                    </p>
                                    <p className="policy">
                                      <span className="policy_icon">
                                        <i className="fal fa-chevron-right"></i>
                                      </span>
                                        Understand the environment and its impact.
                                    </p>
                                    <p className="policy">
                                      <span className="policy_icon">
                                        <i className="fal fa-chevron-right"></i>
                                      </span>
                                        Be held accountable for their actions.
                                    </p>
                                  </div>
                                </div>
                                <div className="Assessment_two">
                                  <div className="Assessment_ones">
                                    <h4 className="ssess_h">  {this.state.selectedVal} </h4>
                                    <p className="policy">
                                      1. Add the suppliers' details.
                                    </p>
                                    <p className="policy">
                                      2. Select the criteria you want to assess.
                                    </p>
                                    <p className="policy">
                                      3. We will reach out on your behalf.
                                    </p>
                                    <p className="policy">
                                    4. Have comfort that your suppliers are responsible, ethical & transparent.
                                    </p>
                                  </div>
                                </div>
                                <div className="global_link mx-0 mt-4">
                                  {condition && (
                                    <NavLink
                                      className="link_bal_next"
                                      to="/supplier_management_detail"
                                    >
                                      Next
                                    </NavLink>
                                  )}

                                  {condition === false && (
                                    <NavLink
                                      className="page_save page_width"
                                      to="/supplier_management_option"
                                    >
                                      Next
                                    </NavLink>
                                  )}
                                </div>
                              </div>
                              <Modal open={this.state.setOpen} className="feedback2 feedback3 iframe_modal">
                                <Modal.Header>{title}</Modal.Header>
                                  <div className="video_esg">
                                    <iframe src={video_link} frameborder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen="true"></iframe>
                                  </div>
                                  <Modal.Actions>
                                    <Button className="mx-3" onClick={() => this.onClose(false)}>
                                      Close
                                    </Button>
                                  </Modal.Actions>
                              </Modal>
                            </div>  
                          </div>  
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

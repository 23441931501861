import React, { Component } from "react";

export default class social_consideration extends Component {
  render() {
    return (
      <div>
        {/* <!-- end panel 1 --> */}
        <div id="panel2" className="panel">
          <div className="table_well">
            <p className="actual">
              <strong>Human Rights, Community Relations, Access & Affordability Human Rights & Community Relations: </strong>
              This category reports to the management the relationship between issuers and the communities in which they operate, including around core human rights and the treatment of indigenous peoples.
            </p>
            <p className="actual">
              <strong>Access & Affordability: </strong>
              Includes the capability of a company to guarantee wide-ranging access to its products and services, explicitly in the framework of underserved markets or population groups.
            </p>
            <p className="actual">
              <strong>Customer Welfare - Fair Messaging, Privacy & Data Security: </strong>
              
              Includes all issues associated with how a company deals with its customers. Deliberations include- the privacy of data, product quality and safety, privacy and well-being of the customers and selling practices/product labelling.
            </p>
            <p className="actual">
              <strong>Labour Relations & Practices: </strong>
              It includes a company's ability to sustain generally accepted workplace labour standards, including compliance with labour laws and internationally recognised standards. It also includes- child labour-related issues, forced or bonded labour, exploitative labour, fair wages and overtime pay.
            </p>
            <p className="actual">
              <strong>Employee Well-being: </strong>
              It addresses an issuer's capacity to create and sustain a safe and healthy work environment free of injuries, fatalities and illness. Also included is an issuer’s ability to ensure that its ethos, employment and promotion practices intend the creation of a diverse and inclusive workforce reflecting the make-up of local talent pools and its client base.
            </p>
            <p className="actual">
              <strong>Exposure to Social Impacts: </strong>
              It reports the effect of social struggle or pressure, such as strikes and boycotts on an issuer’s capacity to perform projects or operations.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

import React, { useState, useEffect } from "react";
import Sidebar from "../../../sidebar/sidebar";
import Header from "../../../header/header";
import "./carbon.css";
import classes from "./scopeEmissions.module.css";
import ScopeTableComponent from "./ScopeTableComponent";
import { requestScopeEmissions } from "./services/CarbonService";
import { sweetAlert } from "../../../../utils/UniversalFunction";

const ScopeEmissions = () => {
  const [ activeTab, setActiveTab ] = useState(1);
  const [ entities, setEntities ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ totalEmission, setTotalEmission ] = useState({
    scope1: 0,
    scope2: 0,
    scope3: 0
  });


  const getValues = async() => {
    setLoading(true);
    requestScopeEmissions(activeTab).then((res) => {
      setEntities(res.data);
      setTotalEmission(res.totalEmmsion);
      setLoading(false);
    }).catch((error) => {
      sweetAlert("error", error.message || 'Oops some error occured')
      setLoading(false);
    })
  }

  useEffect(() => {
    // it will be called when ever tab count is changed
    getValues();

    // eslint-disable-next-line
  }, [activeTab]);
  return (
    <div>
      <Header />
      <Sidebar dataFromParent={"/Scope_1_Emissions"} />
      <div className={`main_wrapper ${classes.container}`}>
        <div className={classes.tabContainer}>
          <div className={classes.tabBox}>
            <div
              className={
                activeTab === 1 ? classes.activeTab : classes.visitedTab
              }
            >
              Scope 1: Emissions
            </div>
            <div
              className={
                activeTab === 1
                  ? classes.unVisitedTab
                  : activeTab === 2
                  ? classes.activeTab
                  : classes.visitedTab
              }
            >
              Scope 2: Emissions
            </div>
            <div
              className={
                activeTab === 3 ? classes.activeTab : classes.unVisitedTab
              }
            >
              Scope 3: Emissions
            </div>
          </div>
        </div>

        {activeTab === 1 && (
          <ScopeTableComponent
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            entities={entities}
            setEntities={setEntities}
            loading={loading}
            totalEmission={totalEmission}
          />
        )}
        {activeTab === 2 && (
          <ScopeTableComponent
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            entities={entities}
            setEntities={setEntities}
            loading={loading}
            totalEmission={totalEmission}
          />
        )}
        {activeTab === 3 && (
          <ScopeTableComponent
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            entities={entities}
            setEntities={setEntities}
            loading={loading}
            totalEmission={totalEmission}
          />
        )}
      </div>
    </div>
  );
};

export default ScopeEmissions;

import React, { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import "./carbon_footprint.css";
import classes from "./result.module.css";
import moment from "moment";
import { requestScopeSummary } from "../../Component/carbon_footprint/services/CarbonService";
import { sweetAlert } from "../../../../utils/UniversalFunction";

const Summary = ({openAll}) => {
  // create seprate for each scope summary

  const [scope1, setScope1] = useState([]);
  const [scope2, setScope2] = useState([]);
  const [scope3, setScope3] = useState([]);
  const [totals, setTotals] = useState({
    scope1: {
        total_cy: 0,
        total_kgco2e: 0,
        total_tco2e: 0,
        total_emission: 0
      },
      scope2: {
        total_cy: 0,
        total_kgco2e: 0,
        total_tco2e: 0,
        total_emission: 0
      },
      scope3: {
        total_cy: 0,
        total_kgco2e: 0,
        total_tco2e: 0,
        total_emission: 0
      }
  });

  const hitServerRequest = async () => {
    requestScopeSummary()
      .then((res) => {
        setScope1(res.data.scope1);
        setScope2(res.data.scope2);
        setScope3(res.data.scope3);
        setTotals(res.totals);
      })
      .catch((error) => {
        sweetAlert("error", error || "Oops some error occured");
      });
  };

  useEffect(() => {
    hitServerRequest();
  }, []);

  return (
    <Accordion defaultActiveKey={openAll} alwaysOpen>
      <Accordion.Item eventKey="0" className={classes.accordianItem}>
        <Accordion.Header className={classes.accHeader}>
          Scope 1 Emissions
        </Accordion.Header>
        <Accordion.Body className={classes.accordionSpc}>
          <table className={classes.scopeTable}>
            <thead>
              <tr>
                <th className={classes.tableHeadCell} style={{ width: "10%" }}>
                  Group
                </th>
                <th className={classes.tableHeadCell} style={{ width: "13%" }}>
                  Emissions Group
                </th>
                <th className={classes.tableHeadCell} style={{ width: "20%" }}>
                  Emission Source
                </th>
                <th className={classes.tableHeadCell} style={{ width: "8%" }}>
                  Unit
                </th>
                <th className={classes.tableHeadCell} style={{ width: "10%" }}>
                  Activity data CY {moment().year()}
                </th>
                <th className={classes.tableHeadCell} style={{ width: "10%" }}>
                  Emission factor
                </th>
                <th className={classes.tableHeadCell} style={{ width: "10%" }}>
                  {moment().year()} Emissions (kg CO2e)
                </th>
                <th className={classes.tableHeadCell} style={{ width: "10%" }}>
                  {moment().year()} Emissions (tCO2e)
                </th>
                <th className={classes.tableHeadCell} style={{ width: "10%" }}>
                  {moment().year()} Emissions % of total
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="12" className="hr_spacing">
                  {" "}
                  <hr className={classes.tableHr} />{" "}
                </td>
              </tr>
                {scope1.map((child, parentIndex) => (
                    child.map((item, index) => (
                    <tr key={index}>
                        <td className={classes.tableBodyCell}> {index === 0 ? item?.group : null}</td>
                        <td className={classes.tableBodyCell}>
                        {item?.emission_group}
                        </td>
                        <td className={classes.tableBodyCell}>
                        {item?.source}
                        </td>
                        <td className={classes.tableBodyCell}>{item?.unit}</td>
                        <td className={classes.tableBodyCell}>{item?.activity_cy ? item?.activity_cy.toFixed(2) : 0}</td>
                        <td className={classes.tableBodyCell}>{item?.emission_factor ? item?.emission_factor.toFixed(2) : 0.00}</td>
                        <td className={classes.tableBodyCell}>{item?.emission_kgco2e ? item?.emission_kgco2e.toFixed(2) : 0.00}</td>
                        <td className={classes.tableBodyCell}>{item?.emission_tco2e ? item?.emission_tco2e.toFixed(2) : 0.00}</td>
                        <td className={classes.tableBodyCell}>{item?.total_emission ? item?.total_emission.toFixed(2) : 0.00}</td>
                    </tr>
                    ))
                ))
              }

            <tr>
                <td colSpan="12" className="hr_spacing">
                  <hr className={classes.tableHr} />{" "}
                </td>
            </tr>
              <tr>
                <td className={classes.tableBodyCell}>
                  <strong>Scope 1 Total</strong>
                </td>
                <td className={classes.tableBodyCell}></td>
                <td className={classes.tableBodyCell}></td>
                <td className={classes.tableBodyCell}></td>
                <td className={classes.tableBodyCell}>
                  <strong>{totals.scope1.total_cy.toFixed(2)}</strong>
                </td>
                <td className={classes.tableBodyCell}></td>
                <td className={classes.tableBodyCell}>
                  <strong>{totals.scope1.total_kgco2e.toFixed(2)}kgCO2e</strong>
                </td>
                <td className={classes.tableBodyCell}>
                  <strong>{totals.scope1.total_tco2e.toFixed(2)}tCO2e</strong>
                </td>
                <td className={classes.tableBodyCell}>
                  <strong>{totals.scope1.total_emission.toFixed(2)}%</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1" className={classes.accordianItem}>
        <Accordion.Header className={classes.accHeader}>
          Scope 2 Emissions
        </Accordion.Header>
        <Accordion.Body className={classes.accordionSpc}>
          <table className={classes.scopeTable}>
            <thead>
              <tr>
                <th className={classes.tableHeadCell} style={{ width: "10%" }}>
                  Group
                </th>
                <th className={classes.tableHeadCell} style={{ width: "13%" }}>
                  Emissions Group
                </th>
                <th className={classes.tableHeadCell} style={{ width: "20%" }}>
                  Emission Source
                </th>
                <th className={classes.tableHeadCell} style={{ width: "8%" }}>
                  Unit
                </th>
                <th className={classes.tableHeadCell} style={{ width: "10%" }}>
                  Activity data CY {moment().year()}
                </th>
                <th className={classes.tableHeadCell} style={{ width: "10%" }}>
                  Emission factor
                </th>
                <th className={classes.tableHeadCell} style={{ width: "10%" }}>
                  {moment().year()} Emissions (kg CO2e)
                </th>
                <th className={classes.tableHeadCell} style={{ width: "10%" }}>
                  {moment().year()} Emissions (tCO2e)
                </th>
                <th className={classes.tableHeadCell} style={{ width: "10%" }}>
                  {moment().year()} Emissions % of total
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="12" className="hr_spacing">
                  {" "}
                  <hr className={classes.tableHr} />{" "}
                </td>
              </tr>
                {scope2.map((child, parentIndex) => (
                    child.map((item, index) => (
                    <tr key={index}>
                        <td className={classes.tableBodyCell}> {index === 0 ? item?.group : null}</td>
                        <td className={classes.tableBodyCell}>
                        {item?.emission_group}
                        </td>
                        <td className={classes.tableBodyCell}>
                        {item?.source}
                        </td>
                        <td className={classes.tableBodyCell}>{item?.unit}</td>
                        <td className={classes.tableBodyCell}>{item?.activity_cy ? item?.activity_cy.toFixed(2) : 0}</td>
                        <td className={classes.tableBodyCell}>{item?.emission_factor ? item?.emission_factor.toFixed(2) : 0.00}</td>
                        <td className={classes.tableBodyCell}>{item?.emission_kgco2e ? item?.emission_kgco2e.toFixed(2) : 0.00}</td>
                        <td className={classes.tableBodyCell}>{item?.emission_tco2e ? item?.emission_tco2e.toFixed(2) : 0.00}</td>
                        <td className={classes.tableBodyCell}>{item?.total_emission ? item?.total_emission.toFixed(2) : 0.00}</td>
                    </tr>
                    ))
                ))
              }

            <tr>
                <td colSpan="12" className="hr_spacing">
                  <hr className={classes.tableHr} />{" "}
                </td>
            </tr>
              <tr>
                <td className={classes.tableBodyCell}>
                  <strong>Scope 2 Total</strong>
                </td>
                <td className={classes.tableBodyCell}></td>
                <td className={classes.tableBodyCell}></td>
                <td className={classes.tableBodyCell}></td>
                <td className={classes.tableBodyCell}>
                  <strong>{totals.scope2.total_cy.toFixed(2)}</strong>
                </td>
                <td className={classes.tableBodyCell}></td>
                <td className={classes.tableBodyCell}>
                  <strong>{totals.scope2.total_kgco2e.toFixed(2)}kgCO2e</strong>
                </td>
                <td className={classes.tableBodyCell}>
                  <strong>{totals.scope2.total_tco2e.toFixed(2)}tCO2e</strong>
                </td>
                <td className={classes.tableBodyCell}>
                  <strong>{totals.scope2.total_emission.toFixed(2)}%</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2" className={classes.accordianItem}>
        <Accordion.Header className={classes.accHeader}>
          Scope 3 Emissions
        </Accordion.Header>
        <Accordion.Body className={classes.accordionSpc}>
          <table className={classes.scopeTable}>
            <thead>
              <tr>
                <th className={classes.tableHeadCell} style={{ width: "10%" }}>
                  Group
                </th>
                <th className={classes.tableHeadCell} style={{ width: "13%" }}>
                  Emissions Group
                </th>
                <th className={classes.tableHeadCell} style={{ width: "20%" }}>
                  Emission Source
                </th>
                <th className={classes.tableHeadCell} style={{ width: "8%" }}>
                  Unit
                </th>
                <th className={classes.tableHeadCell} style={{ width: "10%" }}>
                  Activity data CY {moment().year()}
                </th>
                <th className={classes.tableHeadCell} style={{ width: "10%" }}>
                  Emission factor
                </th>
                <th className={classes.tableHeadCell} style={{ width: "10%" }}>
                  {moment().year()} Emissions (kg CO2e)
                </th>
                <th className={classes.tableHeadCell} style={{ width: "10%" }}>
                  {moment().year()} Emissions (tCO2e)
                </th>
                <th className={classes.tableHeadCell} style={{ width: "10%" }}>
                  {moment().year()} Emissions % of total
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="12" className="hr_spacing">
                  {" "}
                  <hr className={classes.tableHr} />{" "}
                </td>
              </tr>
                {scope3.map((child, parentIndex) => (
                    child.map((item, index) => (
                    <tr key={index}>
                        <td className={classes.tableBodyCell}> {index === 0 ? item?.group : null}</td>
                        <td className={classes.tableBodyCell}>
                        {item?.emission_group}
                        </td>
                        <td className={classes.tableBodyCell}>
                        {item?.source}
                        </td>
                        <td className={classes.tableBodyCell}>{item?.unit}</td>
                        <td className={classes.tableBodyCell}>{item?.activity_cy ? item?.activity_cy.toFixed(2) : 0 }</td>
                        <td className={classes.tableBodyCell}>{item?.emission_factor ? item?.emission_factor.toFixed(2) : 0.00}</td>
                        <td className={classes.tableBodyCell}>{item?.emission_kgco2e ? item?.emission_kgco2e.toFixed(2) : 0.00}</td>
                        <td className={classes.tableBodyCell}>{item?.emission_tco2e ? item?.emission_tco2e.toFixed(2) : 0.00}</td>
                        <td className={classes.tableBodyCell}>{item?.total_emission ? item?.total_emission.toFixed(2) : 0.00}</td>
                    </tr>
                    ))
                ))
              }

            <tr>
                <td colSpan="12" className="hr_spacing">
                  <hr className={classes.tableHr} />{" "}
                </td>
            </tr>
              <tr>
                <td className={classes.tableBodyCell}>
                  <strong>Scope 3 Total</strong>
                </td>
                <td className={classes.tableBodyCell}></td>
                <td className={classes.tableBodyCell}></td>
                <td className={classes.tableBodyCell}></td>
                <td className={classes.tableBodyCell}>
                  <strong>{totals.scope3.total_cy.toFixed(2)}</strong>
                </td>
                <td className={classes.tableBodyCell}></td>
                <td className={classes.tableBodyCell}>
                  <strong>{totals.scope3.total_kgco2e.toFixed(2)}kgCO2e</strong>
                </td>
                <td className={classes.tableBodyCell}>
                  <strong>{totals.scope3.total_tco2e.toFixed(2)}tCO2e</strong>
                </td>
                <td className={classes.tableBodyCell}>
                  <strong>{totals.scope3.total_emission.toFixed(2)}%</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default Summary;

import { createContext, useContext, useState, useEffect } from 'react';
import config from '../config/config.json';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  const currentUser = localStorage.getItem('currentUser');
  const parsedToken = JSON.parse(currentUser);
  const token = parsedToken?.data?.token;
  const currentRole = localStorage.getItem('user_status');


  const [auth, setAuth] = useState({
    isAuthenticated: token ? true : false,
    role: currentRole,
    token: token,
  });



  useEffect(() => {
    // check user current status from backend API
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    fetch(config.BASE_URL + "esg/check_user_status", { headers })
      .then((res) => res.json())
      .then(
        (result) => {
          if(result.type === "TRIAL_EXPIRED") {
            logout()
          }else{
            const isDisable = result?.data?.isDisable;
            if(isDisable) {

              // eslint-disable-next-line
              const role = isDisable == 0 ? "disabled" :  isDisable == 1 ? "regular" : "trial";
              localStorage.setItem('user_status', role);
              setAuth({ isAuthenticated: true,
                role: role,
                token
              });
            }
          }
        },
        (error) => {
          console.log("ERROR OCCURED", error.response);
        }
      ).catch((error) => {
        console.log("error", error.response);
      });
       // eslint-disable-next-line
  }, []);
  
   const login = (token, role) => {
    // localStorage.setItem('token', token);
    // localStorage.setItem('role', role);
    setAuth({ isAuthenticated: true, role, token });
  };

  const logout = () => {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('role');
    setAuth({ isAuthenticated: false, role: null, token: null });
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
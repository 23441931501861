import React, { useEffect } from 'react';
import './Loading.css';

const Loading = () => {

    useEffect(()=> {
        
    }, []);
    
  return (
    <div className='loader__container'>
    <div className="text-center">
    <div className="spinner-border" role="status" style={{ width: '6rem', height: '6rem' }}>
      <span className="sr-only">Loading...</span>
    </div>
  </div>
  </div>
  )
}

export default Loading
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { sweetAlert } from "../../../../utils/UniversalFunction";
import React, { Component } from "react";
import Sidebar from "../../../sidebar/sidebar";
import Header from "../../../header/header";
import Moment from "react-moment";
import image16 from "../../../../img/image 16.png";
import image17 from "../../../../img/image 17.png";
import image18 from "../../../../img/image 18.png";
// import image19 from "../../../../img/Group 81311.png";
import { NavLink } from "react-router-dom";
import Table from "react-bootstrap/Table";
import config from "../../../../config/config.json";
import EnviornmentConsideration from "../../Component/Dashboard/enviornment_consideration";
import "./home.css";
import axios from "axios";
import { ExternalLink } from "react-external-link";
import { authenticationService } from "../../../../_services/authentication";
import { Recommendations } from "./Recommendations";
import CircularProgressBar from "./CircularProgressBar";
import { saveAs } from "file-saver";
import Spinner from 'react-bootstrap/Spinner';

const currentUser = authenticationService.currentUserValue;

export default class home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      criteria: [props.location.aboutProps] ?? [],
      isLoaded: true,
      finalIds: [],
      list: [],
      blogs: [],
      innovativeProgram: [],
      subInnovativeProgram: [],
      globalInnovativeProgram: [],
      limit: 10,
      skip: 0,
      startDate: "",
      endDate: "",
      submitted: false,
      csvsubmitted: false,
      firstName: "",
      lastName: "",
      uuid: [],
      email: "",
      companyName: "",
      items: [],
      businessPerformance: 0,
      esgRiskManagement: 0,
      supplierRiskManagement: 0,
      carbonFootprint: 0,
      sustainableDevelopmentGoals: 0,
      businessPerformanceRecommendation: {},
      esgRiskManagementRecommendation: {},
      supplierRiskManagementRecommendation: {},
      carbonFootprintRecommendation: {},
      sustainableDevelopmentGoalsRecommendation: {},
      annualFile: null,
      reportLoad: false
    };
    this.getPoints = this.getPoints.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.getInnovativeProgrammes = this.getInnovativeProgrammes.bind(this);
    this.getInnovativeProgrammesbyUserIDForSubUsers =
      this.getInnovativeProgrammesbyUserIDForSubUsers.bind(this);
  }

  onFileChange = (event) => {
    this.setState({ csvsubmitted: true });
    let topicId = event.target.getAttribute("data-id");
    const formData = new FormData();

    // Update the formData object
    formData.append("csv", event.target.files[0], event.target.files[0].name);
    formData.append("supplier_management_criteria_ids", topicId);
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    axios
      .post(config.OLD_API_URL + "supplierManagementWithCSV", formData, {
        headers,
      })
      .then((response) => {
        sweetAlert("success", response.data.message);
        setTimeout(() => {
          const newLocal = "/supplier_management_detail";
          this.props.history.push(newLocal);
        }, 1000);
      })
      .catch(function (response) {
        sweetAlert("error", response.data.message);
      });
  };

  getPoints(requestOptions) {
    fetch(config.API_URL + "getTotalPoints", requestOptions)
      .then((res) => res.json())
      .then(
        (data) => {
          this.setState({
            businessPerformance: data?.points?.businessPerformance,
            esgRiskManagement: data?.points?.esgRiskManagement,
            supplierRiskManagement: data?.points?.supplierRiskManagement,
            carbonFootprint: data?.points?.carbonFootprint,
            sustainableDevelopmentGoals: data?.points?.sdg,
            businessPerformanceRecommendation: data?.points?.isRecommendated
              ?.businessPerformance
              ? JSON.parse(data?.points?.isRecommendated?.businessPerformance)
              : {},
            esgRiskManagementRecommendation: data?.points?.isRecommendated
              ?.esgRiskManagement
              ? JSON.parse(data?.points?.isRecommendated?.esgRiskManagement)
              : {},
            supplierRiskManagementRecommendation: data?.points?.isRecommendated
              ?.supplierRiskManagement
              ? JSON.parse(
                  data?.points?.isRecommendated?.supplierRiskManagement
                )
              : {},
            carbonFootprintRecommendation: data?.points?.isRecommendated
              ?.carbonFootprint
              ? JSON.parse(data?.points?.isRecommendated?.carbonFootprint)
              : {},
            sustainableDevelopmentGoalsRecommendation: data?.points
              ?.isRecommendated?.sustainableDevelopmentGoals
              ? JSON.parse(
                  data?.points?.isRecommendated?.sustainableDevelopmentGoals
                )
              : {},
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
          });
        }
      );
  }

  onDateChange(event) {
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
    let setStartDate = null;
    let setEndDate = null;
    fetch(
      config.OLD_API_URL +
        `supplyChainRiskManagement?limit=${this.state.limit}&skip=${this.state.skip}&startDate=${setStartDate}&endDate=${setEndDate}`,
      { headers }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            totalCount: result.totalCount,
            items: result.data,
            list: result.data,
            pages: Math.floor(result.data.length / this.state.perPage),
            isEditableOrNot: result?.insertOrUpdate,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  getInnovativeProgrammes(uuid) {
    fetch(config.API_URL + `getInnovativeProgrammesByUUID/${uuid}`)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded2: true,
            innovativeProgram: result.innovativeProgram,
          });
        },
        (error2) => {
          this.setState({
            isLoaded2: true,
            error2,
          });
        }
      );
  }

  getInnovativeProgrammesbyUserIDForSubUsers(id) {
    fetch(config.API_URL + `getInnovativeProgrammesbyUserIDForSubUsers/${id}`)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded2: true,
            subInnovativeProgram: result.innovativeProgram,
          });
        },
        (error2) => {
          this.setState({
            isLoaded2: true,
            error2,
          });
        }
      );
  }

  getTotalPerformance() {
    let total =
      this.state.businessPerformance +
      this.state.esgRiskManagement +
      this.state.supplierRiskManagement +
      this.state.carbonFootprint +
      this.state.sustainableDevelopmentGoals;
    if (total <= 450) {
      return "Needs Improvement";
    } else if (total <= 800) {
      return "Average";
    } else if (total <= 1000) {
      return "Excellent";
    }
  }

  componentDidMount() {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };
    this.getPoints(requestOptions);
    fetch(config.API_URL + "blogs?search=&sortBy=desc", requestOptions)
      .then((res) => res.json())
      .then(
        (data) => {
          this.setState({
            isLoaded: false,
            blogs: data?.result,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
          });
        }
      );

    fetch(config.API_URL + `getGlobalInnovativeProgrammes`)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded2: true,
            globalInnovativeProgram: result.innovativeProgram,
          });
        },
        (error2) => {
          this.setState({
            isLoaded2: true,
            error2,
          });
        }
      );

    fetch(config.API_URL + "getProfileData", requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded2: true,
            id: result.user[0]?.id,
            uuid: result.user[0]?.uuid,
          });
          this.getInnovativeProgrammes(result.user[0]?.uuid);
          this.getInnovativeProgrammesbyUserIDForSubUsers(result.user[0]?.id);
        },
        (error) => {
          this.setState({
            isLoaded2: true,
            error,
          });
        }
      );

    fetch(
      config.OLD_API_URL +
        `supplyChainRiskManagement?limit=${this.state.limit}&skip=${this.state.skip}&startDate=${this.state.startDate}&endDate=${this.state.endDate}`,
      requestOptions
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            totalCount: result.totalCount ?? 0,
            items: result.data,
            list: result.data,
            pages: Math.floor(result.data.length / this.state.perPage),
            isEditableOrNot: result?.insertOrUpdate,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
      // this.handleAnuualReportDownload();
  }

  async handleAnuualReportDownload() {
    this.setState({reportLoad: true});
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };

    try{
      const response = await fetch(config.BASE_URL + `esg/report/annual_report/download`, requestOptions);
      const payload = await response.json();

      if(payload?.data?.file) {
            this.setState({
              annualFile: payload?.data?.file,
              reportLoad: false
            });  
      }else{
        this.setState({
          reportLoad: false
        });  
      }
    }catch(error) {
      sweetAlert('error', "Oops some error occured!");
      this.setState({
              reportLoad: false,
              isLoaded2: true,
      });
    }  

    // .then((res) => res.json())
    // .then(
    //   (result) => {
    //     if(result?.data?.file){

    //       console.log("result?.data?.file", result?.data?.file);
    //       this.setState({
    //         annualFile: result?.data?.file,
    //         reportLoad: false
    //       });
    //     }
    //   },
    //   (error2) => {
    //     this.setState({
    //       reportLoad: false,
    //       isLoaded2: true,
    //       error2,
    //     });
    //   }
    // );
  }

  async handleDownloadFile() {

    await this.handleAnuualReportDownload();
    if(this.state.annualFile){
      sweetAlert("success", "Success!");
      const url = `${config.BASE_URL}reports/${this.state?.annualFile}`;
      
      setTimeout(() => {
        saveAs(url);
      }, 200)
    }else{
      sweetAlert("info", "Your report will be downloaded within 48hrs!");
      this.setState({
        reportLoad: false
      });  
    }
  }

  async handelAnnualReportDownload2(e) {
    try {
      this.setState({reportLoad: true});
      e.preventDefault();

      const headers = {
        Authorization: `Bearer ${currentUser.data.token}`,
        Accept: "application/json",
      };

      axios
        .get(config.API_URL + "repotings/downloadCompanyReport/annual", {
          headers,
        })
        .then((response) => {
          if (response?.data?.file) {
            
            sweetAlert("success", "Success!");
            let url = config.BASE_URL + "docs/" + response?.data?.file;

            setTimeout(() => {
              this.setState({ reportLoad: false });
              saveAs(url);
            }, 1000);
          } else {
            sweetAlert("error", "Unable to find file!");
            this.setState({ reportLoad: false });
          }
        })
        .catch(function (error) {
          if (error.response) {
            this.setState({ reportLoad: false });
            sweetAlert(
              "error",
              "Oops some error created while dowloading file"
            );
          }
        });
    } catch (err) {
      sweetAlert("error", err.message);
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      blogs,
      businessPerformance,
      esgRiskManagement,
      supplierRiskManagement,
      carbonFootprint,
      sustainableDevelopmentGoals,
    } = this.state;

    // const businessPerformanceText =
    //   businessPerformance >= 0 && businessPerformance < 131
    //     ? "Below Average"
    //     : businessPerformance > 130 && businessPerformance < 171
    //       ? "Average"
    //       : businessPerformance > 170 && businessPerformance < 251
    //         ? "Above Average"
    //         : "None";
    // const esgRiskManagementText =
    //   esgRiskManagement >= 0 && esgRiskManagement < 131
    //     ? "Below Average"
    //     : esgRiskManagement > 130 && esgRiskManagement < 171
    //       ? "Average"
    //       : esgRiskManagement > 170 && esgRiskManagement < 251
    //         ? "Above Average"
    //         : "None";
    // const supplierRiskManagementText =
    //   supplierRiskManagement >= 0 && supplierRiskManagement < 131
    //     ? "Below Average"
    //     : supplierRiskManagement > 130 && supplierRiskManagement < 171
    //       ? "Average"
    //       : supplierRiskManagement > 170 && supplierRiskManagement < 251
    //         ? "Above Average"
    //         : "None";
    // const carbonFootprintText =
    //   carbonFootprint >= 0 && carbonFootprint < 41
    //     ? "Below Average"
    //     : carbonFootprint > 40 && carbonFootprint < 71
    //       ? "Average"
    //       : carbonFootprint > 70 && carbonFootprint < 151
    //         ? "Above Average"
    //         : "None";
    // const sustainableDevelopmentGoalsText =
    //   sustainableDevelopmentGoals >= 0 && sustainableDevelopmentGoals < 41
    //     ? "Below Average"
    //     : sustainableDevelopmentGoals > 40 && sustainableDevelopmentGoals < 61
    //       ? "Average"
    //       : sustainableDevelopmentGoals > 60 && sustainableDevelopmentGoals < 101
    //         ? "Above Average"
    //         : "None";

    const {
      list,
      totalCount,
      innovativeProgram,
      globalInnovativeProgram,
      subInnovativeProgram,
    } = this.state;
    let weathers =
      list.map((item, keys) => {
        return (
          <tr key={keys}>
            <td>{keys + 1}</td>
            <td>{item.companyName}</td>
            <td>
              <Moment format="DD/MM/YYYY">{item.createdAt}</Moment>
            </td>
            <td>{item.followingCriteria}</td>
            <td>
              <span className="red bold center">
                {item.identified_risk === "High" && "High"}
              </span>
              <span className="green bold center">
                {item.identified_risk === "Low" && "Low"}
              </span>
              <span className="bold center">
                {item.identified_risk === "Medium" && "Medium"}
              </span>
            </td>
          </tr>
        );
      }) || "";

    // let total =
    //   this.state.businessPerformance +
    //   this.state.esgRiskManagement +
    //   this.state.supplierRiskManagement +
    //   this.state.carbonFootprint +
    //   this.state.sustainableDevelopmentGoals;
    // let final = total / 10 + "%";

    return (
      <div>
        <Sidebar dataFromParent={this.props.location.pathname} />
        <Header />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-xxl-6 col-lg-12 col-sm-12 col-12">
                      <div className="esg_score_title">Your ESG Score</div>
                      <div className="color_div">
                        <div className="main_text">
                          {/* <div className="current_score">
                            <div className="score">
                              <div
                                className="half-arc"
                                style={{ "--percentage": `${final}` }}
                              >
                                <span id="value_graph" className="label">
                                  {businessPerformance +
                                    esgRiskManagement +
                                    supplierRiskManagement +
                                    carbonFootprint +
                                    sustainableDevelopmentGoals}
                                  /1000
                                </span>
                              </div>
                              <h4 className="performance">
                                {this.getTotalPerformance()}
                              </h4>
                              <div className="score_dashboard"></div>
                              <div className="sore_ligh_background"></div>
                            </div>
                          </div> */}
                          <CircularProgressBar
                            score={
                              businessPerformance +
                              esgRiskManagement +
                              supplierRiskManagement +
                              carbonFootprint +
                              sustainableDevelopmentGoals
                            }
                          />
                          <div className="tabele_div dashboard_score_div">
                            <div className="esg_score_title module_button_download">
                              Module
                            {this.state.reportLoad ? (
                                <button
                                className="btn btn esgDownloadReporting "
                                >
                                <Spinner animation="border" role="status" size="sm">
                                </Spinner>
                                  Downloading..
                                </button>
                            ): (
                              <button
                                className="btn btn esgDownloadReporting "
                                href="#"
                                // onClick={(e) => this.handelAnnualReportDownload2(e)}
                                onClick={(e) => this.handleDownloadFile(e)}
                              >
                                Download your complete ESG Report
                              </button>
                            )}

                            </div>
                            <div className="over_lor">
                              {/* <div className="over_view">
                                  <div className="over_top">
                                    <h5 className="model_text">Module</h5>
                                  </div>
                                  <div className="over_top">
                                    <h5 className="model_text">Average</h5>
                                  </div>
                                  <div className="over_top">
                                    <h5 className="model_text">Score</h5>
                                  </div>
                                </div> */}
                              <div className="inter_fats">
                                <div className="busins_point">
                                  <div className="bussins">
                                    <p className="prbus">
                                      Business Performance
                                    </p>
                                  </div>
                                  {/* <div className="above_text">
                                    <p className="aver_icon">
                                      <i
                                        className="fa fa-caret-up tio"
                                        aria-hidden="true"
                                      ></i>
                                      {businessPerformanceText}
                                    </p>
                                  </div> */}
                                  <div className="text_number">
                                    <p className="nam_hol">
                                      {businessPerformance} Score
                                    </p>
                                  </div>
                                </div>
                                <div className="f_Impr">
                                  <p className="Area_of">
                                    Areas of Improvement:
                                  </p>
                                  {/* <p className="Corpor">
                                    Conflict of interest, Corporate Governance
                                    Statement &amp; ESG on the board
                                  </p> */}
                                  <p>
                                    {/* Recommendations: */}
                                    <Recommendations
                                      items={
                                        this.state
                                          .businessPerformanceRecommendation
                                      }
                                    />
                                  </p>
                                </div>
                              </div>
                              <hr className="ofice" />
                              {/* <!-- two --> */}
                              <div className="inter_fats">
                                <div className="busins_pointw">
                                  <div className="bussins">
                                    <p className="prbus">ESG Risk Management</p>
                                  </div>
                                  {/* <div className="above_text">
                                    <p className="aver_icon">
                                      <i
                                        className="fa fa-caret-up tio"
                                        aria-hidden="true"
                                      ></i>
                                      {esgRiskManagementText}
                                    </p>
                                  </div> */}
                                  <div className="text_number">
                                    <p className="nam_hol">
                                      {esgRiskManagement} Score
                                    </p>
                                  </div>
                                </div>
                                <div className="f_Impr">
                                  <p className="Area_of">
                                    Areas of Improvement:
                                  </p>
                                  {/* <p className="Corpor">
                                    Answer sector questions &amp; report using <b>ESGRI</b>
                                  </p> */}
                                  <p>
                                    {/* Recommendations: */}
                                    <Recommendations
                                      items={
                                        this.state
                                          .esgRiskManagementRecommendation
                                      }
                                    />
                                  </p>
                                </div>
                              </div>
                              <hr className="ofice" />
                              {/* <!-- thre --> */}
                              <div className="inter_fats">
                                <div className="busins_pointw">
                                  <div className="bussins">
                                    <p className="prbus">
                                      Supplier Risk Management
                                    </p>
                                  </div>
                                  {/* <div className="above_text">
                                    <p className="aver_icon">
                                      <i className="fas fa-caret-down"></i>
                                      {supplierRiskManagementText}
                                    </p>
                                  </div> */}
                                  <div className="text_number">
                                    <p className="nam_hol">
                                      {supplierRiskManagement} Score
                                    </p>
                                  </div>
                                </div>
                                <div className="f_Impr">
                                  <p className="Area_of">
                                    Areas of Improvement:
                                  </p>
                                  {/* <p className="Corpor">
                                    Improve supplier communication (if
                                    transparency is medium or low)
                                  </p> */}
                                  <p>
                                    {/* Recommendations: */}
                                    <Recommendations
                                      items={
                                        this.state
                                          .supplierRiskManagementRecommendation
                                      }
                                    />
                                  </p>
                                </div>
                              </div>
                              <hr className="ofice" />
                              {/* <!-- four --> */}
                              <div className="inter_fats">
                                <div className="busins_pointw">
                                  <div className="bussins">
                                    <p className="prbus">Carbon Footprint</p>
                                  </div>
                                  {/* <div className="above_text">
                                    <p className="aver_icon">
                                      <i
                                        className="fas fa-caret-down"
                                        aria-hidden="true"
                                      ></i>
                                      {carbonFootprintText}
                                    </p>
                                  </div> */}
                                  <div className="text_number">
                                    <p className="nam_hol">
                                      {carbonFootprint} Score
                                    </p>
                                  </div>
                                </div>
                                <div className="f_Impr">
                                  <p className="Area_of">
                                    Areas of Improvement:
                                  </p>
                                  {/* <p className="Corpor">
                                    Complete scope 3 emissions
                                  </p> */}
                                  <p>
                                    {/* Recommendations: */}
                                    <Recommendations
                                      items={
                                        this.state.carbonFootprintRecommendation
                                      }
                                    />
                                  </p>
                                </div>
                              </div>
                              <hr className="ofice" />
                              {/* <!-- five --> */}
                              <div className="inter_fats">
                                <div className="busins_pointw">
                                  <div className="bussins">
                                    <p className="prbus">
                                      Sustainable Development Goals
                                    </p>
                                  </div>
                                  {/* <div className="above_text">
                                    <p className="aver_icon">
                                      <i className="fas fa-caret-down"></i>
                                      {sustainableDevelopmentGoalsText}
                                    </p>
                                  </div> */}
                                  <div className="text_number">
                                    <p className="nam_hol">
                                      {sustainableDevelopmentGoals} Score
                                    </p>
                                  </div>
                                </div>
                                <div className="f_Impr">
                                  <p className="Area_of">
                                    Areas of Improvement:
                                  </p>
                                  {/* <p className="Corpor">
                                    Gender Equality, No Poverty &amp; Zero
                                    Hunger
                                  </p> */}
                                  <p>
                                    {/* Recommendations: */}
                                    <Recommendations
                                      items={
                                        this.state
                                          .sustainableDevelopmentGoalsRecommendation
                                      }
                                    />
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="Download_esg_report">
                          
                        </div> */}
                      </div>
                      <div></div>
                    </div>
                    <div className="col-xxl-6 col-lg-12 col-sm-12 col-12">
                      <div className="row_two_hol">
                        <div className="esg_score_title">
                          Key Supplier Risks
                        </div>
                        <div
                          className={
                            totalCount === 0
                              ? "hideData"
                              : "color_div_key div_scroll key_div"
                          }
                        >
                          <div className="table_f manage-detail admin-risk-table table-responsive">
                            <Table striped bordered hover size="sm">
                              <thead>
                                <tr className="heading_color">
                                  <th className="t_head">ID</th>
                                  <th className="t_head">SUPPLIER</th>
                                  <th className="t_head">DATE ADDED</th>
                                  <th className="t_head">CRITERIA &nbsp; ASSESSED</th>
                                  <th className="t_head">IDENTIFIED RISK</th>
                                </tr>
                              </thead>
                              <tbody>{weathers}</tbody>
                            </Table>
                          </div>
                        </div>

                        <div
                          className={
                            totalCount === 0 ? "color_div_key" : "hideData"
                          }
                        >
                          <div className="text_busses">
                            <div className="your_business">
                              {/* <h4 className="font-heading">
                                Key Supplier Risks
                              </h4> */}
                            </div>
                          </div>
                          <div className="table_row">
                            <div className="image-upload-wrap">
                              <input
                                type="file"
                                className="file-upload-input"
                                name="supplierData"
                                accept=".csv"
                                onChange={this.onFileChange}
                              />
                              <div className="drag-text">
                                <i className="far fa-file-alt"></i>
                                <h3>Upload Your Supplier Details</h3>
                              </div>
                            </div>
                            <div className="text-outside">
                              <p>
                                Upload or drag &amp; drop your <br />
                                documents for key supplier risks
                              </p>
                            </div>
                            {/* <div className="meettable mt-3">
                              <Link
                                to="../../../../../../template.xlsx"
                                target="_blank"
                                className="page_save_min page_width_mini m-0"
                                type="button"
                              >
                                Download Template
                              </Link>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      {/* <!-- table tab --> */}
                      <div className="row_two_hol">
                        <div className="color_div _q tabbing_enviormental_div">
                          <div className="tabbedPanels">
                            {/* <!-- begins the tabbed panels / wrapper--> */}
                            <div className="panelContainer">
                              <EnviornmentConsideration />
                            </div>
                          </div>
                          {/* <!-- end panel 4 --> */}
                        </div>
                      </div>
                      <div className="row_two_hol">
                        <div className="esg_score_title">
                          Recommended Innovative Programs for you
                        </div>
                        <div className="color_div_Programs div_scroll recommend_div">
                          {/* <div className="text_busses">
                            <div className="your_businefs">
                              <h4 className="progam_commend mb-3">
                                
                              </h4>
                            </div>
                          </div> */}
                          <div className="vertical_row">
                            <div className="progreas">
                              {/* <h5 className="not_show"> Kindly share if this is required- Show the following if not supplied in the modules: */}
                              {/* </h5> */}
                              <div className="text_pros">
                                <ul className="sp_po">
                                  {globalInnovativeProgram.map((item, key) => (
                                    <li key={key}>
                                      <span className="pol">
                                        <strong>{item.title}: </strong>
                                      </span>
                                      <span className="ancy">
                                        {item.description}
                                      </span>
                                      <br />
                                      <div className="my-2 pol1">
                                        Date :{" "}
                                        <Moment format="DD-MMM-YYYY" withTitle>
                                          {item.created_at}
                                        </Moment>
                                      </div>
                                    </li>
                                  ))}
                                  {innovativeProgram.map((item, key) => (
                                    <li key={key}>
                                      <span className="pol">
                                        <strong>{item.title}: </strong>
                                      </span>
                                      <span className="ancy">
                                        {item.description}
                                      </span>
                                      <br />
                                      <div className="my-2 pol1">
                                        Date :{" "}
                                        <Moment format="DD-MMM-YYYY" withTitle>
                                          {item.created_at}
                                        </Moment>
                                      </div>
                                    </li>
                                  ))}
                                  {subInnovativeProgram.map((item, key) => (
                                    <li key={key}>
                                      <span className="pol">
                                        <strong>{item.title}: </strong>
                                      </span>
                                      <span className="ancy">
                                        {item.description}
                                      </span>
                                      <br />
                                      <div className="my-2 pol1">
                                        Date :{" "}
                                        <Moment format="DD-MMM-YYYY" withTitle>
                                          {item.created_at}
                                        </Moment>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- new add --> */}

                  <div className="camad">
                    <div className="color_div_Current">
                      <div className="color_rent mb-0">
                        <h6 className="home_text font-heading pb-0">
                          Current News
                        </h6>
                      </div>
                      <div className="row">
                        {blogs
                          .slice(0, 6)
                          .map((item, key) => (
                            <div
                              key={key}
                              className="col-xxl-4 col-lg-6 col-md-12 col-12 mt-5"
                            >
                              <div className="row">
                                <div className="col-sm-6 ">
                                  <div className="curren">
                                    <div className="curren_text traning_image">
                                      <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={item.link}
                                      >
                                        <img src={item.image} />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6 main__container">
                                  <div className="curren">
                                    <div className="curren_hop">
                                      <p className="dete_time">
                                        <Moment format="MMM YYYY" withTitle>
                                          {item.created_at}
                                        </Moment>
                                      </p>

                                      <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={item.link}
                                      >
                                        <h4 className="Austr_text mt-3 clamp-lines">
                                          {item.title}
                                        </h4>
                                      </a>
                                      <div className="companyName-container">
                                        <h5 className="companyName">
                                          {item.companyName}
                                        </h5>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                      {/* <!-- row two --> */}
                      <div className="comleted_dell home-align mt-3">
                        <NavLink to="/training">view all</NavLink>
                      </div>
                    </div>
                  </div>

                  {/* <!-- new add --> */}
                  {/* <!-- col-12 --> */}
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="row_two_hole">
                        <div className="color_div_KATE">
                          <div className="three_box">
                            <div className="row">
                              <div className="col-xxl-4 col-lg-6 col-md-12 col-12">
                                {/* <!-- Card --> */}
                                <ExternalLink
                                  href="https://lms.esgri.tech/dashboard/"
                                  className="card_anchor"
                                >
                                  <article className="card animated fadeInUp">
                                    <div className="card-block image">
                                      <h6 className="text-muted">Academy</h6>
                                    </div>
                                    <div className="four_box">
                                      <img
                                        className="imagev_icon"
                                        src={image16}
                                        alt=""
                                      />
                                    </div>
                                    <div className="card-block">
                                      <div className="text_bill">
                                        <p className="card-text card_white">
                                          New to the platform?
                                        </p>
                                        <p className="card-text card_white">
                                          Get started with our Introductory
                                          video Courses.
                                        </p>
                                      </div>
                                    </div>
                                  </article>
                                </ExternalLink>
                                {/* <!-- .end Card --> */}
                              </div>
                              <div className="col-xxl-4 col-lg-6 col-md-12 col-12">
                                {/* <!-- Card --> */}
                                <NavLink
                                  to="/coaching_form"
                                  className="card_anchor"
                                >
                                  <article className="card animated fadeInUp op">
                                    <div className="card-block image">
                                      <h6 className="text-muted">Coaching</h6>
                                    </div>
                                    <div className="four_box">
                                      <img
                                        className="imagev_icon"
                                        src={image17}
                                        alt=""
                                      />
                                    </div>
                                    <div className="card-block">
                                      <div className="text_bill">
                                        <p className="card-text card_white">
                                          Find your coach &amp; <br />
                                          accelerate your learning
                                          <br /> &amp; reporting.
                                        </p>
                                        {/* <!--  <p className="card-text">Find your coach and<br> accelerate your learning<br> and reporting.</p> --> */}
                                      </div>
                                    </div>
                                  </article>
                                </NavLink>
                                {/* <!-- .end Card --> */}
                              </div>
                              <div className="col-xxl-4 col-lg-6 col-md-12 col-12">
                                {/* <!-- Card --> */}
                                <ExternalLink
                                  href="https://lms.esgri.tech/dashboard/"
                                  className="card_anchor"
                                >
                                  <article className="card animated fadeInUp oplo">
                                    <div className="card-block image">
                                      <h6 className="text-muted">
                                        Continuing Professional <br />
                                        Development
                                      </h6>
                                    </div>
                                    <div className="four_box">
                                      <img
                                        className="imagev_icon"
                                        src={image18}
                                        alt=""
                                      />
                                    </div>
                                    <div className="card-block">
                                      <div className="text_bill">
                                        <p className="card-text card_white">
                                          See content exclusively for
                                          <br /> our members.
                                        </p>
                                      </div>
                                    </div>
                                  </article>
                                </ExternalLink>
                                {/* <!-- .end Card --> */}
                              </div>
                            </div>
                          </div>
                          <div className="comleted_dell home-align mt-3">
                            <NavLink to="/training">view all</NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- col-12 --> */}
                </div>
              </section>
              {/* <!-- end main --> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

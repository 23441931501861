/* eslint-disable jsx-a11y/img-redundant-alt */
import Swal from "sweetalert2";
import React, { Component } from "react";
import config from "../../../../config/config.json";
import axios from "axios";
import { NavLink } from "react-router-dom";
import Sidebar from "../../../sidebar/sidebar";
import Header from "../../../header/header";
import "./card.css";
import "./billing.css";
import { authenticationService } from "../../../../_services/authentication";
import { readCokkie } from '../../../../utils/UniversalFunction';
import universalImage from '../../../../utils/universalimage.png' 


const ProfilePics = "https://res.cloudinary.com/dmklsntsw/image/upload/v1658480882/dummyPic.75a04487_fqfqey.png";
const baseURL = config.baseURL;
const currentUser = authenticationService.currentUserValue;

export default class SettingProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      firstName: "",
      lastName: "",
      email: "",
      businessNumber: "",
      sectorofInterests: "",
      userCategory: "",
      register_company_name: "",
      main_country_of_operations: "",
      company_industry: "",
      logo: "",
      position: "",
      no_of_users: "",
      password:"",
      id: "",
      type: "password",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmit2 = this.handleSubmit2.bind(this);
    this.showHide = this.showHide.bind(this);
  }

  showHide(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === "password" ? "input" : "password"
    });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  async removeProfile(e) {
    e.preventDefault();
    const response = await fetch(universalImage);
    const fileBlob = await response.blob();

    const formData = new FormData();
    formData.append("uploadImage", fileBlob, 'universalimage.png');
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    axios
      .post(config.API_URL + "uploadProfilePicture", formData, { headers })
      .then((response) => {

        console.log("RESPPPPPP", response.data);
        this.setState({ logo: response.data.data });
        setTimeout(() => {
          window.location.href = baseURL + "/Profile_Settings";
        }, 1000);
      })
      .catch(function (response) {
        Swal.fire({
          icon: 'error',
          title:  response.data.message,
          showConfirmButton: false,
          timer: 1000
        })
      });
   
  }


  onFileChange = (event) => {
    const formData = new FormData();

    // Update the formData object
    formData.append(
      "uploadImage",
      event.target.files[0],
      event.target.files[0].name
    );

    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    axios
      .post(config.API_URL + "uploadProfilePicture", formData, { headers })
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title:  response.data.message,
          showConfirmButton: false,
          timer: 1000
        })
        this.setState({ logo: response.data.data });
        setTimeout(() => {
          window.location.href = baseURL + "/Profile_Settings";
        }, 1000);
      })
      .catch(function (response) {
        Swal.fire({
          icon: 'error',
          title:  response.data.message,
          showConfirmButton: false,
          timer: 1000
        })
      });
  };
  handleSubmit2(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const {
      firstName,
      lastName,
      email,
      businessNumber,
      userCategory,
      register_company_name,
      main_country_of_operations,
      company_industry,
      position,
      no_of_users,
      password
    } = this.state;
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    axios
      .post(
        config.API_URL + "updateProfile",
        {
          register_company_name: register_company_name,
          main_country_of_operations: main_country_of_operations,
          business_number: businessNumber,
          company_industry: company_industry,
          user_category: userCategory,
          first_name: firstName,
          last_name: lastName,
          position: position,
          no_of_users: no_of_users,
          email: email,
          password: password,
        },
        { headers }
      )
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title:  response.data.message,
          showConfirmButton: false,
          timer: 1000
        })
        setTimeout(() => {
          window.location.href = baseURL + "/Profile_Settings";
        }, 1000);
      })
      .catch(function (error) {
        if (error.response) {
          Swal.fire({
            icon: 'error',
            title:  error.response.data.message,
            showConfirmButton: false,
            timer: 1000
          })
        }
      });
  }
  handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const {
      firstName,
      lastName,
      position,
      email,
      password
    } = this.state;
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    axios
      .post(
        config.API_URL + "updateProfileForCompanySubAdmin",
        {
          first_name: firstName,
          last_name: lastName,
          email: email,
          position: position,
          password:password
        },
        { headers }
      )
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title:  response.data.message,
          showConfirmButton: false,
          timer: 1000
        })
        setTimeout(() => {
          window.location.href = baseURL + "/Profile_Settings";
        }, 1000);
      })
      .catch(function (error) {
        if (error.response) {
          Swal.fire({
            icon: 'error',
            title:  error.response.data.message,
            showConfirmButton: false,
            timer: 1000
          })
        }
      });
  }

  handelLanguageChange(){
    document.body.style.top = '0px';
  }
  componentDidMount() {

    // Language fix
    const cokkie = readCokkie();
    if(cokkie === '/en/da') {
      const saveElem = document.querySelector('.lang_fix_save');
      if(saveElem) {
        saveElem.innerText="Gem"
      }
    }

    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };

    fetch(config.API_URL + "getProfileData", { headers })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded2: true,
            id: result.user[0]?.id,
            firstName: result.user[0]?.firstName,
            lastName: result.user[0]?.lastName,
            email: result.user[0]?.email,
            country: result.user[0]?.country,
            businessNumber: result.user[0]?.businessNumber,
            sectorofInterests: result.user[0]?.sectorofInterests,
            userCategory: result.user[0]?.userCategory,
            register_company_name: result.user[0]?.register_company_name,
            main_country_of_operations:
              result.user[0]?.main_country_of_operations,
            company_industry: result.user[0]?.company_industry,
            logo:
              result.user[0]?.logo === null
                ? ProfilePics
                : config.BASE_URL + result.user[0]?.logo,
            position: result.user[0]?.position,
            no_of_users: result.user[0]?.no_of_users,
            
          });
        },
        (error) => {
          this.setState({
            isLoaded2: true,
            error,
          });
        }
      );
  }

  render() {
    const {type } = this.state;
    const {
      firstName,
      lastName,
      businessNumber,
      userCategory,
      register_company_name,
      main_country_of_operations,
      company_industry,
      position,
      no_of_users,
      id,
    } = this.state;
    let role = JSON.parse(localStorage.getItem("currentUser")).data.role;
    return (
      <div>
        <Header />
        <Sidebar dataFromParent={this.props.location.pathname} />

        {/*  my_profile_menus */}
        <div className="main_wrapper">
        <div className={localStorage.getItem("role") === "company_admin" ? "tabs-top my_profile_menus" : "tabs-top my_profile_menus setting_tab_d"}>
            <ul>
              <li>
                <NavLink to="#" className="activee">
                  My Profile
                </NavLink>
              </li>
              <li>
                <NavLink to="/Google_2FA">Two Factor Authentication</NavLink>
              </li>
              {currentUser.data.role === "company_admin" && (
                <li>
                  <NavLink to="/Setting_Billing">Billing</NavLink>
                </li>
              )}
              {currentUser.data.role === "company_admin" && (
                <li>
                  <NavLink to="/sub_accounts">Sub Accounts</NavLink>
                </li>
              )}
            </ul>
          </div>
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="Introduction framwork_2">
                        <section className="forms">
                          {role === "company_admin" && (
                            <div className="row">
                              <div className="col-md-8 col-xs-12">
                                <form name="form" onSubmit={this.handleSubmit2}>
                                  <div className="business_detail">
                                    <div className="heading">
                                      <h4>Business Details</h4>
                                      {/* <span>
                                      <i className="fa fa-edit"></i>
                                    </span> */}
                                    </div>
                                    <hr className="line"></hr>
                                    <div className="form-group pb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Registered Company Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        placeholder="Registered Company Name"
                                        name="register_company_name"
                                        onChange={this.handleChange}
                                        value={register_company_name}
                                      />
                                    </div>
                                    <div className="form-group pb-3">
                                      <label htmlFor="exampleInputPassword1">
                                        Main Country of Operations
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="exampleInputPassword1"
                                        placeholder="Main Country Of Operations"
                                        name="main_country_of_operations"
                                        onChange={this.handleChange}
                                        value={main_country_of_operations}
                                      />
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6 col-xs-6">
                                        <div className="form-group pb-3">
                                        <label htmlFor="exampleInputPassword1">
                                            Business Number
                                          </label>
                                          <input
                                            type="tel"
                                            className="form-control"
                                            id="exampleInputPassword1"
                                            placeholder="businessNumber"
                                            maxLength={9}
                                            name="businessNumber"
                                            onChange={this.handleChange}
                                            value={businessNumber}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xs-6">
                                        <div className="form-group pb-3">
                                          <label htmlFor="exampleInputPassword1">
                                            No. of Users
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control disableddd"
                                            id="exampleInputPassword1"
                                            placeholder="Number of Users"
                                            name="no_of_users"
                                            disabled
                                            onChange={this.handleChange}
                                            value={no_of_users}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6 col-xs-6">
                                        <div className="form-group pb-3">
                                          <label htmlFor="exampleInputPassword1">
                                            Company Industry
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control disableddd"
                                            id="exampleInputPassword1"
                                            placeholder="Company Industry"
                                            name="company_industry"
                                            disabled
                                            onChange={this.handleChange}
                                            value={company_industry}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xs-6">
                                        <div className="form-group pb-3">
                                          <label htmlFor="exampleInputPassword1">
                                            User Category
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control disableddd"
                                            id="exampleInputPassword1"
                                            placeholder="User Category"
                                            disabled
                                            name="userCategory"
                                            onChange={this.handleChange}
                                            value={userCategory}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="business_detail mt-3">
                                    <div className="heading">
                                      <h4>Admin Details</h4>
                                    </div>
                                    <hr className="line"></hr>
                                    <div className="row">
                                      <div className="col-lg-6 col-xs-6">
                                        <div className="form-group pb-3">
                                          <label htmlFor="exampleInputPassword1">
                                            First Name
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="exampleInputPassword1"
                                            placeholder="First Name"
                                            name="firstName"
                                            onChange={this.handleChange}
                                            value={firstName}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xs-6">
                                        <div className="form-group pb-3">
                                          <label htmlFor="exampleInputPassword1">
                                            Last Name
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="exampleInputPassword1"
                                            placeholder="Last Name"
                                            name="lastName"
                                            onChange={this.handleChange}
                                            value={lastName}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group pb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Title or Position
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control disableddd"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        placeholder="Title or Position"
                                        name="position"
                                        disabled
                                        onChange={this.handleChange}
                                        value={position}
                                      />
                                    </div>
                                    <div className="form-group pb-3">
                                      <label htmlFor="exampleInputPassword1">
                                        Corporate Email
                                      </label>
                                      <input
                                        type="email"
                                        className="form-control disableddd"
                                        id="exampleInputPassword1"
                                        placeholder="Corporate Email"
                                        disabled
                                        name="email"
                                        onChange={this.handleChange}
                                        value={this.state.email}
                                      />
                                    </div>
                                    <div className="form-group pb-3">
                                      <label htmlFor="exampleInputPassword1">
                                      Reset Password
                                      </label>
                                      {/* <input
                                        type="password"
                                        className="form-control"
                                        id="exampleInputPassword1"
                                        placeholder="Enter password for Change or leave this field"
                                        name="password"
                                        onChange={this.handleChange}
                                      />
                                      <div className="form_sign password-eye">
                                        <div className="img-eye">
                                          <span onClick={this.showHide}>
                                            {this.state.type === "input" ? (
                                              <i className="fas fa-eye-slash"></i>
                                            ) : (
                                              <i className="fas fa-eye"></i>
                                            )}
                                          </span>
                                        </div>
                                      </div> */}
                                      <div className="form_sign password-eye">
                                        <div className="img-eye" style={{top: '17px'}}>
                                          <span onClick={this.showHide}>
                                            {this.state.type === "input" ? (
                                              <i className="fas fa-eye-slash"></i>
                                            ) : (
                                              <i className="fas fa-eye"></i>
                                            )}
                                          </span>
                                        </div>
                                        <input
                                          className="form-control name_nf"
                                          type={type}
                                          name="password"
                                          id="exampleInputPassword1"
                                          placeholder="Enter password for Change or leave this field"
                                          onChange={this.handleChange}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="global_link mt-3">
                                    <button
                                      type="submit"
                                      className="link_bal_next lang_fix_save"
                                    >
                                      SAVE
                                    </button>
                                  </div>
                                </form>
                                {/* <div className="business_detail mt-3">
                                    <div className="heading">
                                      <h4>Language Settings</h4>
                                    </div>
                                    <hr className="line"></hr>
                                  </div> */}
                              </div>
                              <div className="col-lg-4 col-xs-12">
                                <div className="upload_image imgChangeCont">
                                  <img
                                    className="file-upload-image"
                                    src={this.state.logo}
                                    alt="your image"
                                  />
                                  <input
                                    type="file"
                                    className="input_cont_profile"
                                    name="uploadImage"
                                    accept='.jpg, .png, .jpeg'
                                    data-id={id}
                                    onChange={this.onFileChange}                    
                                  />
                                  <div className="Creoss"><span onClick={(e) => this.removeProfile(e)}>X</span></div>
                                </div>
                                {/* <div className="text-outside">
                                <p>ID: 356DS543</p>
                              </div> */}
                              </div>
                            </div>
                          )}

                          {role === "company_sub_admin" && (
                            <div className="row">
                              <div className="col-md-8 col-xs-12">
                                <form name="form" onSubmit={this.handleSubmit}>
                                  <div className="business_detail mt-3">
                                    <div className="heading">
                                      <h4>Profile Details</h4>
                                    </div>
                                    <hr className="line"></hr>
                                    <div className="row">
                                      <div className="col-lg-6 col-xs-6">
                                        <div className="form-group pb-3">
                                          <label htmlFor="exampleInputPassword1">
                                            First Name
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="exampleInputPassword1"
                                            placeholder="name"
                                            name="firstName"
                                            onChange={this.handleChange}
                                            value={firstName}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xs-6">
                                        <div className="form-group pb-3">
                                          <label htmlFor="exampleInputPassword1">
                                            Last Name
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="exampleInputPassword1"
                                            placeholder="name"
                                            name="lastName"
                                            onChange={this.handleChange}
                                            value={lastName}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group pb-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Title or Position
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        placeholder="CEO"
                                        name="position"
                                        onChange={this.handleChange}
                                        value={position}
                                      />
                                    </div>
                                    <div className="form-group pb-3">
                                      <label htmlFor="exampleInputPassword1">
                                        Corporate Email
                                      </label>
                                      <input
                                        type="email"
                                        className="form-control disableddd"
                                        id="exampleInputPassword1"
                                        placeholder="name"
                                        disabled
                                        name="email"
                                        onChange={this.handleChange}
                                        value={this.state.email}
                                      />
                                    </div>
                                    <div className="form-group pb-3">
                                      <label htmlFor="exampleInputPassword1">
                                        Reset Password
                                      </label>
                                      <input
                                        type="password"
                                        className="form-control"
                                        id="exampleInputPassword1"
                                        placeholder="Enter password for Change or leave this field"
                                        name="password"
                                        onChange={this.handleChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="global_link mt-3">
                                    <button
                                      type="submit"
                                      className="link_bal_next lang_fix_save"
                                    >
                                      SAVE
                                    </button>
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4 col-xs-12">
                                <div className="upload_image imgChangeCont">
                                  <img
                                    className="file-upload-image"
                                    src={this.state.logo}
                                    alt="your image"
                                  />
                                  <input
                                    type="file"
                                    name="uploadImage"
                                    className="input_cont_profile"
                                    data-id={id}
                                    onChange={this.onFileChange}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import { Route, Redirect } from 'react-router-dom';
import { useAuth } from './AuthContext';

const ProtectedRoute = ({ component: Component, allowedRoles, ...rest }) => {
  const { auth } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) => {

        if (!auth.isAuthenticated) {
          return <Redirect to="/403" />;
        }

        if (!allowedRoles.includes(auth.role)) {
          return <Redirect to="/403" />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default ProtectedRoute;
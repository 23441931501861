import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import head_Logo from "../../img/logo.png";
import foot_Logo from "../../img/logol.png";
import "./common.css";
import "./sidebar.css";


const nestedData = [
  {
    parentLabel : "ESG Criteria",
    parentChild : [
      {
        childLabel : "Environment",
        grandChild : [
          {
            grandLabel : "Carbon Footprint",
            link: "/carbon_footprint",
            icon: "fal fa-shoe-prints",
            ids: ['carbon_footprint' ,'Scope_1_Emissions','Scope_2_Emissions', 'Scope_3_Emissions','result','carbon_footprint_detail']
          },{
            grandLabel : "Sector Questions",
            link: "/sector_questions",
            icon: "far fa-clipboard",
            ids: ['sector_questions', 'Social_Capital','Leadership_Governance', 'Human_Capital', 'Environmental_Capital', 'Cyber_Digital', 'Business_Model_Innovation', 'sector_question_detail']
          }
        ]
      },
      {
        childLabel : "Social",
        grandChild : [
          {
            grandLabel : "Board Skills",
            link: "/board_skills",
            icon: "far fa-clipboard",
            ids: ['board_skills','board_skills_detail'],
          },{
            grandLabel : "Suppliers",
            link: "/suppliers",
            icon: "far fa-user-cog",
            ids: [ 'suppliers', 'suppliers_fast', 'Environmental_Topics', 'Social_Topics', 'Governance_Topics', 'Business_Models', 'supplier_details']

          }
        ]
      },
      {        
        childLabel : "Governance",
        grandChild : [
          {
            grandLabel : "Governance",
            link: "/governance",
            icon: "far fa-building",
            ids: ['governance', 'Governance_Policies', 'Social_Policies', 'Cyber_&_Technology', 'Health_&_Safety_Policy', 'Environmental_Policy', 'other_policies']
          },{
            grandLabel : "Management",
            link: "/management",
            icon: "fal fa-users",
            ids: ['management','management_details']
          }
      ]

      }
    ]
  }
]


export default class sidebar extends Component {
  constructor(props) {
    super(props);
    const url = props?.dataFromParent?.split("/")[1];

    const validMenus = ['carbon_footprint','Scope_1_Emissions','Scope_2_Emissions', 'Scope_3_Emissions','result','carbon_footprint_detail',
     'sector_questions', 'Social_Capital','Leadership_Governance', 'Human_Capital', 'Environmental_Capital', 'Cyber_Digital', 'Business_Model_Innovation', 'sector_question_detail',
     'board_skills','board_skills_detail',
     'suppliers', 'suppliers_fast', 'Environmental_Topics', 'Social_Topics', 'Governance_Topics', 'Business_Models', 'supplier_details',
      'governance', 'Governance_Policies', 'Social_Policies', 'Cyber_&_Technology', 'Health_&_Safety_Policy', 'Environmental_Policy', 'other_policies',
      'management','management_details'
    ];
    const validSubMenu = {
      'carbon_footprint': 'Environment',
      'Scope_1_Emissions': 'Environment',
      'Scope_2_Emissions': 'Environment', 
      'Scope_3_Emissions': 'Environment', 
      'result': 'Environment',
      'carbon_footprint_detail': 'Environment',

      'sector_questions': 'Environment',
      'Social_Capital': 'Environment',
      'Leadership_Governance': 'Environment', 
      'Human_Capital': 'Environment', 
      'Environmental_Capital': 'Environment', 
      'Cyber_Digital': 'Environment', 
      'Business_Model_Innovation': 'Environment', 
      'sector_question_detail': 'Environment',

      'board_skills': 'Social',
      'board_skills_detail': 'Social',

      'suppliers': 'Social',
      'suppliers_fast': 'Social', 
      'Environmental_Topics': 'Social', 
      'Social_Topics': 'Social', 
      'Governance_Topics': 'Social', 
      'Business_Models': 'Social', 
      'supplier_details': 'Social',

      'governance': 'Governance', 
      'Governance_Policies': 'Governance', 
      'Social_Policies': 'Governance', 
      'Cyber_&_Technology': 'Governance', 
      'Health_&_Safety_Policy': 'Governance', 
      'Environmental_Policy': 'Governance', 
      'other_policies': 'Governance',

      'management': 'Governance',
      'management_details': 'Governance'
      
    };


    this.state = {
      criteriaDrop: false,
      haveText: "",
      activeItem: validMenus.includes(url) ? "ESG Criteria" : null,
      isDropdownOpen:  validMenus.includes(url) ? true : false,
      activeChildItem: validSubMenu[url] || null,
      validMenuState: validMenus
    };
  }

  handleItemClick = (item, event) => {

    event.stopPropagation();

    this.setState((prevState) => ({
      activeItem: prevState.activeItem === item ? null : item,
      isDropdownOpen: true
    }));
  };

  // handleChildItemClick = (childItem) => {

  //   console.log("childItem", childItem);
  //   this.setState((prevState) => ({
  //     activeChildItem: prevState.activeChildItem === childItem ? null : childItem
  //   }));
  // };
  handleChildItemClick = (childItem, e) => {
    e.stopPropagation();
    this.setState((prevState) => ({
      activeChildItem: prevState.activeChildItem === childItem ? null : childItem,
       // Ensures that parent is open when a child is clicked;
      isDropdownOpen: true 
    }));
};




  handleClick = () => {
    this.setState({
      criteriaDrop: !this.state.criteriaDrop
    });
  }

  handleText = (ev) => {
    this.setState({
      haveText: ev.currentTarget.textContent
    })
  }

  itemList = props => {
    const list = props.map((item) => (
      <div
        onClick={this.handleText}
        className="dropdown__item"
        key={item.toString()}>
        {item}
      </div>
    ));

    return (
      <div className="dropdown__items"> { list } </div>
    )
  }

  isAuthorized = (link) => {
    const role = localStorage.getItem("user_status");
    if(role === "trial") {
      const allowedRoutes = ["home", "/suppliers", "/management", "esg_products", "training", "Profile_Settings"];
      if(!allowedRoutes.includes(link)) {
        return false
      }else{
        return true
      }
    }else{
      return true
    }
  }

  render() {
    const company = this.props?.dataFromParent?.split("/");
    const sustainableTarget = {
      pathname: "/sustainable",
      key: Date.now(), // we could use Math.random, but that's not guaranteed unique.
      state: {
        applied: true,
      },
    };

    const homeTarget = {
      pathname: "/home",
      key: Date.now(), // we could use Math.random, but that's not guaranteed unique.
      state: {
        applied: true,
      },
    };
    
    const { isDropdownOpen, activeItem, activeChildItem, validMenuState } = this.state;
    return (
      <div>
        <div className="d-flex" id="wrapper">
          {/* <!-- Sidebar--> */}
          <div className="border-end bg-white active-sidebar" id="sidebar-wrapper">
            <div className="sidebar-heading border-bottom color_xl">
              <div className="logo_text">
                <NavLink to="/home">
                  <img src={head_Logo} alt="" />
                </NavLink>
              </div>
            </div>
            <div className="list-group list-group-flush">
              <div className="route-dom">
                <ul className="home_icon_img">
                  <li>
                    {this.isAuthorized('home') ? (
                      <NavLink
                        activeClassName="active"
                        to={homeTarget}
                        className="list-group-item list-group-item-action list-group-item-light p-3 nop"
                      >
                        <i className="fal fa-th-large"></i>
                        <span className="home_boom">Dashboard</span>
                      </NavLink>
                    ): (
                      // eslint-disable-next-line
                      <a className="list-group-item list-group-item-action list-group-item-light p-3 nop disabled_menu" title="Can't access with trial account, upgrade to premium">
                        <i className="fal fa-file-chart-line"></i>
                        <span className="home_boom">Dashboard</span>
                      </a>
                    )}
                    
                  </li>
                  <li>
                    {this.isAuthorized("esg_reporting") ? (
                      <NavLink
                      activeClassName="active"
                      to="/esg_reporting"
                      name="esg_reporting"
                      className={
                        company[1] === "esg_reporting"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "esg_reporting_pie"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                      }
                    >
                      <i className="fal fa-file-chart-line"></i>
                      <span className="home_boom">ESG Reporting</span>
                    </NavLink>
                    ): (
                      // eslint-disable-next-line
                      <a className="list-group-item list-group-item-action list-group-item-light p-3 nop disabled_menu" title="Can't access with trial account, upgrade to premium">
                        <i className="fal fa-file-chart-line"></i>
                        <span className="home_boom">ESG Reporting</span>
                      </a>
                    )}
                  </li>
                  {nestedData.map((parent, parentIndex) => (
                    <li key={parentIndex}>
                      <div
                        className={`d-flex justify-content-between list-group-item border-0 list-group-item-action list-group-item-light p-3 nop esgCriteriaMenu ${( validMenuState.includes(company[1])) ? 'active': ''}`}
                        onClick={(e) => this.handleItemClick(parent.parentLabel, e)}
                      >
                        <div className="d-flex gap-2"  >
                          <i className="far fa-chart-bar"></i>
                          <span>{parent.parentLabel}</span>
                        </div>
                        <i className={`fa ${isDropdownOpen ? 'rotate-up' : 'rotate-down'} fa-angle-down`}></i>
                      </div>
                      <ul style={{ marginLeft : '-1.9rem' }} className={activeItem === parent.parentLabel && isDropdownOpen ? "activeMenu" : "inactiveMenu"}>
                        {parent.parentChild && parent.parentChild.map((child, childIndex) => (
                          <li key={childIndex} style={{ marginTop: '5px', width: '100%', borderLeft: '0.5px solid #E5E7EB', marginLeft: '1.2rem' }} onClick={(e) => e.stopPropagation()}>
                            <div
                              id="sub__menu_criteria"
                              className={` d-flex justify-content-between list-group-item border-0 list-group-item-action list-group-item-light p-3 nop esgCriteriaMenu`}
                              onClick={(e) => this.handleChildItemClick(child.childLabel, e)}
                            >
                              <div className="d-flex gap-2">
                                <span>{child.childLabel}</span>

                              </div>
                              <i className={`fa ${activeChildItem === child.childLabel ? 'rotate-up' : 'rotate-down'} fa-angle-down`}></i>

                            </div>
                            <ul style={{ marginLeft : '-1rem' }} className={activeChildItem === child.childLabel ? "activeSubMenu" : "inactiveSubMenu"} >
                              {child.grandChild && child.grandChild.map((grand, grandIndex) => (
                               
                                  <li key={grandIndex} style={{ width: '100%', borderLeft: '0.5px solid #E5E7EB', margin: "5px" }} >


                                  {this.isAuthorized(grand.link) ?(
                                    <NavLink to={grand.link} style={{ textDecoration: 'none' }} >
                                      <div className={`list-group-item border-0 list-group-item-action list-group-item-light p-3 nop esgCriteriaMenu ${grand.ids.includes(company[1])  ? "active" : ""}`}>
                                          <i className={grand.icon}></i>
                                        <span>{grand.grandLabel}</span>
                                      </div>
                                    </NavLink>
                                  ): (
                                    // eslint-disable-next-line
                                    <a className="list-group-item list-group-item-action list-group-item-light p-3 nop disabled_menu" title="Can't access with trial account, upgrade to premium">
                                      <i className={grand.icon}></i>
                                      <span >{grand.grandLabel}</span>
                                    </a>
                                  )}
                                    
                                  </li>
                              ))}
                            </ul>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}



                  {/* <li className="margin_tp_criteria">
                    <NavLink
                      activeClassName="active"
                      to="/sector_questions"
                      className={
                        company[1] === "sector_question"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Environmental_Capital"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Social_Capital"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Leadership_Governance"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Business_Model_Innovation"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "sector_question_detail"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Cyber_Digital"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Human_Capital"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                      }
                    >
                      <i className="far fa-clipboard"></i>
                      <span className="home_boom">Sector Questions</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="active"
                      to="/governance"
                      a="true"
                      className={
                        company[1] === "governance"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Governance_Policies"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Social_Policies"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Cyber_&_Technology"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Health_&_Safety_Policy"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Environmental_Policy"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "other_policies"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                      }
                    >
                      <i className="far fa-building"></i>
                      <span className="home_boom">Governance</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="active"
                      to="/suppliers"
                      className={
                        company[1] === "supplier"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "suppliers_fast"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Environmental_Topics"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Social_Topics"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Governance_Topics"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Business_Models"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "supplier_details"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                      }
                    >
                      <i className="far fa-user-cog"></i>
                      <span className="home_boom">Suppliers</span>
                    </NavLink>
                  </li> */}
                  <li className="margin_tp_criteria">
                    {this.isAuthorized("supplier_management") ? (
                      <NavLink
                        activeClassName="active"
                        to="/supplier_management"
                        className={
                          company[1] === "supplier_management"
                            ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                            : company[1] === "supplier_management_option"
                            ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                            : company[1] === "supplier_management_form"
                            ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                            : company[1] === "supplier_management_detail"
                            ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                            : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                        }
                      >
                        <i className="fal fa-file-chart-line"></i>
                        <span className="home_boom">Supplier Management</span>
                      </NavLink>
                    ):(
                      // eslint-disable-next-line
                      <a className="list-group-item list-group-item-action list-group-item-light p-3 nop disabled_menu" title="Can't access with trial account, upgrade to premium">
                        <i className="fal fa-file-chart-line"></i>
                        <span className="home_boom">Supplier Management</span>
                      </a>
                    )}
                    
                  </li>
                  <li>
                    {this.isAuthorized("sustainableTarget") ? (
                      <NavLink
                      activeClassName="active"
                      to={sustainableTarget}
                      className={
                        company[1] === "sustainable"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "sustainable_submissions"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                      }
                    >
                      <span className="leaf-fer">
                        <i className="fal fa-leaf"></i>
                        <span className="home_boom text_upper">17 UN SDGs</span>
                      </span>
                    </NavLink>
                    ): (
                      // eslint-disable-next-line
                      <a className="list-group-item list-group-item-action list-group-item-light p-3 nop disabled_menu" title="Can't access with trial account, upgrade to premium">
                        <i className="fal fa-leaf"></i>
                        <span className="home_boom">17 UN SDGs</span>
                      </a>
                    )}
                    
                  </li>
                  {/* <li>
                    <NavLink
                      activeClassName="active"
                      to="/carbon_footprint"
                      className={
                        company[1] === "carbon_footprint"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Scope_1_Emissions"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Scope_2_Emissions"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Scope_3_Emissions"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "result"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "carbon_footprint_detail"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                      }
                    >
                      <i className="fal fa-shoe-prints"></i>
                      <span className="home_boom">Carbon Footprint</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="active"
                      to="/board_skills"
                      className={
                        company[1] === "board_skills"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active board_cls"
                          : company[1] === "board_skills_detail"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active board_cls"
                          : "list-group-item list-group-item-action list-group-item-light p-3 nop board_cls"
                      }
                    >
                      <i className="fal fa-clipboard"></i>
                      <span className="home_boom"> Board Skills</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="active"
                      to="/management"
                      className={
                        company[1] === "management"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "management_details"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                      }
                    >
                      <i className="fal fa-users"></i>
                      <span className="home_boom">Management</span>
                    </NavLink>
                  </li> */}


                  <li>
                  {this.isAuthorized("training") ? (
                    <NavLink
                      activeClassName="active"
                      to="/training"
                      // className="list-group-item list-group-item-action list-group-item-light p-3 nop"
                      className={
                        company[1] === "coaching_form"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                      }
                    >
                      <i className="fal fa-address-card"></i>
                      <span className="home_boom">Training</span>
                    </NavLink>
                  ): (
                    // eslint-disable-next-line
                    <a className="list-group-item list-group-item-action list-group-item-light p-3 nop disabled_menu" title="Can't access with trial account, upgrade to premium">
                      <i className="fal fa-address-card"></i>
                      <span className="home_boom">Training</span>
                    </a>
                  )}
                    
                  </li>
                  <li>
                    {this.isAuthorized("esg_products") ? (
                      <NavLink
                      activeClassName="active"
                      to="/esg_products"
                      name="esg_products"
                      onClick={this.handleNavClick}
                      className="list-group-item list-group-item-action list-group-item-light p-3 nop"  
                    >
                      <i className="fal fa-archive"></i>
                      <span className="home_boom">ESG Products</span>
                    </NavLink>
                    ): (
                      // eslint-disable-next-line
                      <a className="list-group-item list-group-item-action list-group-item-light p-3 nop disabled_menu" title="Can't access with trial account, upgrade to premium">
                        <i className="fal fa-archive"></i>
                        <span className="home_boom">ESG Products</span>
                      </a>
                    )}
                    
                  </li>
                  <li>
                    <NavLink
                      activeClassName="active"
                      to="/Profile_Settings"
                      className={
                        company[1] === "Setting_Profile"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Setting_Billing"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Google_2FA"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "sub_accounts"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                      }
                    >
                        <i className="fal fa-cog"></i>
                        <span className="home_boom">Settings</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="slill_bord">
              <img src={foot_Logo} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
